import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form, Upload } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDigit, ProFormDateTimePicker } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { proxy, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

/**
 * Update node
 *
 * @param fields
 * @param id
 */
const handleUpdate = async (fields, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await proxy.updateItem({ ...fields, id: id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change auto renew
 *
 * @param status
 * @param record
 */
const handledChangeAutoRenew = async (status, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await proxy.changeRenew(record.id, {'status': status ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const ProxyExpiredListComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history  = useHistory();
    const [form] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');
    const timeFormat = "DD/MM/YYYY HH:mm";

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);

        const data = await proxy.getList({...parameters, 'is_expired': 1});
        return data;
    }

    /**
     * Columns show table
     */
    const columns = [
        {
            title: "Title",
            dataIndex: 'title',
            sorter: false,
            valueType: 'text'
        },
        {
            title:'IP',
            dataIndex: 'ip',
            hideInSearch: true,
            render: (_, record) => [
                <span key={record.id}>{ record.ip }{ record?.port ? ":" . record?.port : "" }</span>
            ]
        },
        {
            title: "Username",
            dataIndex: 'username',
            sorter: false,
            valueType: 'text',
            hideInSearch: true,
        },
        {
            title: "Password",
            dataIndex: 'password',
            sorter: false,
            valueType: 'text',
            hideInSearch: true,
        },
        {
            title: "User Name",
            dataIndex: 'user_name',
            valueType: 'text',
        },
        {
            title: "Package",
            dataIndex: 'package_name',
            valueType: 'text',
            hideInSearch: true,
        },
        {
            title: "Type Proxy",
            dataIndex: 'type',
            hideInSearch: true,
            render: (_, record) => [
                <span key={record.id}>{ record?.type == 'proxy_sock_5' ? "SOCKS5 Proxy" : "HTTPS Proxy" }</span>
            ]
        },
        {
            title: "Price",
            dataIndex: 'credit',
            hideInSearch: true,
            render: (_, record) => [
                <span key={record.id}>{record.credit} Point</span>
            ]
        },
        {
            title: "Expiration Date",
            dataIndex: 'expiration_date',
            hideInSearch: true
        },
        {
            title: "Auto-renew",
            dataIndex: 'is_renew',
            valueEnum: {
                0: {
                    text:'Deactive',
                    status: 'Default',
                },
                1: {
                    text: "Active",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="On" unCheckedChildren="Off" onChange={(value) => handledChangeAutoRenew(value, record)} key={record.id} defaultChecked={record?.is_renew  == 1 ? true : false} />
            ]
        },
        {
            title: "Status",
            dataIndex: 'status',
            valueEnum: {
                0: {
                    text:'Deactive',
                    status: 'Default',
                },
                1: {
                    text: "Running",
                    status: 'Success',
                },
                2: {
                    text: "Expired",
                    status: 'Warning',
                },
                3: {
                    text: "Banned",
                    status: 'Error',
                },
                5: {
                    text: "Pause",
                    status: 'Processing'
                },
                6: {
                    text: "Initialize",
                    status: 'Processing'
                }
            }
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true,
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <a key="edit"
                    onClick={() => {
                        form.resetFields();
                        setCurrentRow(record);
                        handleModalVisible(true);
                    }}
                ><EditOutlined /> Edit</a>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Proxy Manage"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    defaultPageSize: (paramQuery?.pageSize || 20)
                }}
            />

            {/*Form Add new and Edit*/}
            <ModalForm
                id="form-add-package"
                title="Proxy Form"
                width="640px"
                form={form}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                    setLoading(true);
                    const success = await handleUpdate({...value}, currentRow?.id);
                    if (success) {
                        form.resetFields();
                        handleModalVisible(false);
                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                    setLoading(false);
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Title is required"
                        },
                    ]}
                    initialValue={currentRow?.title}
                    width="full"
                    name="title"
                    label="Title:"
                />

                <ProFormText 
                    rules={[
                        {
                            required: true,
                            message: "IP is required"
                        },
                    ]}
                    initialValue={currentRow?.ip}
                    label="IP:"
                    placeholder="IP"
                    name="ip"
                />

                <ProFormText 
                    rules={[
                        {
                            required: true,
                            message: "Port is required"
                        },
                    ]}
                    initialValue={currentRow?.port}
                    label="Port:"
                    placeholder="Port"
                    name="port"
                />

                <ProFormText 
                    rules={[
                        {
                            required: true,
                            message: "Username is required"
                        },
                    ]}
                    initialValue={currentRow?.username}
                    label="Username:"
                    placeholder="Username"
                    name="username"
                />

                <ProFormText 
                    rules={[
                        {
                            required: true,
                            message: "Password is required"
                        },
                    ]}
                    initialValue={currentRow?.password}
                    label="Password:"
                    placeholder="Password"
                    name="password"
                />

                <ProFormSelect
                    name="type"
                    label="Proxy Type"
                    rules={[
                        {
                            required: true,
                            message: "Proxy Type is required"
                        },
                    ]}
                    request={async () => [
                        { label: 'SOCKS5 Proxy', value: 'proxy_sock_5'},
                        { label: 'HTTPS Proxy', value: 'proxy_https' }
                    ]}
                    initialValue={currentRow?.type}
                    placeholder="Proxy Type"
                />

                <ProFormDateTimePicker
                    name="datetime"
                    label="Expiration Date:"
                    name="expiration_date"
                    placeholder="Expiration Date"
                    fieldProps={{
                        showTime: { format: timeFormat },
                        format: [timeFormat]
                    }}
                    initialValue={currentRow?.expiration_date}
                />

                <ProFormText 
                    rules={[
                        {
                            required: true,
                            message: "Meritserver VPS ID is required"
                        },
                    ]}
                    initialValue={currentRow?.sid}
                    label="Meritserver VPS ID:"
                    placeholder="Meritserver VPS ID"
                    name="sid"
                />

                <ProFormSelect
                    name="status"
                    label="Status"
                    rules={[
                        {
                            required: true,
                            message: "Status is required"
                        },
                    ]}
                    request={async () => [
                        { label: 'Active', value: 1},
                        { label: 'Deactive', value: 0 },
                        { label: 'Expired', value: 2 },
                        { label: 'Suppend', value: 3 },
                        { label: 'Pause', value: 5 }
                    ]}
                    initialValue={currentRow?.status}
                    placeholder="Status"
                />

                <ProFormTextArea
                    row="6"
                    name="description"
                    label="Description:"
                    placeholder="Description:"
                    initialValue={currentRow?.description}
                />
                <div className="row">
                    <div className="col-md-12 text-right">
                        <Button type="primary" htmlType="submit" loading={loading} className="medium">Save</Button>
                    </div>
                </div>
            </ModalForm>
  
            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.name}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default ProxyExpiredListComponent;
