import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDateTimePicker } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { scan_ip, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

/**
 * Add node list
 *
 * @param fields
 */
const handleAdd = async (fields) => {
    const hide = message.loading('Processing...');
    try {
        var res = await scan_ip.addItem({ ...fields});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update node
 *
 * @param fields
 * @param id
 */
const handleUpdate = async (fields, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await scan_ip.updateItem({...fields, id: id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};


/**
 * Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await scan_ip.deleteItems({
            lists: selectedRows.map((row) => row.id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const ScanIpComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [createModalVisibleEdit, handleModalVisibleEdit] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [scanTypeList, setScanTypeList] = useState([]);
    const [scanTypeEnum, setScanTypeEnum] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const timeFormat = "DD/MM/YYYY HH:mm";
    const history  = useHistory();
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};
        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');
        history.push(`?` + queryString);
        const data = await scan_ip.getList(parameters);
        setScanTypeList(data.scan_type_list);
        setScanTypeEnum(data.scan_enum);
        return data;
    }

    /**
     * Columns show table
     */
    const columns = [
        {
            title: "IP",
            dataIndex: 'ip',
            sorter: false,
            valueType: 'text',
            initialValue: paramQuery?.sock,
            render: (dom, entity) => {
                return (
                    <a
                        onClick={() => {
                            setCurrentRow(entity);
                            setShowDetail(true);
                        }}
                    >
                        <span className="line-bottom">{dom}</span>
                    </a>
                );
            }
        },
        {
            title:'Type',
            dataIndex: 'type',
            valueEnum: scanTypeEnum
        },
        {
            title:'Date Block',
            dataIndex: 'date',
            hideInSearch: true
        },
        {
            title:'Date',
            dataIndex: 'updated_at_format',
            hideInSearch: true
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <a key={'edit' + record.id}
                    onClick={() => {
                        form1.resetFields();
                        setCurrentRow(record);
                        handleModalVisibleEdit(true);
                    }}
                ><EditOutlined /> Edit</a>,
                <Popconfirm
                    key={'delete' + record.id}
                    title="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key="delete" className='text-danger'><DeleteOutlined /> Delete</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Scan IP"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="scan_ip"
                        onClick={() => {
                            handleModalVisible(true);
                        }}
                    >
                        <PlusOutlined /> Add New
                    </Button>
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    defaultPageSize: (paramQuery?.pageSize || 100)
                }}
            />

            {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        key="delete"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}

            {/*Form Add new*/}
            <ModalForm
                id="form-add-filter"
                title="Add Scan IP"
                form={form}
                width="500px"
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                    const success = await handleAdd(value);
                    if (success) {
                        handleModalVisible(false);
                        form.resetFields();
                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
            >
                <ProFormTextArea
                    rules={[
                        {
                            required: true,
                            message: "List IP is required"
                        },
                    ]}
                    row="10"
                    width="lg"
                    name="list_ip"
                    label="List IP:"
                />
                <ProFormSelect
                    width="lg"
                    name="type"
                    label="Type"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={async () => scanTypeList}
                    placeholder="Please select a type"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select your type!' 
                        }
                    ]}
                />
            </ModalForm>

            {/*Form Edit*/}
            <ModalForm
                id="form-edit"
                title="Form Edit"
                width="500px"
                form={form1}
                visible={createModalVisibleEdit}
                onVisibleChange={handleModalVisibleEdit}
                onFinish={async (value) => {
                    const success = await handleUpdate(value, currentRow?.id);
                    if (success) {
                        form1.resetFields();
                        handleModalVisibleEdit(false);
                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
            >
                <ProFormDateTimePicker
                    label="Date Block:"
                    name="created_at"
                    placeholder="Date"
                    fieldProps={{
                        showTime: { format: timeFormat },
                        format: [timeFormat]
                    }}
                    initialValue={currentRow?.created_at}
                />
            </ModalForm>
  
            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.sock}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default ScanIpComponent;
