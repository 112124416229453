import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { UploadOutlined} from '@ant-design/icons';
import { Form, Button, Input, Upload, message, notification } from 'antd';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { googleKey } from '../../_until';
import { ticket } from '../../endpoint';

/**
 * Add node
 *
 * @param dataForm
 */
const handleAdd = async (dataForm) => {
    const hide = message.loading('Processing...');
    try {
        var res = await ticket.addItemEmailFrontend(dataForm);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const FeedbackComponent = () => {
    const history = useHistory();
    const [loadingButton, setLoadingButton] = useState(false);
    const [token, setToken] = useState(null);
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [contentData, setContentData] = useState('');
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [filesUpload, setFilesUpload] = useState([]);
    const [form] = Form.useForm();
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Application Assistance - " + window?.title;
        setEditorLoaded(true);
    }, []);

    /**
     * Handle change file
     *
     * @param file
     */
    const onChangeFiles = (file) => {
        setFilesUpload(file.fileList);
    }

    /**
     * Handle validate file
     *
     * @param file
     */
    const beforeUpload = (file) => {
        if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg" && file.type != "image/gif") {
            notification.open({
                message: 'Fail!',
                description:  `${file.name} not image file`,
                type: 'error'
            });
            return false;
        }
        const maxLimit = 3
        if (filesUpload.length > maxLimit) {
            notification.open({
                message: 'Fail!',
                description: 'Only 3 files allowed',
                type: 'error'
            });
            return false;
        }
      
        const maxFileSize = 3 * 1024 * 1024; // 3MB limit
        if (file.size > maxFileSize) {
            console.error("File size exceeds the limit of 3MB");
            notification.open({
                message: 'Fail!',
                description: `${file.name} file upload failed (exceeds 3MB)`,
                type: 'error'
            });
            return false;
        }
        return true;
    };

    /**
     * Handle remove file
     *
     * @param file
     */
    const onRemoveFile = (file) => {
        let files = [];
        if (filesUpload.length > 0) {
            for(var i = 0; i < filesUpload.length; i++) {
                let tempFile = filesUpload[i];
                if (tempFile.uid != file.uid) {
                    files.push(tempFile);
                }
            }
        }
        setFilesUpload(files);
    }

    if (user != null) {
        history.push({pathname: '/contacts.html'});
    }

    return (
        <div className="container">
            <div className='p-3'>
                <div className="row">
                    <div className="col-sm-12 col-md-2"></div>
                    <div className="col-sm-12 col-md-8">
                        <GoogleReCaptchaProvider reCaptchaKey={googleKey()}>
                            {
                                token === null && (<GoogleReCaptcha onVerify={token => {setToken(token); }}></GoogleReCaptcha>)
                            }
                            <div className='content p-4'>
                                <h3 className='text-center'>Application Assistance</h3>
                                <Form
                                    name="basic"
                                    className="form"
                                    onFinish={async (value) => {
                                    }}
                                    form={form}
                                >
                                    <Form.Item
                                        label="Title:"
                                        labelCol={{span: 24}}
                                        name="title"
                                        required={true}
                                        rules={[{ required: true, message: 'Please input Title' }]}
                                    >
                                        <Input placeholder="Title" />
                                    </Form.Item>
                                    {
                                        editorLoaded ? (
                                            <div className="form-group">
                                                <div className="ant-col ant-col-24 ant-form-item-label">
                                                    <label className="ant-form-item-required" title="Problem description:">Problem description</label>
                                                </div>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    id="problem_description"
                                                    config={{
                                                        ckfinder: false,
                                                    }}
                                                    onReady={(editor) => {
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setContentData(data);
                                                    }}
                                                />
                                            </div>
                                        ) : ""
                                    }
                                    <div className='form-group mt-3'>
                                        <label className='d-block'>Images (3 images):</label>
                                        <Upload beforeUpload={() => {beforeUpload(); return false;}} maxCount="3" multiple={true} onChange={onChangeFiles} onRemove={onRemoveFile}>
                                            <Button
                                                icon={<UploadOutlined />}
                                                className="upload-btn"
                                            >
                                                Add images
                                            </Button>
                                        </Upload>
                                        <p className='text-left'>
                                            Tips<br/>
                                            1. Only supports uploading images in jpg and png formats<br/>
                                            2. Upload up to <b>3</b> pictures<br/>
                                            3. Image size is less than 3MB
                                        </p>
                                    </div>
                                    <Form.Item
                                        label="Email Address:"
                                        labelCol={{span: 24}}
                                        name="email"
                                        required={true}
                                        rules={[{ required: true, message: 'Please input Email Address' }]}
                                    >
                                        <Input placeholder="Email Address" />
                                    </Form.Item>

                                    <div className="mb-3 text-center">
                                        <div className="col-xs-12 pb-3">
                                            <Button 
                                                type="primary"
                                                htmlType="submit"
                                                className="d-block medium"
                                                loading={loadingButton}
                                                onClick={async () => {
                                                    if (token) {
                                                        const dataForm = form.getFieldsValue();
                                                        form.submit();
                                                        if (dataForm.title && dataForm.email && contentData) {
                                                            const formData = new FormData();
                                                            formData.append('content', contentData);
                                                            formData.append('title', dataForm.title);
                                                            formData.append('email', dataForm.email);
                                                            formData.append('gg_code', token);
                                                            if (filesUpload.length > 0) {
                                                                for(var i = 0; i < filesUpload.length; i++) {
                                                                    let tempFile = filesUpload[i];
                                                                    if (beforeUpload(tempFile)) {
                                                                        formData.append("image" + (i + 1), tempFile.originFileObj);
                                                                    }
                                                                }
                                                            }
                                                            setLoadingButton(true);
                                                            const success = await handleAdd(formData);
                                                            if (success) {
                                                                form.resetFields();
                                                                location.href = '/success.html';
                                                            }
                                                            setLoadingButton(false);
                                                        } else {
                                                            notification.open({
                                                                message: 'Fail!',
                                                                description: "Please enter all infomation",
                                                                type: 'error'
                                                            });
                                                        }
                                                    } else {
                                                        notification.open({
                                                            message: 'Fail!',
                                                            description: "Recaptcha has not been loaded",
                                                            type: 'error'
                                                        });
                                                    }
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </GoogleReCaptchaProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default FeedbackComponent;
