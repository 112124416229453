export function getParamsByUrl() {
    var substring = window.location.href.split('?');
    if (substring.length > 1) {
        const params = JSON.parse('{"' + substring[1].replace(/&/g, '","').replace(/=/g, '":"') + '"}', function(key, value) { return key === "" ? value : decodeURIComponent(value) });
        if (typeof params.current != 'undefined') {
            delete params['current'];
        }
        if (typeof params.pageSize != 'undefined') {
            delete params['pageSize'];
        }
        Object.keys(params).forEach(key => {
            if (params[key] == "undefined") {
                delete params[key];
            }
        });
        return params;
    }
    return {};
}

export function getParamsByUrlPaging() {
    var substring = window.location.href.split('?');
    if (substring.length > 1) {
        const params = JSON.parse('{"' + substring[1].replace(/&/g, '","').replace(/=/g, '":"') + '"}', function(key, value) { return key === "" ? value : decodeURIComponent(value) });
        Object.keys(params).forEach(key => {
            if (params[key] == "undefined") {
                delete params[key];
            }
        });

        // if (typeof params.current != 'undefined') {
        //     delete params['current'];
        // }
        // if (typeof params.pageSize != 'undefined') {
        //     delete params['pageSize'];
        // }
        return params;
    }
    return {};
}

export function getParamsHash() {
    var substring = window.location.href.split('#');
    if (substring.length > 1) {
        const params = JSON.parse('{"' + substring[1].replace(/&/g, '","').replace(/=/g, '":"') + '"}', function(key, value) { return key === "" ? value : decodeURIComponent(value) });
        Object.keys(params).forEach(key => {
            if (params[key] == "undefined") {
                delete params[key];
            }
        });
        return params;
    }
    return {};
}