import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useParams, generatePath, Link  } from "react-router-dom";
import { PlusOutlined, UploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form, Spin, Upload } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDigit } from '@ant-design/pro-form';
import { ticket, ticket_message, authenticator } from '../../../../endpoint';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/**
 * Add new message
 *
 * @param dataForm
 */
const handleAdd = async (dataForm) => {
    const hide = message.loading('Processing...');
    try {
        var res = await ticket_message.addItem(dataForm);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            setTimeout(function() {
                location.reload();
            }, 300);
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update message
 *
 * @param dataForm
 * @param id
 */
const handleUpdate = async (dataForm, id) => {
    const hide = message.loading('Processing...');
    dataForm.append('id', id);
    try {
        var res = await ticket_message.updateItem(dataForm);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            setTimeout(function() {
                location.reload();
            }, 300);
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Delete message
 *
 * @param selectedRow
 */
const handleRemove = async (selectedRow) => {
    const hide = message.loading('Processing...');
    if (!selectedRow) return true;
    try {
        var res = await ticket_message.deleteItems(selectedRow.id, {});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const TicketDetailComponent = () => {
    const actionRef = useRef(null);
    const history = useHistory();
    const [form] = Form.useForm();
    const [createModalVisible, handleModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingComment, setLoadingComment] = useState(true);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [dataEditor, setDataEditor] = useState("");
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [currentRow, setCurrentRow] = useState([]);
    const [ticketListComment, setTicketListComment] = useState([]);
    const [ticketData, setTicketData] = useState({});
    const [filesUpload, setFilesUpload] = useState([]);
    const oneTimePassword = localStorage.getItem('one_time_password');
    const { id } = useParams();

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }

        ticket.getItem(id).then(response => {
            if (response != null) {
                setTicketData(response);
                document.title = response.title + " - " + window?.title;
                loadListComment();
            }
            setLoading(false);
        }).then(res => {
        });
    }, []);

    const loadListComment = () => {
        const hide = message.loading('Processing...');
        setLoadingComment(true);
        ticket_message.getList(id, {'current': current, 'pageSize': pageSize}).then(res => {
            if (res?.success) {
                if (current == 1) {
                    setTicketListComment(res.data);
                } else {
                    setTicketListComment(ticketListComment.concat(res.data));
                }
                setTotal(res.total);
                setCurrent(current + 1);
            } else if (res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoadingComment(false);
        }).then(complete => {
            hide();
            setLoadingComment(false);
        });
    }

    const onChangeFiles = (file) => {
        setFilesUpload(file.fileList);
    }

    const onRemoveFile = (file) => {
        let files = [];
        if (filesUpload.length > 0) {
            for(var i = 0; i < filesUpload.length; i++) {
                let tempFile = filesUpload[i];
                if (tempFile.uid != file.uid) {
                    files.push(tempFile);
                }
            }
        }
        setFilesUpload(files);
    }

    const beforeUpload = (file) => {
        if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg" && file.type != "image/gif") {
            notification.open({
                message: 'Fail!',
                description:  `${file.name} not image file`,
                type: 'error'
            });
            return false;
        }
        const maxLimit = 3
        if (filesUpload.length > maxLimit) {
            notification.open({
                message: 'Fail!',
                description: 'Only 3 files allowed',
                type: 'error'
            });
            return false;
        }
      
        const maxFileSize = 3 * 1024 * 1024; // 3MB limit
        if (file.size > maxFileSize) {
            console.error("File size exceeds the limit of 3MB");
            notification.open({
                message: 'Fail!',
                description: `${file.name} file upload failed (exceeds 3MB)`,
                type: 'error'
            });
            return false;
        }
        return true;
    };


    return (
        <PageContainer>
            {
                loading ? (
                    <div className="text-center mt-5 mb-5"><Spin /></div>
                ) :
                (
                    <div className='wrapper'>
                        <div className="panel panel-default panel-custom panel-contact">
                            <div className="panel-heading">{ticketData?.title}</div>
                            <div className="panel-body">
                                {
                                    ticketData?.user != null ? (
                                        <div className="user-icon-set -collapsed-trigger d-flex align-items-center mb-4">
                                            <span className="user-icon-set__user-icon comment-item__user-icon pr-2">
                                                <img src={ticketData?.user?.avatar } width="42px" height="42px" className="image image-circle" style={{borderRadius: '50%'}} />
                                            </span>
                                            <span className="user-icon-set__text comment-item__user-text">
                                                <span className="user-icon-set__name d-block"><b>{ticketData?.user?.user_name}</b></span>
                                                <span className="user-icon-set__sub-line d-block">{ticketData?.date}</span>
                                            </span>
                                        </div>
                                    ) : ""
                                }
                                <div dangerouslySetInnerHTML={{__html: ticketData?.content}} />
                                <p>{ticketData?.date}</p>
                                {
                                    ticketData?.images && JSON.parse(ticketData.images).length > 0 ? (
                                        <div className='images'>
                                            <h5>Images Detail</h5>
                                            <ul>
                                                {
                                                    JSON.parse(ticketData.images).map((imageUrl, key) => 
                                                        <li key={'images' + key}>
                                                            <a href={imageUrl} target='_blank'>
                                                                <img src={imageUrl} style={{maxWidth: "100%"}} />
                                                            </a>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>
                        <div className="comment-list-heading d-flex align-items-end justify-content-between mt-4 mb-2">
                            <h5 className="title title--fourth comment-list-heading__title">
                                Comments <span className="title-group__number">({total})</span>
                            </h5>
                            <div className="comment-list-heading__view-options">
                                <Button type="primary" htmlType="button" className="medium" onClick={() => {
                                    form.resetFields();
                                    setCurrentRow(null);
                                    handleModalVisible(true);
                                    setEditorLoaded(true);
                                    setDataEditor('');
                                    setFilesUpload([]);
                                }}>
                                    <PlusOutlined /> New Comment
                                </Button>
                            </div>
                        </div>
                        <div className="panel panel-default panel-custom panel-contact">
                            <div className="panel-body">
                                <ul className='comments comment-list__items'>
                                    {
                                        ticketListComment.length > 0 && ticketListComment.map((item, key) => 
                                            <li className="comment-item" key={item.id}>
                                                <div className="comment-item__inner">
                                                    <div className="comment-item__header">
                                                        <div className="user-icon-set -collapsed-trigger d-flex align-items-center">
                                                            <span className="user-icon-set__user-icon comment-item__user-icon">
                                                                <img src={window.base_url + (item?.is_admin == 1 ?  "uploads/admin-icon-vector.png": "uploads/avatar.jpg") } width="42px" height="42px" className="image image-circle"/>
                                                            </span>
                                                            <span className="user-icon-set__text comment-item__user-text">
                                                                <span className="user-icon-set__name d-block">{item?.is_admin == 1 ? 'Administrar' : item?.user_name}</span>
                                                                <span className="user-icon-set__sub-line d-block">{item?.date}</span>
                                                            </span>
                                                        </div>
                                                        <div className="edit-comment">
                                                            <a className='pr-3'
                                                                onClick={() => {
                                                                    form.resetFields();
                                                                    setCurrentRow(item);
                                                                    handleModalVisible(true);
                                                                    setEditorLoaded(true);
                                                                    setFilesUpload([]);
                                                                    setDataEditor(item.content);
                                                                }}
                                                            ><EditOutlined /> Edit</a>
                                                            <Popconfirm
                                                                key={item.id}
                                                                title="Are you sure to delete this comment?"
                                                                okText="Yes"
                                                                cancelText="No"
                                                                onConfirm={ async () => {
                                                                    await handleRemove(item);
                                                                    setTimeout(function() {
                                                                        location.reload();
                                                                    }, 300);
                                                                }} 
                                                            >
                                                                <a className='text-danger'><DeleteOutlined />  Delete</a>
                                                            </Popconfirm>
                                                        </div>
                                                    </div>
                                                    <div className="comment-item__container">
                                                        <div className="loom comment-content">
                                                            <div className="markdown-body">
                                                                <blockquote>
                                                                    <div dangerouslySetInnerHTML={{__html: item?.content}} />
                                                                </blockquote>
                                                                {
                                                                    item?.images && JSON.parse(item?.images).length > 0 ? (
                                                                        <div className='images'>
                                                                            <h5>Images Detail</h5>
                                                                            <ul>
                                                                                {
                                                                                    JSON.parse(item.images).map((imageUrl, key) => 
                                                                                        <li key={'images-detail-' + key}>
                                                                                            <a href={imageUrl} target='_blank'>
                                                                                                <img src={imageUrl} style={{maxWidth: "100%"}} />
                                                                                            </a>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    ) : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                                {
                                    total > 0 && (current - 1) * pageSize < total ? (
                                        <div className='comment-load-more text-center'>
                                            <p>
                                                {
                                                    loadingComment  ? (<Spin />) : (<a onClick={loadListComment}>Load More...</a>)
                                                }
                                            </p>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>

                        {/*Form Add new and Edit*/}
                        <ModalForm
                            id="form-add-contact"
                            title="New Contact"
                            width="640px"
                            form={form}
                            submitter={false}
                            visible={createModalVisible}
                            onVisibleChange={handleModalVisible}
                            onFinish={async (value) => {
                            }}
                        >
                            {
                                editorLoaded ? (
                                    <div className="mb-4 ant-form-item-label d-block">
                                        <label className="ant-form-item-required">Content:</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="content"
                                            style={{width: "100%"}}
                                            config={{
                                                //removePlugins: ['Image', 'MediaEmbed']
                                            }}
                                            data={dataEditor}
                                            onReady={(editor) => {
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setDataEditor(data);
                                            }}
                                        />
                                    </div>
                                ) : ""
                            }
                            <div className='form-group ant-form-item-label mt-3'>
                                <label className='d-block'>Images (3 images):</label>
                                <Upload beforeUpload={() => {beforeUpload(); return false;}} maxCount="3" multiple={true} onChange={onChangeFiles} onRemove={onRemoveFile}>
                                    <Button
                                        icon={<UploadOutlined />}
                                        className="upload-btn"
                                    >
                                        Add images
                                    </Button>
                                    <small className='d-block text-danger'>Please upload image maxsize 3MB.</small>
                                </Upload>
                            </div>
                            <div className='text-right'>
                                <Button
                                    type="primary"
                                    key="submitForm"
                                    loading={loadingButton}
                                    onClick={async () => {
                                        const value = form.getFieldsValue();
                                        form.submit();
                                        if (dataEditor) {
                                            var isVal = true;
                                            const formData = new FormData();
                                            formData.append('content', dataEditor);
                                            formData.append('ticket_id', id);
                                            if (filesUpload.length > 0) {
                                                for(var i = 0; i < filesUpload.length; i++) {
                                                    let tempFile = filesUpload[i];
                                                    if (beforeUpload(tempFile)) {
                                                        formData.append("image" + (i + 1), tempFile.originFileObj);
                                                    } else {
                                                        isVal = false;
                                                    }
                                                }
                                            }
                                            if (isVal) {
                                                setLoadingButton(true);
                                                if (currentRow?.id) {
                                                    const success = await handleUpdate(formData, currentRow?.id);
                                                    if (success) {
                                                        form.resetFields();
                                                        handleModalVisible(false);
                                                    }
                                                    setLoadingButton(false);
                                                } else {
                                                    const success = await handleAdd(formData);
                                                    if (success) {
                                                        form.resetFields();
                                                        handleModalVisible(false);
                                                    }
                                                    setLoadingButton(false);
                                                }
                                            }
                                        } else {
                                            notification.open({
                                                message: 'Fail!',
                                                description: "Please enter content",
                                                type: 'error'
                                            });
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </ModalForm>
                    </div>
                )
            }
        </PageContainer>
    );
}
export default TicketDetailComponent;
