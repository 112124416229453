import Home from '../component/website/Home';
import MyAccount from '../component/website/MyAccount';
import Sock24h from '../component/website/Sock24h';
import SockApi from '../component/website/SockApi';
import Sock5list from '../component/website/Sock5list';
import SockLists from '../component/website/SockLists';
import SockPremium from '../component/website/SockPremium';
import Buysock from '../component/website/Buysock';
import Register from '../component/website/Register';
import Forgot from '../component/website/Forgot';
import ResetPassword from '../component/website/ResetPassword';
import Login from '../component/website/Login';
import Success from '../component/website/Success';
import Pendding from '../component/website/Pendding';
import Cancel from '../component/website/Cancel';
import Checksock from '../component/website/Checksock';
import Tool from '../component/website/Tool';
import Using from '../component/website/Using';
import Histories from '../component/website/History';
import FAQsList from '../component/website/FAQsList';
import FAQsCategory from '../component/website/FAQsCategory';
import FAQDetail from '../component/website/FAQDetail';
import Auth2FAComponentFrontend from '../component/website/Auth2fa';
import Auth2FAVerifyComponentFrontend from '../component/website/Auth2faVerify';
import OrdersList from '../component/website/OrdersList';
import ResetList from '../component/website/ResetList';
import ConvertHistory from '../component/website/ConvertHistory';
import ContactList from '../component/website/ContactList';
import ContactDetail from '../component/website/ContactDetail';
import TrialFail from '../component/website/TrialFail';
import SendCDKey from '../component/website/SendCDKey';
import FeedbackComponent from '../component/website/Feedback';
import BuyCreditComponent from '../component/website/BuyCredit';
import BuyProxyComponent from '../component/website/BuyProxy';
import ListPackageProxyComponent from '../component/website/PackageProxy';
import ProxyListComponent from '../component/website/ProxyList';
import ProxyExpiredListComponent from '../component/website/ProxyExpiredList';
import ProxyChangeIpListComponent from '../component/website/ProxyChangeIpList';
import LoginMicrosoft from '../component/website/LoginMicrosoft';
import Partners from '../component/website/Partners';
import BuyVpsComponent from '../component/website/BuyVps';
import VpsListComponent from '../component/website/VpsList';
import VpsExpiredListComponent from '../component/website/VpsExpiredList';
import VpsChangeIpListComponent from '../component/website/VpsChangeIpList';
import CreditHistory from '../component/website/CreditHistory';
import UserInvite from '../component/website/UserInvite';
import PaymentCryptomusDetail from '../component/website/PaymentCryptomusDetail';

var WebsiteRoutes = [
    { 
        path: '/verify2fa.html',
        name: 'Auth2FAVerifyComponentFrontend',
        component: Auth2FAVerifyComponentFrontend
    },
    { 
        path: '/2fa.html',
        name: 'Auth2FAComponentFrontend',
        component: Auth2FAComponentFrontend
    },
    { 
        path: '/my-account.html',
        name: 'MyAccount',
        component: MyAccount
    },
    { 
        path: '/contacts.html',
        name: 'Contacts',
        component: ContactList
    },
    { 
        path: '/contact-detail/:id',
        name: 'contactDetail',
        component: ContactDetail
    },
    { 
        path: '/feedback.html',
        name: 'feedBack',
        component: FeedbackComponent
    },
    { 
        path: '/faqs.html',
        name: 'Faqs',
        component: FAQsList
    },
    { 
        path: '/faqs/category/:slug',
        name: 'Faqs',
        component: FAQsCategory
    },
    { 
        path: '/faq-detail/:slug',
        name: 'faqDetail',
        component: FAQDetail
    },
    { 
        path: '/partners.html',
        name: 'partners',
        component: Partners
    },
    { 
        path: '/crypto-payment/:code',
        name: 'crypto-payment',
        component: PaymentCryptomusDetail
    },
    { 
        path: '/tools.html',
        name: 'tools',
        component: Tool
    },
    { 
        path: '/histories.html',
        name: 'histories',
        component: Histories
    },
    { 
        path: '/reset.html',
        name: 'reset_list',
        component: ResetList
    },
    { 
        path: '/convert.html',
        name: 'convert_list',
        component: ConvertHistory
    },
    { 
        path: '/ss5-wallet.html',
        name: 'wallet',
        component: CreditHistory
    },
    { 
        path: '/orders.html',
        name: 'orders',
        component: OrdersList
    },
    { 
        path: '/how-to-use-shopsocks5.html',
        name: 'using',
        component: Using
    },
    { 
        path: '/view-sock.html',
        name: 'SockLists',
        component: SockLists
    },
    { 
        path: '/socks5-list.html',
        name: 'SockLists',
        component: Sock5list
    },
    { 
        path: '/socks5-premium.html',
        name: 'SockPremium',
        component: SockPremium
    },
    { 
        path: '/buy-socks5.html',
        name: 'Buysock',
        component: Buysock
    },
    { 
        path: '/socks5-24h.html',
        name: 'Sock24h',
        component: Sock24h
    },
    { 
        path: '/socks5-api.html',
        name: 'SockApi',
        component: SockApi
    },
    { 
        path: '/register.html',
        name: 'Register',
        component: Register
    },
    { 
        path: '/forgot.html',
        name: 'Forgot',
        component: Forgot
    },
    { 
        path: '/reset-password.html',
        name: 'ResetPassword',
        component: ResetPassword
    },
    { 
        path: '/login.html',
        name: 'Login',
        component: Login
    },
    { 
        path: '/success.html',
        name: 'success',
        component: Success
    },
    { 
        path: '/pendding.html',
        name: 'pendding',
        component: Pendding
    },
    { 
        path: '/send-cdkey.html',
        name: 'send_cdkey',
        component: SendCDKey
    },
    { 
        path: '/cancel.html',
        name: 'cancel',
        component: Cancel
    },
    { 
        path: '/fail.html',
        name: 'trial-fail',
        component: TrialFail
    },
    { 
        path: '/social/microsoft',
        name: 'login-microsoft',
        component: LoginMicrosoft
    },
    { 
        path: '/buy-point.html',
        name: 'buy-point',
        component: BuyCreditComponent
    },
    { 
        path: '/buy-proxy/:id',
        name: 'buy-proxy',
        component: BuyProxyComponent
    },
    { 
        path: '/history-proxy-change-ip.html',
        name: 'history-proxy-change-ip',
        component: ProxyChangeIpListComponent
    },
    { 
        path: '/history-vps-change-ip.html',
        name: 'history-vps-change-ip',
        component: VpsChangeIpListComponent
    },
    { 
        path: '/buy-vps/:id',
        name: 'buy-vps',
        component: BuyVpsComponent
    },
    // { 
    //     path: '/package-proxy.html',
    //     name: 'package-proxy',
    //     component: ListPackageProxyComponent
    // },
    { 
        path: '/list-proxy.html',
        name: 'list-proxy',
        component: ProxyListComponent
    },
    { 
        path: '/list-expired-proxy.html',
        name: 'list-expired-proxy',
        component: ProxyExpiredListComponent
    },
    { 
        path: '/list-vps.html',
        name: 'list-vps',
        component: VpsListComponent
    },
    { 
        path: '/list-expired-vps.html',
        name: 'list-expired-vps',
        component: VpsExpiredListComponent
    },
    { 
        path: '/user-invite.html',
        name: 'user-invite',
        component: UserInvite
    },
    { 
        path: '/check_sock',
        name: 'Checksock',
        component: Checksock
    },
    { 
        path: '/home',
        name: 'index',
        component: Home
    },
    { 
        path: '/',
        name: 'Home',
        component: Home
    }
];

export default WebsiteRoutes;
