import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { ExportOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { sock_delete, authenticator } from '../../../../endpoint';

/**
 * Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await sock_delete.deleteItems({
            lists: selectedRows.map((row) => row.id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Clean node
 *
 * @param selectedRows
 */
 const handleClean = async () => {
    const hide = message.loading('Processing...');
    try {
        var res = await sock_delete.clean();
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Clean successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Clean failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 *  Export TXT
 */
const handleExport = async () => {
    const hide = message.loading('Processing...');
    try {
        var res = await sock_delete.exportCSV();
        hide();
        if (res.status == 'success') {
            const link = document.createElement('a');
            link.setAttribute('download', '');
            link.href = res.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Export failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const SockDeleteComponent = () => {
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [total, setTotal] = useState(0);
    const history  = useHistory();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);


    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');
        history.push(`?` + queryString);

        const data = await sock_delete.getList(parameters);
        if(data?.total){
            setTotal(data?.total);
        }
        return data;
    }

    const columns = [
        {
            title: "Sock IP",
            dataIndex: 'sock_ip',
            hideInSearch: true
        },
        {
            title:'User Name',
            dataIndex: 'user_name',
            hideInSearch: true
        },
        {
            title:'Password',
            dataIndex: 'password',
            hideInSearch: true
        },
        {
            title:'IP Thật',
            dataIndex: 'ip_real',
            hideInSearch: true
        },
        {
            title:'Host Name',
            dataIndex: 'sock_hostname'
        },
        {
            title: "Zip Code",
            dataIndex: 'sock_zip',
            hideInSearch: true
        },
        {
            title: "City",
            dataIndex: 'sock_city',
            hideInSearch: true,
        },
        {
            title: "State",
            dataIndex: 'sock_states',
            hideInSearch: true
        },
        {
            title: "Country",
            dataIndex: 'sock_country',
            hideInSearch: true,
            render: (_, record) => [
                <span key={record.sock_id}><img src={record.country_img} /> {record.sock_country}</span>
            ]
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <Popconfirm
                    key={record.id}
                    title="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key="delete" className='text-danger'><DeleteOutlined /> Delete</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle={ "Sock Delete(" + total + ")" }
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={false}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            handleExport();
                        }}
                    >
                        <ExportOutlined /> Export
                    </Button>,
                    <Button
                        type="info"
                        key="clear"
                        onClick={() => {
                            handleClean();
                            actionRef.current?.reloadAndRest?.();
                        }}
                    >
                        <DeleteOutlined /> Clear
                    </Button>
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
            />

             {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        key="delete"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}
        </PageContainer>
    );
}
export default SockDeleteComponent;
