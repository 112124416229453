import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch } from "react-router-dom";
import { CheckCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDigit } from '@ant-design/pro-form';
import { confirm_email, plan_credit, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

/**
 * Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await confirm_email.deleteItems({
            lists: selectedRows.map((row) => row.id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Status
 *
 * @param record
 */
const handledChangeStatus = async (record) => {
    try {
        var res = await confirm_email.changeStatus(record.id, {'status': 1});
        return true;
    } catch (error) {
        console.log(error);
    }
    return false;
};

/**
 * Confirm
 *
 * @param data
 */
const handledConfirmOrder = async (data) => {
    try {
        var res = await confirm_email.confirmOrderBuyCredit(data);
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Confirm successfully.',
                type: 'success'
            });
            return true;
        } else {
            notification.open({
                message: 'Fail!',
                description: "Confirm Error!",
                type: 'error'
            });
        }
        return true;
    } catch (error) {
        console.log(error);
    }
    return false;
};

/**
 * Update node
 *
 * @param fields
 * @param id
 */
const handleUpdate = async (fields, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await confirm_email.updateItem(id, { ...fields});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update node plan
 *
 * @param fields
 * @param id
 */
const handleUpdatePlan = async (fields, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await confirm_email.updatePlan({...fields, id: id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const ConfirmEmailCreditList = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [updateModalVisible, setUpdateModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const history = useHistory();
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2, 'type_product': 'buy_credit'};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');
        history.push(`?` + queryString);

        const data = await confirm_email.getList(parameters);
        return data;
    }

    /**
     * Get list select plan credit
     */
    const plans = async () => {
        const data = await plan_credit.getSelect();
        return data;
    }

    /**
     * Columns show table
     */
    const columns = [
        {
            title: "Subject",
            dataIndex: 'subject',
            sorter: false,
            valueType: 'text',
            render: (dom, entity) => {
                return (
                    <a
                        onClick={() => {
                            setCurrentRow(entity);
                            setShowDetail(true);
                            if(handledChangeStatus(entity)){
                                var tr = document.querySelectorAll('[data-row-key="' +  entity?.id + '"]');
                                if(typeof tr !== 'undefined'){
                                    tr[0].classList.remove('not_is_ready');
                                }
                            }
                        }}
                    >
                        {dom}
                    </a>
                );
            }
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true,
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <a key={"edit" + record.id}
                    onClick={() => {
                        form.resetFields();
                        setCurrentRow(record);
                        handleModalVisible(true);
                    }}
                ><EditOutlined /> Edit</a>,
                <a key={"edit-plan" + record.id}
                    onClick={() => {
                        form1.resetFields();
                        setCurrentRow(record);
                        setUpdateModalVisible(true);
                    }}
                ><EditOutlined /> Edit Plan</a>,
                <Popconfirm
                    key={record.id}
                    title="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key={"delete" + record.id} className='text-danger'><DeleteOutlined />  Delete</a>
                </Popconfirm>,
                <Popconfirm
                    key={record.id}
                    className={record.is_confirm == 1 && 'd-none'}
                    title="Are you sure to confirm this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handledConfirmOrder(record);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key={"confirm" + record.id} className="btn btn-success text-white"><CheckCircleOutlined /> Confirm</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Confirm Email Buy Credit"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={false}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    defaultPageSize: (paramQuery?.pageSize || 20)
                }}
                rowClassName={(record, index) => record.is_ready == 1 ? ('is_ready black-list-' + record.is_black_list) : ('not_is_ready black-list-' + record.is_black_list)}
            />

            {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        key="delete"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}

            {/*Form Edit*/}
            <ModalForm
                id="form-add-confirm-email"
                title="Confirm Email Form"
                width="420px"
                form={form}
                submitter={false}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Address is required"
                        },
                    ]}
                    initialValue={currentRow?.name}
                    width="full"
                    name="address"
                    label="Address:"
                />
                <div className='text-right'>
                    <Button
                        type="primary"
                        key="submitForm"
                        onClick={async () => {
                            const value = form.getFieldsValue();
                            form.submit();
                            if (value.address) {
                                const success = await handleUpdate({...value}, currentRow?.id);
                                if (success) {
                                    form.resetFields();
                                    handleModalVisible(false);
                                    if (actionRef.current) {
                                        actionRef.current.reload();
                                    }
                                }
                            }
                        }}
                    >
                        Save
                    </Button>
                </div>
            </ModalForm>

            {/*Form Edit Plan*/}
            <ModalForm
                id="form-update-plan"
                title="Update Plan Form"
                width="420px"
                form={form1}
                submitter={false}
                visible={updateModalVisible}
                onVisibleChange={setUpdateModalVisible}
            >
                <ProFormSelect
                    showSearch
                    width="lg"
                    name="plan_id"
                    label="Plan"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={plans}
                    placeholder="Please select a plan"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select plan!' 
                        }
                    ]}
                />
                <div className='text-right'>
                    <Button
                        type="primary"
                        key="submitForm1"
                        onClick={async () => {
                            const value = form1.getFieldsValue();
                            form1.submit();
                            if (value.plan_id) {
                                const success = await handleUpdatePlan({...value}, currentRow?.id);
                                if (success) {
                                    form1.resetFields();
                                    setUpdateModalVisible(false);
                                    if (actionRef.current) {
                                        actionRef.current.reload();
                                    }
                                }
                            }
                        }}
                    >
                        Save
                    </Button>
                </div>
            </ModalForm>

            {/*View Info*/}
            <Drawer
                width={420}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                <div className="form-group">
                    <p>
                        <b>Subject:</b> <br/>
                        {currentRow?.subject}
                    </p>
                </div>
                <div className="form-group">
                    <p><b>Content:</b></p>
                    <div dangerouslySetInnerHTML={{__html: currentRow?.content}} />
                </div>
            </Drawer>
        </PageContainer>
    );
}
export default ConfirmEmailCreditList;
