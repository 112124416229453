import { Request } from '../_until';

export const notification = {
    getList,
    getItem,
    changeStatus,
    changeAllStatus,
    getListWebsite,
    getItemWebsite,
    changeStatusWebsite,
    changeAllStatusWebsite
};

const urls = {
    get_list: `/notifications`,
    get_item: `/notifications/get/`,
    change_status: `/notifications/change_status/`,
    changeall_status: `/notifications/change_all_status`,
    get_list_website: `notifications`,
    get_item_website: `notifications/get/`,
    change_status_website: `notifications/change_status/`,
    changeall_status_website: `notifications/change_all_status`,
};

function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    return Request.get(urls.get_list + '?' + queryString, {}).then(res => res.data);
};

function getItem(id) {
    return Request.get(urls.get_item + id, {}).then(res => res.data);
};

function changeStatus(id, requestOptions) {
    return Request.post(urls.change_status + id, requestOptions).then(res => res.data);
};

function changeAllStatus(requestOptions) {
    return Request.post(urls.changeall_status, requestOptions).then(res => res.data);
};

function getListWebsite(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    return Request.get_website(urls.get_list_website + '?' + queryString, {}).then(res => res.data);
};

function getItemWebsite(id) {
    return Request.get_website(urls.get_item_website + id, {}).then(res => res.data);
};

function changeStatusWebsite(id, requestOptions) {
    return Request.post_website(urls.change_status_website + id, requestOptions).then(res => res.data);
};

function changeAllStatusWebsite(requestOptions) {
    return Request.post_website(urls.changeall_status_website, requestOptions).then(res => res.data);
};