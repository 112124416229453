import React,  { useState, useEffect } from 'react';
import ReactDOM, { render } from 'react-dom';
import { useHistory } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import AppContext from './_until/AppContext';
import axios from 'axios';
import App from './App';

TopBarProgress.config({
    barColors: {
        "0": "#1890ff",
        "0.5": "#1890ff",
        "1.0": "#1890ff",
    },
    shadowBlur: 3
});

// Response interceptor
axios.interceptors.response.use(
    (response) => {
        // Modify the response data here (e.g., parse, transform)
        var path = location.protocol + '//' + location.host;
        var currentUrl = window.location.href;
        var isCheck = (path != currentUrl && path + '/' != currentUrl && path + '/home' != currentUrl);
        if (response.status == 200 && response.data.status == 'fail' && isCheck) {
            if (response.data.hasOwnProperty('is_logout') && response.data.is_logout) {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                window.location.href = "/login.html";
            }
        }
        return response;
    },
    (error) => {
        // Handle response errors here
        return Promise.reject(error);
    }
);

const Layout = () => {
    const globalSettings = {};
    const [isLoadNotification, setLoadNotification] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem('client_ip')) {
            axios.get('https://api.ipify.org?format=json') .then(response => {
                console.log('Client IP Address:', response.data.ip);
                localStorage.setItem('client_ip', response.data.ip);
            }).catch(error => {
                console.error('Error fetching the IP address:', error);
            });
        }
    }, []);

    return (
        <AppContext.Provider value={[isLoadNotification, setLoadNotification]}>
            <App />
        </AppContext.Provider>
    );
};

render(
    <Layout />,
    document.getElementById('root')
);