import { Request } from '../_until';
import { notification } from 'antd';

export const dashboard = {
    getList,
    getStatistical
};

const urls = {
    get_list: `/chart/info`,
    get_statistical: `/chart/get_statistical`,
};

async function getList() {
    const res = await Request.get(urls.get_list, {});
    try {
        return res.data;
    } catch (e) {
    }
    return null;
};

async function getStatistical(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    const res = await Request.get(urls.get_statistical + '?' + queryString, {});
    try {
        return res.data;
    } catch (e) {
    }
    return null;
};