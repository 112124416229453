import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import { PlusOutlined, UploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Button, Pagination, Spin, Popconfirm, notification, message, Modal, Upload } from 'antd';
import { ModalForm, ProFormText, ProFormDigit } from '@ant-design/pro-form';
import { ticket, authenticator } from '../../endpoint';
import { getParamsByUrlPaging } from '../../_until';
import { CKEditor, Context, Bold, Essentials, Italic, Paragraph, ContextWatchdog } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AppContext from './../../_until/AppContext';

/**
 * Add node
 *
 * @param dataForm
 */
const handleAdd = async (dataForm) => {
    const hide = message.loading('Processing...');
    try {
        var res = await ticket.addItemFrontend(dataForm);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            return true;
        } else {
            if(typeof res.message != 'undefined'){
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update node
 *
 * @param dataForm
 * @param id
 */
const handleUpdate = async (dataForm, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await ticket.updateItemFrontend(id, dataForm);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await ticket.deleteItemFrontend(selectedRows.id);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const ContactComponent = () => {
    const actionRef = useRef(null);
    const [form] = Form.useForm();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [dataLists, setDataLists] = useState([]);
    const [first, setFirst] = useState(true);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [dataForm, setDataForm] = useState({});
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const [currentRow, setCurrentRow] = useState([]);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [dataEditor, setDataEditor] = useState("");
    const [createModalVisible, handleModalVisible] = useState(false);
    const [filesUpload, setFilesUpload] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');
    const [isLoadNotification, setLoadNotification] = useContext(AppContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Contact - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    loadList({all: 1, ...paramQuery});
                    if (paramQuery?.current) {
                        delete paramQuery['current'];
                        delete paramQuery['pageSize'];
                    }
                    setDataForm(paramQuery);
                }
            }).then(res => {
            });
        }
    }, []);

    const onChange = (page, pageSize) =>{
        setCurrent(page);
        loadList({current: page, pageSize: pageSize, ...dataForm});
    }

    const loadList = (values) => {
        const hide = message.loading('Processing...');
        setLoading(true);

        var queryString = Object.keys(values).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(values[key])
        }).join('&');
        history.push(`?` + queryString);

        ticket.getListFrontend(values).then(res => {
            if (res.status == 'success') {
                setDataLists(res.response);
                setTotal(res.total);
                setFirst(false);
            } else if(res.status == 'fail'){
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoading(false);
        }).then(complete => {
            hide();
            setLoading(false);
        });
    }

    const onChangeFiles = (file) => {
        setFilesUpload(file.fileList);
    }

    const beforeUpload = (file) => {
        if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg" && file.type != "image/gif") {
            notification.open({
                message: 'Fail!',
                description:  `${file.name} not image file`,
                type: 'error'
            });
            return false;
        }
        const maxLimit = 3
        if (filesUpload.length > maxLimit) {
            notification.open({
                message: 'Fail!',
                description: 'Only 3 files allowed',
                type: 'error'
            });
            return false;
        }
      
        const maxFileSize = 3 * 1024 * 1024; // 3MB limit
        if (file.size > maxFileSize) {
            console.error("File size exceeds the limit of 3MB");
            notification.open({
                message: 'Fail!',
                description: `${file.name} file upload failed (exceeds 3MB)`,
                type: 'error'
            });
            return false;
        }
        return true;
    };

    const onRemoveFile = (file) => {
        let files = [];
        if (filesUpload.length > 0) {
            for(var i = 0; i < filesUpload.length; i++) {
                let tempFile = filesUpload[i];
                if (tempFile.uid != file.uid) {
                    files.push(tempFile);
                }
            }
        }
        setFilesUpload(files);
    }

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    return (
        <div className="container">
            {
                loading ? (
                    <div className="text-center mt-5 mb-5"><Spin /></div>
                ) :
                (
                    <div>
                        <div className="panel panel-default panel-custom">
                            <div className="panel-heading d-flex align-items-center justify-content-between">
                                <span>Contact</span>
                                <a className='btn btn-yellow btn-sm ml-2' onClick={() => {
                                        form.resetFields();
                                        setCurrentRow(null);
                                        handleModalVisible(true);
                                        setEditorLoaded(true);
                                        setDataEditor('');
                                        setFilesUpload([]);
                                    }}
                                >
                                    <PlusOutlined />
                                    <span className='pl-2'>New Contact</span>
                                </a>
                            </div>
                            <div className="panel-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-list-mobile">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataLists.length > 0 && dataLists.map((item, key) => 
                                                    <tr key={item.id}>
                                                        <td data-title="#">{((current - 1)*20) + key + 1}</td>
                                                        <td data-title="Title">
                                                            <Link to={'/contact-detail/' + item.id}>{item.title}</Link>
                                                        </td>
                                                        <td data-title="Status">{item.status == 1 ? (<span className="badge badge-success">Open</span>) : (<span className="badge badge-danger">Close</span>) }</td>
                                                        <td data-title="Date">{item.date}</td>
                                                        <td className='action'>
                                                            <Link to={'/contact-detail/' + item.id} className="text-success"><i className='fa fa-eye'></i> View Detail</Link>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            <tr className={dataLists.length == 0 && !first ? '' : 'd-none'}>
                                                <td colSpan="5" className="text-center">
                                                    No data.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    total > 20 && !loading ? (
                                        <div className="paging mt-4">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <Pagination current={current} total={total} showSizeChanger={false} defaultPageSize="20" onChange={onChange} />
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>

                        {/*Form Add new and Edit*/}
                        <ModalForm
                            id="form-add-contact"
                            title="New Contact"
                            width="768px"
                            form={form}
                            submitter={false}
                            visible={createModalVisible}
                            onVisibleChange={handleModalVisible}
                            onFinish={async (value) => {
                            }}
                        >
                            <ProFormText
                                rules={[
                                    {
                                        required: true,
                                        message: "Title is required"
                                    },
                                ]}
                                initialValue={currentRow?.title}
                                placeholder=""
                                width="full"
                                name="title"
                                label="Title:"
                            />
                            {
                                editorLoaded ? (
                                    <div className="mb-4 ant-form-item-label d-block">
                                        <label className="ant-form-item-required">Content:</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="content"
                                            style={{width: "100%"}}
                                            config={{
                                                //removePlugins: ['Image', 'MediaEmbed']
                                            }}
                                            data={dataEditor}
                                            onReady={(editor) => {
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setDataEditor(data);
                                            }}
                                            
                                        />
                                    </div>
                                ) : ""
                            }
                            <div className='form-group ant-form-item-label mt-3'>
                                <label className='d-block'>Images (3 images):</label>
                                <Upload beforeUpload={() => {beforeUpload(); return false;}} maxCount="3" multiple={true} onChange={onChangeFiles} onRemove={onRemoveFile}>
                                    <Button
                                        icon={<UploadOutlined />}
                                        className="upload-btn"
                                    >
                                        Add images
                                    </Button>
                                    <small className='d-block text-danger'>Please upload image maxsize 3MB.</small>
                                </Upload>
                                <p>
                                    Tips<br/>
                                    1. Only supports uploading images in jpg and png formats<br/>
                                    2. Upload up to <b>3</b> pictures<br/>
                                    3. Image size is less than 3MB<br/>
                                    <b>* We will contact you within 6-12 hours after you submit</b>
                                </p>
                            </div>
                            <div className='text-right'>
                                <Button
                                    type="primary"
                                    key="submitForm"
                                    loading={loadingButton}
                                    onClick={async () => {
                                        const value = form.getFieldsValue();
                                        form.submit();
                                        if (value.title && dataEditor) {
                                            var isVal = true;
                                            const formData = new FormData();
                                            formData.append('content', dataEditor);
                                            formData.append('title', value.title);
                                            if (filesUpload.length > 0) {
                                                for(var i = 0; i < filesUpload.length; i++) {
                                                    let tempFile = filesUpload[i];
                                                    if (beforeUpload(tempFile)) {
                                                        formData.append("image" + (i + 1), tempFile.originFileObj);
                                                    } else {
                                                        isVal = false;
                                                    }
                                                }
                                            }
                                            if (isVal) {
                                                setLoadingButton(true);
                                                if (currentRow?.id) {
                                                    const success = await handleUpdate(formData, currentRow?.id);
                                                    if (success) {
                                                        form.resetFields();
                                                        handleModalVisible(false);
                                                        loadList({all: 1, ...paramQuery});
                                                    }
                                                    setLoadingButton(false);
                                                    setLoadNotification(true);
                                                } else {
                                                    const success = await handleAdd(formData);
                                                    if (success) {
                                                        form.resetFields();
                                                        handleModalVisible(false);
                                                        setLoadNotification(true);
                                                        loadList({all: 1, ...paramQuery});
                                                    }
                                                    setLoadingButton(false);
                                                }
                                            }
                                        } else {
                                            notification.open({
                                                message: 'Fail!',
                                                description: "Please enter all infomation",
                                                type: 'error'
                                            });
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </ModalForm>
                    </div>
                )
            }
        </div>
    );
}
export default ContactComponent;