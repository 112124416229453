import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Header from '../components/header/header';
import Sidebar from '../components/sidebar/sidebar';
import Footer from '../components/footer/footer';
import TopBarProgress from "react-topbar-progress-indicator";
import ThemeRoutes from '../../../routes/routing';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import '../../../assets/scss/style.css';
import '../../../assets/scss/custom-admin.scss';

const Fulllayout = (props) => {
    /*--------------------------------------------------------------------------------*/
    /*Change the layout settings [HEADER,SIDEBAR && DARK LAYOUT] from here            */
    /*--------------------------------------------------------------------------------*/
    const history = useHistory();
    const [width, setWidth] = useState(window.innerWidth);
    const [loadingProcessBar, setLoadingProcessBar] = useState(true);
    const user = JSON.parse(localStorage.getItem('user'));

    window.addEventListener('load', function(){
        setLoadingProcessBar(true);
        setTimeout(function(){
            setLoadingProcessBar(false);
        }, 1500);
    });

    props.history.listen((location, action) => {
        if (window.innerWidth < 767 && $("#main-wrapper").hasClass('show-sidebar')) {
            $("#main-wrapper").toggleClass('show-sidebar')
        }
        setLoadingProcessBar(true);
        setTimeout(function(){
            setLoadingProcessBar(false);
        }, 1500);
    });

    const onScrollTop = () => {
        $("html, body").animate({ scrollTop: "0"}, 'slow');
    }

    const onScrollBottom = () => {
        $("html, body").animate({scrollTop: $(document).height()}, 'slow');
    }

    /*--------------------------------------------------------------------------------*/
    /*Function that handles sidebar, changes when resizing App                        */
    /*--------------------------------------------------------------------------------*/
    useEffect(() => {
        if (user?.user_groupid != '1' && !(user?.user_name == 'ngovanduc_n' || user?.user_name == 'shopsocks55')) {
            history.push({pathname: '/home'});
        } else {
            document.title = "Admin - " + window?.title;
            const updateDimensions = () => {
                setWidth(window.innerWidth)
                if (width < 1170) {
                    $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
                    $("#main-wrapper").addClass("mini-sidebar");
                } else {
                    $("#main-wrapper").attr("data-sidebartype", "full");
                    $("#main-wrapper").removeClass("mini-sidebar");
                }
            }
            if (document.readyState === "complete") {
                updateDimensions();
            }
            window.addEventListener("resize", updateDimensions.bind(this));
            window.addEventListener("load", updateDimensions.bind(this));
            return () => {
                window.removeEventListener("load", updateDimensions.bind(this));
                window.removeEventListener("resize", updateDimensions.bind(this));
            };
        }

    }, [width]);

    const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

    /*--------------------------------------------------------------------------------*/
    /* Theme Setting && Layout Options wiil be Change From Here                       */
    /*--------------------------------------------------------------------------------*/
    return (
        <ConfigProvider locale={enUS}>
            {loadingProcessBar && <div style={{ position: "fixed", zIndex: '100', right: "5px", top: "5px" }}><Spin indicator={antIcon} /></div>}
            {loadingProcessBar && <TopBarProgress />}
            <div
                id="main-wrapper"
                data-theme="light"
                data-layout="vertical"
                data-sidebartype="full"
                data-sidebar-position="fixed"
                data-header-position="fixed"
                data-boxed-layout="full"
            >
                {/*--------------------------------------------------------------------------------*/}
                {/* Header                                                                         */}
                {/*--------------------------------------------------------------------------------*/}
                <Header />

                {/*--------------------------------------------------------------------------------*/}
                {/* Sidebar                                                                        */}
                {/*--------------------------------------------------------------------------------*/}
                <Sidebar {...props} routes={ThemeRoutes} />
                
                {/*--------------------------------------------------------------------------------*/}
                {/* Page Main-Content                                                              */}
                {/*--------------------------------------------------------------------------------*/}
                <div className="page-wrapper d-block">
                    <div className="page-content container-fluid">
                        <Switch>
                            {ThemeRoutes.map((prop, key) => {
                                if (prop.redirect) {
                                    return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                                }
                                else {
                                    return (
                                        <Route path={prop.path} component={prop.component} key={key} />
                                    );
                                }
                            })}
                        </Switch>
                    </div>
                    <Footer />
                </div>
                <div className='scroll-bottom'>
                    <a onClick={onScrollBottom}><i className='fa fa-angle-down'></i></a>
                </div>
                <div className='scroll-top'>
                    <a onClick={onScrollTop}><i className='fa fa-angle-up'></i></a>
                </div>
            </div>
        </ConfigProvider>
    );
}
export default Fulllayout;
