import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Pagination, Spin, notification, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { account, authenticator } from '../../endpoint';
import { getParamsByUrlPaging, copyTextToClipboard } from '../../_until';

const UserInvite = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingUser, setLoadingUser] = useState(true);
    const [dataLists, setDataLists] = useState([]);
    const [dataListsUser, setDataListsUser] = useState([]);
    const [first, setFirst] = useState(true);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [totalUser, setTotalUser] = useState(0);
    const [currentUser, setCurrentUser] = useState(1);
    const [dataForm, setDataForm] = useState({});
    const [dataService, setDataService] = useState({});
    const [profile, setProfile] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    /**
     * Handle change page
     *
     * @param page
     * @param pageSize
     */
    const onChange = (page, pageSize) =>{
        setCurrent(page);
        loadList({current: page, pageSize: pageSize, ...dataForm});
    }

    /**
     * Load list result invite
     *
     * @param values
     */
    const loadList = (values) => {
        const hide = message.loading('Processing...');
        setLoading(true);

        var queryString = Object.keys(values).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(values[key])
        }).join('&');
        history.push(`?` + queryString);

        account.get_list_result_invite_history(values).then(res => {
            if (res.status == 'success') {
                setDataLists(res.response);
                setTotal(res.total);
                setFirst(false);
                setDataService(res?.data_service);
            } else if(res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoading(false);
        }).then(complete => {
            hide();
            setLoading(false);
        });
    }

    /**
     * Load list user invite
     *
     * @param values
     */
    const loadListUser = (values) => {
        setLoadingUser(true);
        var queryString = Object.keys(values).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(values[key])
        }).join('&');
        history.push(`?` + queryString);

        account.get_list_user_invite_history(values).then(res => {
            if (res.status == 'success') {
                setDataListsUser(res.response);
                setTotalUser(res.total);
            } else if(res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoadingUser(false);
        }).then(complete => {
            setLoadingUser(false);
        });
    }

    /**
     * Handle change page user
     *
     * @param page
     * @param pageSize
     */
    const onChangeUser = (page, pageSize) =>{
        setCurrentUser(page);
        loadListUser({current: page, pageSize: pageSize});
    }

    /**
     * Handle get info profile
     */
    const getProfile = () => {
        account.profile().then(res => {
            if (res.status == 'success') {
                setProfile(res.response);
            }
        });
    }

    /**
     * Handle copy text
     *
     * @param textCopy
     */
    const handleCopyText = (textCopy) => {
        if (copyTextToClipboard(textCopy)) {
            notification.open({
                message: 'Success',
                description: "Copy Success!.",
                type: 'success'
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: "Copy fail!.",
                type: 'error'
            });
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "User Invite - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    loadListUser({all: 1});
                    loadList({all: 1, ...paramQuery});
                    if(paramQuery?.current){
                        delete paramQuery['current'];
                        delete paramQuery['pageSize'];
                    }
                    setDataForm(paramQuery);
                    getProfile();
                }
            }).then(res => {
            });
        }
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    return (
        <div className="container">
            <div className="content mb-2">
                {
                    loading ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        <div className='row no-gutters top-service top-user-invite'>
                            <div className='col-md col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Total Referral</div>
                                    <div className="panel-body bg-primary">
                                        <h3 className='text-white'>{dataService?.total} Point</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Referral code</div>
                                    <div className="panel-body">
                                        <h3><a onClick={() => handleCopyText(dataService?.code)}>{dataService?.code} <CopyOutlined className='pl-1' /></a></h3>
                                        <h3><a onClick={() => handleCopyText('https://shopsocks5.com/register.html?invite_code=' + dataService?.code)}>Referral Link <CopyOutlined className='pl-1' /></a></h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Total Referral Complete</div>
                                    <div className="panel-body">
                                        <h3>{dataService?.total_complete} Point</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Total Referral Pending</div>
                                    <div className="panel-body">
                                        <h3>{dataService?.total_pending} Point</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md col-6'>
                                <div className="panel panel-default panel-custom">
                                    <div className="panel-heading">Percentage Referral</div>
                                    <div className="panel-body">
                                        <h3>{dataService?.percent}%</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="content mb-2">
                <p>Your current commission rate is 3.00%, then invite users to recharge 500 USD to get 5.00% commission rate.. <a href="https://shopsocks5.com/faq-detail/Rules-for-Inviting-Activities">Rules for Inviting Activities</a></p>
                {
                    loading ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        <div className="panel panel-default panel-custom">
                            <div className="panel-heading">List of refs</div>
                            <div className="panel-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-list-mobile">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>User</th>
                                                <th>Price</th>
                                                <th>Percent</th>
                                                <th>Status</th>
                                                <th>Note</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataLists.length > 0 && dataLists.map((item, key) => 
                                                    <tr key={item.id}>
                                                        <td data-title="#">{((current - 1)*20) + key + 1}</td>
                                                        <td data-title="User">{item.user_name}({item.user_mail})</td>
                                                        <td data-title="Price">{item.credit} Point</td>
                                                        <td data-title="Percent">{item.percent}%</td>
                                                        <td data-title="Status">
                                                            { item.status == 2 && (<span className='text-danger text-bold'>Refund</span>) }
                                                            { item.status == 1 && (<span className='text-success text-bold'>Complete</span>) }
                                                            { item.status == 0 && (<span className='text-info text-bold'>Pending</span>) }
                                                        </td>
                                                        <td data-title="Note">{item.note}</td>
                                                        <td data-title="Date">{item.date}</td>
                                                    </tr>
                                                )
                                            }
                                            <tr className={dataLists.length == 0 && !first ? '' : 'd-none'}>
                                                <td colSpan="7" className="text-center">
                                                    No data.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    total > 20 && !loading ? (
                                        <div className="paging mt-4">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <Pagination current={current} total={total} showSizeChanger={false} defaultPageSize="20" onChange={onChange} />
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="content">
                {
                    loadingUser ? (
                        <div className="text-center mt-5 mb-5"><Spin /></div>
                    ) :
                    (
                        <div className="panel panel-default panel-custom">
                            <div className="panel-heading">List received</div>
                            <div className="panel-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-list-mobile">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>User</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataListsUser.length > 0 && dataListsUser.map((item, key) => 
                                                    <tr key={item.id}>
                                                        <td data-title="#">{((currentUser - 1)*20) + key + 1}</td>
                                                        <td data-title="User">{item.user_name}({item.user_mail})</td>
                                                        <td data-title="Status"><span className='text-success text-bold'>Complete</span></td>
                                                        <td data-title="Date">{item.date}</td>
                                                    </tr>
                                                )
                                            }
                                            <tr className={dataListsUser.length == 0 && !first ? '' : 'd-none'}>
                                                <td colSpan="4" className="text-center">
                                                    No data.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    totalUser > 20 && !loadingUser ? (
                                        <div className="paging mt-4">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <Pagination current={currentUser} total={totalUser} showSizeChanger={false} defaultPageSize="20" onChange={onChangeUser} />
                                                </div>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
export default UserInvite;