import { useEffect, useState } from 'react';

const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();
    const [countDown, setCountDown] = useState(
        countDownDate - new Date((new Date).toLocaleString('en-US', {timeZone: 'Asia/Ho_Chi_Minh'})).getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date((new Date).toLocaleString('en-US', {timeZone: 'Asia/Ho_Chi_Minh'})).getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

const CountdownTimer = ({ targetDate, show }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
    if (days + hours + minutes + seconds <= 0) {
        return show;
    } else {
        return ((days > 0 ? days + 'd:' : '') + (hours > 0 ? hours + 'h:' : '') + minutes + 'm:' + seconds + 's');
    }
};
export default CountdownTimer;