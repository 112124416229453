import { Request } from '../_until';
import { notification } from 'antd';

export const proxy = {
    getList,
    getItem,
    updateItem,
    addItem,
    changeRenew,
    changeInstallSock,
    deleteItems,
    getListFrontend,
    buyProxy,
    getDetailFrontend,
    reinstallFrontend,
    renewFrontend,
    changeIpFrontend,
    changeAutoRenewFrontend,
    autoFixFrontend,
    pauseActionFrontend,
    rebootFrontend,
    noteProxyFrontend,
    bucketActionFrontend,
    getContentChangeIpFrontend,
    checkPriceChangeIpFrontend,
    getDiscount
};

const urls = {
    get_list: `/proxy`,
    get_item: `/proxy/get/`,
    add_item: `/proxy/add`,
    edit_item: `/proxy/update`,
    change_renew: `/proxy/change_renew/`,
    delete_item: `/proxy/delete`,
    change_install_sock: `/proxy/change_install_sock/`
};

const websiteUrls = {
    get_list: `proxy`,
    buy_proxy: `proxy/buy_proxy`,
    get_item: `proxy/detail/`,
    reinstall: `proxy/reinstall/`,
    renew: `proxy/renew/`,
    change_ip: `proxy/change_ip/`,
    change_auto_renew: `proxy/change_auto_renew/`,
    auto_fix: `proxy/auto_fix/`,
    pause_action: `proxy/pause_action/`,
    reboot: `proxy/reboot/`,
    note_proxy: `proxy/note_proxy/`,
    bucket_action: `proxy/bucket_action`,
    get_content_change_ip_proxy: `proxy/get_content_change_ip_proxy/`,
    check_price_change_list_ip: `proxy/check_price_change_list_ip`,
    get_discount: `proxy/get_discount`,
};

async function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    const res = await Request.get(urls.get_list + '?' + queryString, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            return {
                success: true,
                data: response.response,
                total: response.total
            };
        } else if (response.status === 'fail') {
            if (typeof response.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: response.message,
                    type: 'error'
                });
            }
        }
    } catch (e) {

    }
    return null;
};

async function getItem(id) {
    const res = await Request.get(urls.get_item + id, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {}
    return null;
};

async function updateItem(requestOptions) {
    return await Request.post(urls.edit_item, requestOptions).then(res => res.data);
};

async function addItem(requestOptions) {
    return await Request.post(urls.add_item, requestOptions).then(res => res.data);
};

async function changeRenew(id, requestOptions) {
    return await Request.post(urls.change_renew + id, requestOptions).then(res => res.data);
};

async function changeInstallSock(id, requestOptions) {
    return await Request.post(urls.change_install_sock + id, requestOptions).then(res => res.data);
};

async function deleteItems(requestOptions) {
    return await Request.post(urls.delete_item, requestOptions).then(res => res.data);
};

async function getListFrontend(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(websiteUrls.get_list + '?' + queryString, {}).then(res => res.data);
};

async function getDetailFrontend(id) {
    return await Request.get_website(websiteUrls.get_item + id, {}).then(res => res.data);
};

async function buyProxy(requestOptions) {
    return await Request.post_website(websiteUrls.buy_proxy, requestOptions).then(res => res.data);
};

async function reinstallFrontend(id, requestOptions) {
    return await Request.post_website(websiteUrls.reinstall + id, requestOptions).then(res => res.data);
};

async function renewFrontend(id, requestOptions) {
    return await Request.post_website(websiteUrls.renew + id, requestOptions).then(res => res.data);
};

async function changeIpFrontend(id, requestOptions) {
    return await Request.post_website(websiteUrls.change_ip + id, requestOptions).then(res => res.data);
};

async function changeAutoRenewFrontend(id, requestOptions) {
    return await Request.post_website(websiteUrls.change_auto_renew + id, requestOptions).then(res => res.data);
};

async function autoFixFrontend(id) {
    return await Request.post_website(websiteUrls.auto_fix + id, {}).then(res => res.data);
};

async function pauseActionFrontend(id) {
    return await Request.post_website(websiteUrls.pause_action + id, {}).then(res => res.data);
};

async function rebootFrontend(id) {
    return await Request.post_website(websiteUrls.reboot + id, {}).then(res => res.data);
};

async function noteProxyFrontend(id, requestOptions) {
    return await Request.post_website(websiteUrls.note_proxy + id, requestOptions).then(res => res.data);
};

async function bucketActionFrontend(requestOptions) {
    return await Request.post_website(websiteUrls.bucket_action, requestOptions).then(res => res.data);
};

async function getContentChangeIpFrontend(id) {
    return await Request.post_website(websiteUrls.get_content_change_ip_proxy + id, {}).then(res => res.data);
};

async function checkPriceChangeIpFrontend(listIds) {
    return await Request.post_website(websiteUrls.check_price_change_list_ip, {'ids': listIds}).then(res => res.data);
};

async function getDiscount(requestOptions) {
    return await Request.post_website(websiteUrls.get_discount, requestOptions).then(res => res.data);
};
