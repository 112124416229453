import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Col,
    Row
} from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { dashboard } from '../../../../../endpoint';

const SalesSummary = () => {
    const [data, setData] = useState({
        labels: [],
        datasets: [{
            label: 'Total Order',
            borderWidth: 1,
            backgroundColor: 'rgba(94,114,228,.1)',
            borderColor: 'rgb(94,114,228)',
            pointBorderColor: 'rgb(94,114,228)',
            pointBackgroundColor: 'rgb(94,114,228)',
            data: []
        },
        {
            label: 'Total Price',
            borderWidth: 1,
            backgroundColor: 'rgba(79,195,247,.1)',
            borderColor: 'rgb(79,195,247)',
            pointBorderColor: 'rgb(79,195,247)',
            pointBackgroundColor: 'rgb(79,195,247)',
            data: []
        }]
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const data = await dashboard.getList();
        if (data.status == 'success') {
            //Line chart
            let lineData = {
                labels: data.response.label,
                datasets: [{
                    label: 'Total Order',
                    borderWidth: 1,
                    backgroundColor: 'rgba(94,114,228,.1)',
                    borderColor: 'rgb(94,114,228)',
                    pointBorderColor: 'rgb(94,114,228)',
                    pointBackgroundColor: 'rgb(94,114,228)',
                    data: data.response.count
                },
                {
                    label: 'Total Price',
                    borderWidth: 1,
                    backgroundColor: 'rgba(79,195,247,.1)',
                    borderColor: 'rgb(79,195,247)',
                    pointBorderColor: 'rgb(79,195,247)',
                    pointBackgroundColor: 'rgb(79,195,247)',
                    data: data.response.total
                }]
            };
            setData(lineData);
        }
    }

    return (
        <Card>
            <CardBody>
                <div className="d-flex align-items-center">
                    <div>
                        <CardTitle>Sales Summary</CardTitle>
                        <CardSubtitle>Summary of the month</CardSubtitle>
                    </div>
                    <div className="ml-auto d-flex align-items-center">
                        <ul className="list-inline font-12 dl mr-3 mb-0">
                            <li className="border-0 p-0 text-info list-inline-item">
                                <i className="fa fa-circle"></i> Total Count
                            </li>
                            <li className="border-0 p-0 text-primary list-inline-item">
                                <i className="fa fa-circle"></i> Total Price
                            </li>
                        </ul>
                    </div>
                </div>
                <Row>
                    <Col lg="12">
                        <div className="campaign ct-charts">
                            <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 250 }}>
                                <Line data={data} options={{ maintainAspectRatio: false, legend: { display: false, labels: { fontFamily: "Nunito Sans" } }, scales: { yAxes: [{ stacked: true, gridLines: { display: false }, ticks: { fontFamily: "Nunito Sans" } }], xAxes: [{ gridLines: { display: false }, ticks: { fontFamily: "Nunito Sans" } }] } }} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default SalesSummary;
