import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { ExportOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form, Spin } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDigit } from '@ant-design/pro-form';
import { authenticator } from '../../../../endpoint';

const Auth2FAVerifyComponent = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [verifyCode, setVerifyCode] = useState('');
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        authenticator.get2fa().then(res => {
            if (res.status == 'success') {
                if (res.security?.enable != 1) {
                    history.push({pathname: '/ntsock/2fa'});
                }
            }
        }).then(res => {
            setLoading(false);
        });
    }, []);

    /**
     * verify 2FA
     */
    const verify2fa = () => {
        if (verifyCode) {
            const hide = message.loading('Processing...');
            setLoading(true);
            try {
                authenticator.verify2fa({"one_time_password": verifyCode}).then(res => {
                    if (res.status == 'success') {
                        localStorage.setItem('one_time_password', verifyCode);
                        history.push({pathname: '/ntsock/dashboard'});
                    } else {
                        notification.open({
                            message: 'Fail!',
                            description: res.message,
                            type: 'error'
                        });
                    }
                }).then(res => {
                    hide();
                    setLoading(false);
                });
            } catch (error) {
                hide();
                setLoading(false);
                notification.open({
                    message: 'Fail!',
                    description: 'Error, please try again!',
                    type: 'error'
                });
            }
        } else {
            notification.open({
                message: 'Fail!',
                description: 'Please enter One Time Password.',
                type: 'error'
            });
        }
        return false;
    };

    /**
     * Onchange verify code
     */
    const handleOnChangeVerifyCode = event => {
        const { name, value } = event.target;
        setVerifyCode(value);
    };

    return (
        <PageContainer>
            <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
            <div className={ "card" + (loading ? " d-none" : "")}>
                <div className="card-body">
                    <h3>Two Factor Authentication</h3>
                    <p>Two factor authentication (2FA) strengthens access security by requiring two methods (also referred to as factors) to verify your identity. Two factor authentication protects against phishing, social engineering and password brute force attacks and secures your logins from attackers exploiting weak or stolen credentials.</p>
                    <strong>Enter the pin from Google Authenticator Enable 2FA</strong><br/><br/>
                    <div className="form-group">
                        <label className="control-label">One Time Password</label>
                        <div className="row">
                            <div className="col-md-4">
                                <input id="one_time_password" name="one_time_password" className="form-control" onChange={handleOnChangeVerifyCode} type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 col-md-offset-4">
                                <button className="btn btn-success" onClick={verify2fa}>Authenticate</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageContainer>
    );
}
export default Auth2FAVerifyComponent;
