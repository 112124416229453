import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Spin, notification, message, Modal } from 'antd';
import { sock_list, banner, authenticator } from '../../endpoint';
import { copyTextToClipboard } from '../../_until';

const Sock24h = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [dataLists, setDataLists] = useState([]);
    const [dataListBanner, setListBanner] = useState([]);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [dataAds, setBannerAds] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        document.title = "Socks5 24H - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    sock_list.getList24h({all: 1}).then(res => {
                        if (res.status == 'success') {
                            setDataLists(res.response);
                        } else if(res.status == 'fail') {
                            notification.open({
                                message: 'Fail!',
                                description: res.message,
                                type: 'error'
                            });
                        }
                    }).then(err => {
                        setLoading(false);
                    }).then(complete => {
                        setLoading(false);
                    });
                }
            }).then(res => {
            });
        }

        banner.get_list_banner({position: 'sock'}).then(res => {
            if (res.status == 'success') {
                setListBanner(res.response);
            }
        });
    }, []);

    /**
     * Handle download sock 24h
     */
    const downloadSock24h = () => {
        const hide = message.loading('Processing...');
        sock_list.downloadSock24h().then((res) => {
            if (res.status == 'success') {
                const link = document.createElement('a');
                link.setAttribute('download', '');
                link.href = res.url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                notification.open({
                    message: 'Fail!',
                    description: "Download error!. Please again.",
                    type: 'error'
                });
            }
        }).then(err => {
        }).then(complete => {
            hide();
        });
    }

    /**
     * Handle copy show fill
     */
    const copyShowFill = () => {
        let textFill = '';
        for (let index = 0; index < dataLists.length; index++) {
            const item = dataLists[index];
            if (item.user_name) {
                textFill = textFill + (item.sock_ip + ':' + item.user_name + ':' + item.password) + '\n';
            } else {
                textFill = textFill + item.sock_ip + '\n';
            }
        }
        if (copyTextToClipboard(textFill)) {
            notification.open({
                message: 'Success',
                description: "Copy Success!.",
                type: 'success'
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: "Copy fail!.",
                type: 'error'
            });
        }
    }

    /**
     * Handle copy text
     *
     * @param textCopy
     */
    const copyText = (textCopy) => {
        if (copyTextToClipboard(textCopy)) {
            notification.open({
                message: 'Success',
                description: "Copy Success!.",
                type: 'success'
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: "Copy fail!.",
                type: 'error'
            });
        }
    }

    /**
     * Handle cancel modal ads
     */
    const handleCancel = () => {
        setIsModalVisibleAds(false);
    };

    /**
     * Handle ok
     */
    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    /**
     * Handle show ads
     *
     * @param record
     */
    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    const dataListBannerResult = dataListBanner.map((item, key) => {
        return (
            item.name != 'shopsocks5.com' ? (
                <div className={"col-12 col-sm-" + (item.is_full != 0 ? (12/item.is_full) : '12')} key={item.id}>
                    <a onClick={()=> handleShowModalAds(item)} className='d-block text-center mb-2'>
                        <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                    </a>
                </div>
            ) : ''
        )
    })

    return (
        <div className="container">
            {
                loading ? (
                    <div className="text-center mt-5 mb-5"><Spin /></div>
                ) :
                (
                    <div>
                        <div className="servicer">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row">
                                        {dataListBannerResult}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default panel-custom">
                            <div className="panel-heading">
                                List Sock 24H
                                {
                                    dataLists.length > 0 ? (
                                        <a onClick={() => downloadSock24h()} className='btn btn-info btn-sm ml-2'><i className="fa fa-download" aria-hidden="true"></i></a>
                                    ) : ''
                                }
                                {
                                    dataLists.length > 0 ? (
                                        <a onClick={() => copyShowFill()} className='btn btn-info btn-sm ml-2'>Copy List Socks5</a>
                                    ) : ''
                                }
                            </div>
                            <div className="panel-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-sock24h table-list-mobile">
                                        <thead>
                                            <tr>
                                                <th>Sock</th>
                                                <th>Proxy Type</th>
                                                <th>Country</th>
                                                <th>Region</th>
                                                <th>City</th>
                                                <th>Zipcode</th>
                                                <th>ISP Name</th>
                                                <th>Ip</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataLists.length > 0 && dataLists.map((item, key) => 
                                                    <tr key={item.sock_id}>
                                                        <td data-title="Sock" className="sock">
                                                            {
                                                                item.user_name != null ? 
                                                                    (<a onClick={() => copyText(item.sock_ip + ':' + item.user_name + ':' + item.password)}>{item.sock_ip + ':' + item.user_name + ':' + item.password}</a>) :
                                                                    (<a onClick={() => copyText(item.sock_ip)}>{item.sock_ip}</a>)
                                                            }
                                                        </td>
                                                        <td data-title="Proxy Type">{item.proxy_type == 'proxy_https' ? 'HTTPS Proxy' : 'SOCKS5 Proxy'}</td>
                                                        <td data-title="Country">
                                                            <img src={item.country_img} />&nbsp;&nbsp;
                                                            {item.sock_country}
                                                        </td>
                                                        <td data-title="Region">{item.sock_states}</td>
                                                        <td data-title="City">{item.sock_city}</td>
                                                        <td data-title="Zipcode">{item.sock_zip}</td>
                                                        <td data-title="ISP Name" className="host">{item.sock_hostname}</td>
                                                        <td data-title="Ip">{item.ip_real}</td>
                                                    </tr>
                                                )
                                            }
                                            <tr className={dataLists.length == 0 ? '' : 'd-none'}>
                                                <td colSpan="8" className="text-center no-padding">
                                                    You don't have any socks5 yet.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default Sock24h;