import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Modal, notification, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { account } from '../../endpoint';
import { getParamsByUrlPaging } from '../../_until';

const ResetPassword = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const user = JSON.parse(localStorage.getItem('user'));

    /**
     * Handle submit form change password
     *
     * @param values 
     */
    const onFinish = async (values) => {
        const hide = message.loading('Processing...');
        try {
            var res = await account.reset_password({...paramQuery, ...values});
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: res.message,
                    type: 'success'
                });
                history.push({pathname: '/login.html'});
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                        type: 'error'
                    });
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Forgot failed, please try again!',
                type: 'error'
            });
        }
    };

    /**
     * Check change password
     */
    const checkChangePassword = () => {
        account.check_reset_password(paramQuery).then(res => {
            if (res?.status != 'success') {
                history.push({pathname: '/forgot.html'});
            }
        }).then(err => {
        });
    }

    useEffect(() => {
        document.title = "Reset Password - " + window?.title;
        if (user != null) {
            history.push({pathname: '/home'});
        } else {
            checkChangePassword();
        }
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-3"></div>
                <div className="col-md-4 col-sm-6">
                    <div className="panel panel-default panel-custom">
                        <div className="panel-heading">CHANGE PASSWORD</div>
                        <div className="panel-body">
                            <div id="cards">
                                <div className="section_content">
                                    <Form
                                        name="basic"
                                        onFinish={onFinish}
                                        className="form-horizontal mt-3"
                                    >
                                        <div className="form-group">
                                            <Form.Item
                                                label="New password:"
                                                labelCol={{span: 24}}
                                                name="user_pass_new"
                                                rules={[{ required: true, message: 'Please input new password!' }, { min: 6, message: 'New Password must be minimum 6 characters.' }]}
                                            >
                                                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="New password" />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <Form.Item
                                                label="Repeat password:"
                                                labelCol={{span: 24}}
                                                name="user_pass_new_re"
                                                rules={[{ required: true, message: 'Please input new password!' }, { min: 6, message: 'Repeat password must be minimum 6 characters.' }]}
                                            >
                                                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Repeat password" />
                                            </Form.Item>
                                        </div>
                                        <div className="mb-3 text-center">
                                            <div className="col-xs-12 pb-3">
                                                <Button type="primary" htmlType="submit" loading={loading} className="d-block medium">Submit</Button>
                                                <div className="h-10"></div>
                                                <Link to="/">Cancel</Link>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-3"></div>
            </div>
        </div>
    );
}
export default ResetPassword;
