import { Request } from '../_until';
import { notification } from 'antd';

export const socks = {
    getList,
    getItem,
    updateItem,
    addItem,
    addItemDichvu,
    changeStatus,
    deleteItems,
    getSelect,
    historySock,
    checkSock,
    exportTXT,
    clearSock18h,
    addSockSiteCheck
};

const urls = {
    get_list: `/socks`,
    get_item: `/socks/get/`,
    get_select: `/socks/get_country`,
    add_item: `/socks/add`,
    add_dichvu_item: `/socks/add_dichvu`,
    edit_item: `/socks/update`,
    change_status: `/socks/change_status/`,
    delete_item: `/socks/delete`,
    history_check: `sock_list/history_check_sock`,
    check: `sock_list/check_sock`,
    export: `/socks/export`,
    clean18h: `/socks/clean_sock`,
    add_sock_site_check: `/socks/add_sock_from_site_check`
};

async function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    const res = await Request.get(urls.get_list + '?' + queryString, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            return {
                success: true,
                data: response.response,
                total: response.total
            };
        } else if (response.status === 'fail') {
            if (typeof response.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: response.message,
                    type: 'error'
                });
            }
        }
    } catch (e) {

    }
    return null;
};

async function getItem(id) {
    const res = await Request.get(urls.get_item + id, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {}
    return null;
};

async function updateItem(requestOptions) {
    return await Request.post(urls.edit_item, requestOptions).then(res => res.data);
};

function addItem(requestOptions) {
    return Request.post(urls.add_item, requestOptions).then(res => res.data);
};

function addItemDichvu(requestOptions) {
    return Request.post(urls.add_dichvu_item, requestOptions).then(res => res.data);
};

async function changeStatus(id, requestOptions) {
    return await Request.post(urls.change_status + id, requestOptions).then(res => res.data);
};

async function deleteItems(requestOptions) {
    return await Request.post(urls.delete_item, requestOptions).then(res => res.data);
};

async function getSelect() {
    const requestOptions = {};
    const res = await Request.get(urls.get_select, requestOptions);
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

function historySock(requestOptions) {
    return Request.post_website(urls.history_check, requestOptions).then(res => res.data);
};

function checkSock(requestOptions) {
    return Request.post_website(urls.check, requestOptions).then(res => res.data);
};

async function exportTXT(requestOptions) {
    return await Request.post(urls.export, requestOptions).then(res => res.data);
};

async function clearSock18h(requestOptions) {
    return await Request.post(urls.clean18h, requestOptions).then(res => res.data);
};

async function addSockSiteCheck() {
    return await Request.post(urls.add_sock_site_check, {}).then(res => res.data);
};