import { Request } from '../_until';

export const auth = {
    login,
    register,
    forgot,
    reset
};

const urls = {
    login: `/user_author/login`,
    register: `/user_author/signup`,
    forgot: `/user_author/forgot`,
    reset: `/user_author/reset`,
};

async function login(requestOptions) {
    return await Request.post(urls.login, requestOptions).then(res => res.data);
};

async function register(requestOptions) {
    return await Request.post(urls.register, requestOptions).then(res => res.data);
};

async function forgot(requestOptions) {
    return await Request.post(urls.forgot, requestOptions).then(res => res.data);
};

async function reset(requestOptions) {
    return await Request.post(urls.reset, requestOptions).then(res => res.data);
};