import React, { useState, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { PlusOutlined, LockOutlined, UserOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Tabs, notification, Form, Upload, Input, Spin } from 'antd';
import { account, authenticator } from '../../../../endpoint';

const { TabPane } = Tabs;

const Profile = () => {
    const [loading, setLoading] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [profile, setProfile] = useState([]);
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const history = useHistory();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
        
        account.profile().then(res => {
            if (res.status == 'success') {
                setProfile(res.response);
                setLoadingProfile(false);
            }
        });
    }, []);


    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
        </div>
    );

    /**
     * Update Password
     *
     * @param values
     */
    const onFinish = (values) => {
        const hide = message.loading('Processing...');
        try {
            setLoading(true);
            account.change_password_admin(values).then(res => {
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: 'Save successfully',
                        type: 'success'
                    });
                    form1.resetFields();
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: res.message,
                            type: 'error'
                        });
                    }
                }
            }).then(err => {
                hide();
                setLoading(false);
            });
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
        }
    };

    /**
     * Update Profile
     *
     * @param values
     */
    const onFinishProfile = (values) => {
        const hide = message.loading('Processing...');
        try {
            setLoading(true);
            account.change_profile(values).then(res => {
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: 'Save successfully',
                        type: 'success'
                    });
                    form1.resetFields();
                    setProfile(res.response);
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: res.message,
                            type: 'error'
                        });
                    }
                }
            }).then(com => {
                hide();
                setLoading(false);
            }).then(err => {
                hide();
                setLoading(false);
            });
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
            setLoading(false);
        }
    };

    /**
     * Update Avatar
     *
     * @param files
     */
    const handleChange = (files) => {
        const formData = new FormData();
        formData.append( 
            "file", 
            files
        );
        account.change_avatar(formData).then(res => {
            console.log(res);
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: 'Save successfully',
                    type: 'success'
                });
            } else {
                if (typeof res.message != 'undefined') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }
        }).then(com => {
        }).then(err => {
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            {
                                loadingProfile && (<div className="text-center mt-5 mb-5"><Spin /></div>)
                            }
                            {
                                !loadingProfile && (
                                    <div className="text-center mt-4">
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={handleChange}
                                            accept="image/*" 
                                        >
                                            {profile?.avatar ? <img src={profile?.avatar} alt="avatar" style={{ width: '100%', borderRadius: "50%" }} /> : ''}
                                            {uploadButton}
                                        </Upload>
                                        <div className="mt-2 card-title">{profile?.full_name}</div>
                                        <div className="card-subtitle">{profile?.user_mail}</div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-12 col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Profile" key="1">
                                    {
                                        loadingProfile && (<div className="text-center mt-5"><Spin /></div>)
                                    }
                                    {
                                        !loadingProfile && (
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-1"></div>
                                                <div className="col-12 col-md-12 col-lg-10">
                                                    <Form
                                                        name="basic"
                                                        form={form}
                                                        className="form-horizontal mt-3 form"
                                                        onFinish={onFinishProfile}
                                                    >
                                                        <Form.Item
                                                            label="Full Name:"
                                                            labelCol={{span: 24}}
                                                            name="full_name"
                                                            initialValue={profile?.full_name}
                                                            required={true}
                                                            rules={[{ required: true, message: 'Please input Full Name!' }]}
                                                        >
                                                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Full Name" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="Username:"
                                                            name="user_name"
                                                            initialValue={profile?.user_name}
                                                            labelCol={{span: 24}}
                                                        >
                                                            <Input prefix={<UserOutlined className="site-form-item-icon" />} disabled={true} placeholder="User Name" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="Email:"
                                                            name="user_mail"
                                                            initialValue={profile?.user_mail}
                                                            labelCol={{span: 24}}
                                                        >
                                                            <Input prefix={<UserOutlined className="site-form-item-icon" />} disabled={true} placeholder="Email" />
                                                        </Form.Item>
                                                        
                                                        <div className="row">
                                                            <div className="col-md-12 text-right">
                                                                <Button type="primary" htmlType="submit" loading={loading} className="medium">Save</Button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        )
                                    }
                                </TabPane>
                                <TabPane tab="Change Password" key="2">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-1"></div>
                                        <div className="col-12 col-md-12 col-lg-10">
                                            <Form
                                                name="basic"
                                                form={form1}
                                                className="form-horizontal mt-3 form"
                                                onFinish={onFinish}
                                            >
                                                <Form.Item
                                                    label="New password:"
                                                    labelCol={{span: 24}}
                                                    name="user_pass_new"
                                                    rules={[{ required: true, message: 'Please input new password!' }, { min: 6, message: 'New Password must be minimum 6 characters.' }]}
                                                >
                                                    <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="New password" />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Repeat password:"
                                                    labelCol={{span: 24}}
                                                    name="user_pass_new_re"
                                                    rules={[{ required: true, message: 'Please input new password!' }, { min: 6, message: 'Repeat password must be minimum 6 characters.' }]}
                                                >
                                                    <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Repeat password" />
                                                </Form.Item>
                                                <div className="row">
                                                    <div className="col-md-12 text-right">
                                                        <Button type="primary" htmlType="submit" loading={loading} className="medium">Save</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Profile;
