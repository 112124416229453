import React from 'react';
import { Modal, Form, Button, Space, Input, Upload, Checkbox } from 'antd';
import { ProFormText, ProFormTextArea, ProFormDigit, StepsForm } from '@ant-design/pro-form';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

const ConfigUpdate = (props) => {

    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    }

    return (
        <Modal
            width={420}
            destroyOnClose
            footer={false}
            title="Edit Plan"
            visible={props.updateModalVisible}
            onCancel={() => {
                props.onCancel();
            }}
        >
            <Form
                onFinish={props.onSubmit}
                autoComplete="off"
                initialValues={{
                    config_value: props.values?.config_value,
                    config_name: props.values?.config_name,
                    config_description: props.values?.config_description,
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Name is required"
                        },
                    ]}
                    width="lg"
                    name="config_name"
                    label="Name:"
                    placeholder="Name"
                />
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Name is required"
                        },
                    ]}
                    width="lg"
                    name="config_value"
                    label="Value:"
                    placeholder="Value"
                />
                <ProFormTextArea
                    width="lg"
                    name="config_description"
                    label="Description:"
                    placeholder="Description"
                />

                <label>Logos:</label>
                <Form.List name="logos">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 1 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'upload']}
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                        rules={[{ required: true, message: 'Logo is required' }]}
                                    >
                                        <Upload name="logo" action="/upload.do" listType="picture">
                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'url']}
                                        fieldKey={[fieldKey, 'url']}
                                        rules={[{ required: true, message: 'Url is required'}]}
                                    >
                                        <Input placeholder="Url" />
                                    </Form.Item>
                                    <Form.Item name={[name, 'is_full']} fieldKey={[fieldKey, 'is_full']}>
                                        <Checkbox value="is_full" style={{ lineHeight: '32px' }}>
                                            Full
                                        </Checkbox>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add() } block icon={<PlusOutlined />}>
                                    Add New
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <div className="text-right">
                    <Button type="primary" htmlType="submit">Update</Button>
                </div>
            </Form>
        </Modal>
    );
};
export default ConfigUpdate;
