import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { Spin } from 'antd';
import { faqs } from '../../endpoint';
import defaultImage from '../../assets/theme/images/default-featured-image.jpg';

const FAQsCategory = () => {
    const [loading, setLoading] = useState(true);
    const [dataFaqs, setDataFaqs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [title, setTitle] = useState("User Guide");
    const { slug } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Instructions - " + window?.title;
        faqs.getListCategoryFrontend().then(res => {
            if (res.status == 'success') {
                setCategories(res.response);
                let categoriesList = res.response;
                Object.keys(categoriesList).forEach(function(key) {
                    if (categoriesList[key].slug == slug) {
                        onChangeCategory(categoriesList[key].id);
                        document.title = categoriesList[key].name + ' - ' + window?.title;
                        setTitle(categoriesList[key].name);
                    }
                });
            }
        }).then(res => {
        });
    }, []);

    /**
     * Handle filter category
     *
     * @param categoryId
     */
    const onChangeCategory = (categoryId) =>{
        setLoading(true);
        faqs.getListFrontend(categoryId).then(res => {
            if (res.status == 'success') {
                setDataFaqs(res.response);
            }
            setLoading(false);
        }).then(res => {
        });
    }
    
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <h3 className="text-bold mt-0 mb-3"><b>{ title }</b></h3>
                    <div className='list-category'>
                        <ul>
                            <li><Link to='/faqs.html'>All</Link></li>
                            {
                                categories && categories.length > 0 && categories.map((item, key) => 
                                    <li key={item.id}>
                                        <a className={slug == item.slug ? 'active' : ''} href={'/faqs/category/' + item.slug}>{item.name}</a>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                        {
                            loading ? 
                            (<div className="text-center mt-5"><Spin /></div>) :
                            (
                                <div className="row">
                                    <div className="col-12 col-sm-12">
                                        <div className="row faqs-list">
                                            {
                                                dataFaqs && dataFaqs.length > 0 && dataFaqs.map((item, key) => 
                                                    <div key={item.id} className='col-12 col-sm-6 col-md-3'>
                                                        <div className="faq-item">
                                                            <div className='img'>
                                                                <Link to={'/faq-detail/' + item.slug}><img src={item.image ? window.base_url + item.image : defaultImage} /></Link>
                                                            </div>
                                                            <div className='faq-content'>
                                                                <Link to={'/faq-detail/' + item.slug}>{item.name}</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        } 
                </div>
            </div>
        </div>
    );
}
export default FAQsCategory;
