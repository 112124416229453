import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Modal, Spin, notification, message } from 'antd';
import { partner } from '../../endpoint';
import { getParamsByUrlPaging } from '../../_until';

const Partners = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingCategory, setLoadingCategory] = useState(true);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [data, setData] = useState([]);
    const [dataLists, setDataLists] = useState([]);
    const [categories, setCategories] = useState([]);
    const [active, setActive] = useState('all');
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());

    /**
     * Handle list partner by category
     *
     * @param categoryId
     */
    const loadList = (categoryId) => {
        const hide = message.loading('Processing...');
        setLoading(true);
        setActive(categoryId);
        const parameters = {'category_id': categoryId};
        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');
        partner.getListFrontend(parameters).then(res => {
            if (res.status == 'success') {
                setDataLists(res.response);
                history.push(`?` + queryString);
            } else if(res.status == 'fail') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(err => {
            setLoading(false);
        }).then(complete => {
            hide();
            setLoading(false);
        });
    }

    /**
     * Handle nl2br textarea
     */
    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    /**
     * Handle cancel modal
     */
    const handleCancel = () => {
        setIsModalVisibleAds(false);
    };

    /**
     * Handle show modal ads
     *
     * @param record
     */
    const handleShowModalAds = (record) => {
        setData(record);
        setIsModalVisibleAds(true);
    };
    
    /**
     * Handle ok redirect
     */
    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(data.url, '_blank', 'noopener,noreferrer');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Partners - " + window?.title;
        let type = "all";
        if (typeof paramQuery?.category_id != 'undefined') {
            type = paramQuery.category_id;
        }
        loadList(type);
        partner.getCategoryFrontend().then(res => {
            if (res.status == 'success') {
                setLoadingCategory(false);
                setCategories(res.response);
            }
        }).then(res => {
        }).then(complete => {
            setLoadingCategory(false);
        });
    }, []);

    return (
        <div className="container">
            {
                <div className="row partner_cont">
                    <div className='col-12 col-sm-4 col-md-3'>
                        <div className="panel panel-default panel-custom">
                            <div className="panel-body">
                                <ul className="partner_type">
                                    <li className={active == 'all' ? 'active' : ''}><a onClick={() => loadList('all')}><span>All</span></a></li>
                                    {
                                        loadingCategory ? (
                                            <div className="text-center mt-2 mb-2"><Spin /></div>
                                        ) : (
                                            categories && categories.length > 0 && categories.map((item, key) => 
                                                <li key={item.id} className={active == item.id ? 'active' : ''}>
                                                    <a onClick={() => loadList(item.id)}><span>{item.name}</span></a>
                                                </li>
                                            )
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-8 col-md-9'>
                        <div className="partner_all_conts">
                            {
                                loading ? (
                                    <div className="text-center mt-5 mb-5"><Spin /></div>
                                ) : (
                                    <div className="partner_all">
                                        {
                                            dataLists && dataLists.length > 0 && dataLists.map((item, key) => 
                                                <div className="part_detail" key={item.id}>
                                                    <div className="part_title">
                                                        <p className="pt_left">
                                                            <img src={window.base_url + item.logo} alt={item.name} />
                                                        </p>
                                                        <div className="pt_right">
                                                            <p>{item.name} <span className="select_down_pt"></span></p>
                                                            <div dangerouslySetInnerHTML={{__html: nl2br(item?.description)}} />
                                                            {
                                                                item?.senior_partner == 1 && (
                                                                    <div className="depth_partner">
                                                                        <p className="depth_text">
                                                                            <span>Senior</span>
                                                                            <span>Partner</span>
                                                                        </p>
                                                                    </div>
                                                                )
                                                            }
                                                            <div className="part_cont text-right">
                                                                <a className="partner_visit" onClick={() => { handleShowModalAds(item) }} >Visit Website</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                        <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="remove-margin white-space-pre-line">
                                        Dear customer,<br/><br/>
                                        If you wish to access this website please note that we are not the owners of this website.<br/><br/>
                                        Any purchase or sale between you and this website is not related to us<br/><br/>
                                        By clicking 'OK' you will be redirected to this website.<br/><br/>
                                        Please contact them before proceeding with the transaction.<br/><br/>
                                        Thank you!
                                    </p>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            }
        </div>
    );
}
export default Partners;