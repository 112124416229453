import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDigit } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { setting_percent, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

/**
 * Add node
 *
 * @param fields
 */
const handleAdd = async (fields) => {
    const hide = message.loading('Processing...');
    try {
        var res = await setting_percent.addItem({ ...fields, 'type': 'coupon'});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Added successfully',
                type: 'success'
            });
            setTimeout(function() {
                location.reload();
            }, 500);
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Adding failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Update node
 *
 * @param fields
 * @param id
 */
const handleUpdate = async (fields, id) => {
    const hide = message.loading('Processing...');
    try {
        var res = await setting_percent.updateItem({ ...fields, id: id});
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            setTimeout(function() {
                location.reload();
            }, 500);
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await setting_percent.deleteItems({
            lists: selectedRows.map((row) => row.id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Change Status
 *
 * @param status
 * @param record
 */
const handledChangeStatus = async (status, record) => {
    const hide = message.loading('Processing...');
    try {
        var res = await setting_percent.changeStatus(record.id, {'status': status ? 1 : 0 });
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Update successfully',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        notification.open({
            message: 'Fail!',
            description: 'Update failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

const SettingCouponComponent = () => {
    const history = useHistory();
    const actionRef = useRef(null);
    const [form] = Form.useForm();
    const [createModalVisible, handleModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);

        const data = await setting_percent.getList({...parameters, 'type': 'coupon'});
        return data;
    }

    /**
     * Columns show table
     */
    const columns = [
        {
            title: "Title",
            dataIndex: 'title',
            sorter: false,
            valueType: 'text',
            hideInSearch: true,
        },
        {
            title:'Month',
            dataIndex: 'total',
            hideInSearch: true,
            render: (_, record) => [
                <span key={'total' + record.id}>{parseInt(record.total)}</span>
            ]
        },
        {
            title:'Percent',
            dataIndex: 'percent',
            hideInSearch: true,
            render: (_, record) => [
                <span key={'percent' + record.id}>{record.percent}%</span>
            ]
        },
        {
            title: "Status",
            dataIndex: 'status',
            valueEnum: {
                0: {
                    text:'Deactive',
                    status: 'red',
                },
                1: {
                    text: "Active",
                    status: 'Success',
                }
            },
            render: (_, record) => [
                <Switch checkedChildren="Active" unCheckedChildren="Deactive" onChange={(value) => handledChangeStatus(value, record)} key={record.id} defaultChecked={record?.status  == 1 ? true : false} />
            ]
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true,
        },
        {
            title: "Action",
            dataIndex: 'option',
            valueType: 'option',
            hideInSearch: true,
            render: (_, record) => [
                <a key="edit"
                    onClick={() => {
                        form.resetFields();
                        setCurrentRow(record);
                        handleModalVisible(true);
                    }}
                ><EditOutlined /> Edit</a>
                ,
                <Popconfirm
                    key={record.id}
                    title="Are you sure to delete this task?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={ async () => {
                        await handleRemove([record]);
                        actionRef.current?.reloadAndRest?.();
                    }} 
                >
                    <a key="delete" className='text-danger'><DeleteOutlined />  Delete</a>
                </Popconfirm>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Setting Discount Manager"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            setCurrentRow(null);
                            handleModalVisible(true);
                            form.resetFields();
                        }}
                    >
                        <PlusOutlined /> Add New
                    </Button>
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    defaultPageSize: (paramQuery?.pageSize || 20)
                }}
            />

            {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        key="delete"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}

            {/*Form Add new and Edit*/}
            <ModalForm
                id="form-add-package"
                title="Setting Discount Form"
                width="540px"
                form={form}
                submitter={false}
                visible={createModalVisible}
                onVisibleChange={handleModalVisible}
                onFinish={async (value) => {
                    
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Title is required"
                        },
                    ]}
                    initialValue={currentRow?.title}
                    width="full"
                    name="title"
                    label="Title:"
                />

                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Month is required"
                        },
                    ]}
                    initialValue={currentRow?.total}
                    label="Month"
                    placeholder="Month"
                    name="total"
                    min={1}
                />

                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Percent is required"
                        },
                    ]}
                    placeholder="Percent"
                    initialValue={currentRow?.percent}
                    label="Percent"
                    name="percent"
                    min={0}
                />

                <div className='text-right'>
                    <Button
                        type="primary"
                        key="submitForm"
                        onClick={async () => {
                            const value = form.getFieldsValue();
                            form.submit();
                            if (value.title && value.total && value.percent != null) {
                                if (currentRow?.id) {
                                    const success = await handleUpdate({...value, 'status': currentRow.status, 'type': currentRow.type}, currentRow?.id);
                                    if (success) {
                                        form.resetFields();
                                        handleModalVisible(false);
                                    }
                                } else {
                                    const success = await handleAdd({...value, 'status': 1});
                                    if (success) {
                                        form.resetFields();
                                        handleModalVisible(false);
                                    }
                                }
                            }
                        }}
                    >
                        Save
                    </Button>
                </div>
            </ModalForm>
  
            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.name}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default SettingCouponComponent;
