import { Request } from '../_until';
import { notification } from 'antd';

export const authenticator = {
    get2fa,
    generate2faSecret,
    enable2fa,
    disable2fa,
    verify2fa,
    checkVerify2fa,
    checkVerify2faFrontend
};

const urls = {
    get2fa: `authenticator/get2fa`,
    generate2faSecret: `authenticator/generate2faSecret`,
    enable2fa: `authenticator/enable2fa`,
    disable2fa: `authenticator/disable2fa`,
    verify2fa: `authenticator/verify2fa`,
    checkVerify2fa: `authenticator/checkVerify2fa`,
    checkVerify2faFrontend: `authenticator/checkVerify2faFrontend`,
};

function get2fa() {
    return Request.post_website(urls.get2fa, {}).then(res => res.data);
};

function generate2faSecret(requestOptions) {
    return Request.post_website(urls.generate2faSecret, requestOptions).then(res => res.data);
};

function enable2fa(requestOptions) {
    return Request.post_website(urls.enable2fa, requestOptions).then(res => res.data);
};

function disable2fa(requestOptions) {
    return Request.post_website(urls.disable2fa, requestOptions).then(res => res.data);
};

function verify2fa(requestOptions) {
    return Request.post_website(urls.verify2fa, requestOptions).then(res => res.data);
};

function checkVerify2fa(requestOptions) {
    return Request.post_website(urls.checkVerify2fa, requestOptions).then(res => res.data);
};

function checkVerify2faFrontend(requestOptions) {
    return Request.post_website(urls.checkVerify2faFrontend, requestOptions).then(res => res.data);
};