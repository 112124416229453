import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tooltip, Spin, Modal, Form, notification, Button, Checkbox, Radio, Input, Select, message } from 'antd';
import { useHistory, Link } from "react-router-dom";
import { plan, config, payment, banner, account, authenticator, vps } from '../../endpoint';
import { EnvironmentOutlined, UserOutlined } from '@ant-design/icons';
import { getParamsByUrlPaging, constants } from '../../_until';
import AppContext from './../../_until/AppContext';

import perfectMoney from '../../assets/images/payment/perfect-money.png';
import webMoney from '../../assets/images/payment/webmoney.png';
import bitCoin from '../../assets/images/payment/bitcoin.png';
import bitcoinCash from '../../assets/images/payment/bitcoincash.png';
import tether from '../../assets/images/payment/tether.png';
import usdt from '../../assets/images/payment/usdt.png';
import bep20 from '../../assets/images/payment/bep20.png';
import payeer from '../../assets/images/payment/ic-payeer.png';

// import qrc_bitCoin from '../../assets/theme/images/qr-btc.png';
// import qrc_bitcoinCash from '../../assets/theme/images/qr-bitcoin-cash.png';

// import s5ABC from '../../assets/images/abc.png';
// import s5922 from '../../assets/images/922.png';
// import s5PIA from '../../assets/images/pia.png';
// import luna from '../../assets/images/luna.png';
import icBuyPoint from '../../assets/images/ic-buy-point.png';

const { TabPane } = Tabs;

const Buysock = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleAds, setIsModalVisibleAds] = useState(false);
    const [isModalVisiblePlan, setIsModalVisiblePlan] = useState(false);
    const [isModalVisibleTrial, setIsModalVisibleTrial] = useState(false);
    const [isModalVisibleTrialProxy, setIsModalVisibleTrialProxy] = useState(false);
    const [isModalVisibleTrialVps, setIsModalVisibleTrialVps] = useState(false);
    const [dataListPackageProxy, setListPackageProxy] = useState([]);
    const [dataListPackageVps, setListPackageVps] = useState([]);
    const [dataListBanner, setListBanner] = useState([]);
    const [profile, setProfile] = useState([]);
    const [dataAds, setBannerAds] = useState([]);
    const [data, setData] = useState([]);
    const [dataPayment, setDataPayment] = useState([]);
    const [note, setNote] = useState("");
    const [planChoose, setPlanChoose] = useState({});
    const [typePlan, setTypePlan] = useState("");
    const [typeProduct, setTypeProduct] = useState("");
    const [typeTrial, setTypeTrial] = useState("");
    const [packageIdTrialProxy, setPackageIdTrialProxy] = useState(null);
    const [proxyTypeTrial, setProxyTypeTrial] = useState('proxy_https');
    const [packageIdTrialVps, setPackageIdTrialVps] = useState(null);
    const [isShowTrial, setIsShowTrial] = useState(false);
    const [isShowTrialProxy, setIsShowTrialProxy] = useState(false);
    const [isShowTrialVps, setIsShowTrialVps] = useState(false);
    const [settingWebsite, setSettingWebsite] = useState({});
    const [dataOs, setDataOs] = useState([]);
    const [checkIhaveRead, setCheckIhaveRead] = useState(false);
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');
    const [form] = Form.useForm();
    const [isLoadNotification, setLoadNotification] = useContext(AppContext);

    /**
     * Handle show modal
     *
     * @param plan
     * @param type
     * @param type_Product
     */
    const showModal = (plan, type, type_Product) => {
        form.resetFields();
        var planId = null;
        var packageId = null;
        if (type_Product == 'socks5') {
            planId = plan.plan_id;
        } else {
            packageId = plan.id;
        }
        saveTracking(planId, packageId, type);

        if (window.isCryptomus && (type == constants.Bitcoin.toLowerCase() ||
            type == constants.BitcoinCash.toLowerCase() ||
            type == constants.ERC20.toLowerCase() ||
            type == constants.TRC20.toLowerCase() ||
            type == constants.BSC.toLowerCase() ||
            type == constants.Litecoin.toLowerCase()
        )) {
            getConfirm({
                plan: plan,
                type: constants.Cryptomus.toLowerCase(),
                network: type,
                type_product: type_Product,
                plan_price: plan.plan_price,
            });
        } else {
            if (type == constants.Bitcoin.toLowerCase()) {
                setDataPayment({
                    plan: plan,
                    type: type,
                    type_product: type_Product,
                    plan_price: plan.plan_price,
                    payment_name: "Bitcoin (BTC)",
                    payment_address: "14LUfvDXQBTJWSNMyagrVHjfyo1c6sLBCV",
                    image: "https://shopsocks5.com/assets/qr-btc.png"
                });
                setIsModalVisible(true);
            } else if (type == constants.BitcoinCash.toLowerCase()) {
                setDataPayment({
                    plan: plan,
                    type: type,
                    type_product: type_Product,
                    plan_price: parseFloat(plan.plan_price),
                    payment_name: "Bitcoin Cash (BCH)",
                    payment_address: "14LUfvDXQBTJWSNMyagrVHjfyo1c6sLBCV",
                    image: "https://shopsocks5.com/assets/qr-bitcoin-cash.png"
                });
                setIsModalVisible(true);
            } else if (type == constants.ERC20.toLowerCase()) {
                setDataPayment({
                    plan: plan,
                    type: type,
                    type_product: type_Product,
                    plan_price: parseFloat(plan.plan_price),
                    payment_name: "USDT (ERC20)",
                    payment_address: "0x4e9b6dc9d1717b6aed27aad520a00afadbddace2",
                    image: "https://shopsocks5.com/assets/qr-erc20.png"
                });
                setIsModalVisible(true);
            } else if (type == constants.TRC20.toLowerCase()) {
                setDataPayment({
                    plan: plan,
                    type: type,
                    type_product: type_Product,
                    plan_price: parseFloat(plan.plan_price),
                    payment_name: "USDT (TRC20)",
                    payment_address: "TNaBJknjJyyWAaa7rUcWUXzRVzxpppC5dW",
                    image: "https://shopsocks5.com/assets/qr-trc20.png"
                });
                setIsModalVisible(true);
            } else if (type == constants.BSC.toLowerCase()) {
                setDataPayment({
                    plan: plan,
                    type: type,
                    type_product: type_Product,
                    plan_price: parseFloat(plan.plan_price),
                    payment_name: "USDT BSC (BNB Smart Chain(BEP20))",
                    payment_address: "0x4e9b6dc9d1717b6aed27aad520a00afadbddace2",
                    image: "https://shopsocks5.com/assets/qr-bep20.png"
                });
                setIsModalVisible(true);
            } else {
                getConfirm({
                    plan: plan,
                    type: type,
                    type_product: type_Product,
                    plan_price: plan.plan_price,
                });
            }
        }
    }

    /**
     * Handle buy another
     */
    const handleBuyAnother = () => {
        setIsModalVisiblePlan(false);
        showModal(planChoose, typePlan, typeProduct);
    }

    /**
     * Handle show modal plan
     *
     * @param plan
     * @param typePayment
     * @param type
     */
    const showModalPlan = (plan, typePayment, type) => {
        if (user == null) {
            history.push({pathname: '/login.html'});
            return;
        }
        setTypeProduct(type);
        if (type == 'socks5') {
            if (profile?.plan_type != "None" && !(profile?.plan_type == plan.plan_type && profile?.plan_daily_limit == plan.plan_daily_limit)) {
                saveTracking(plan.plan_id, null, typePayment);
                setTypePlan(typePayment);
                setPlanChoose(plan);
                setIsModalVisiblePlan(true);
            } else {
                showModal(plan, typePayment, type);
            }
        } else {
            showModal(plan, typePayment, type);
        }
    }

    /**
     * Handle close modal
     */
    const handleCancel = () => {
        setIsModalVisible(false);
        setIsModalVisibleAds(false);
        setIsModalVisiblePlan(false);
    };

    /**
     * Handle redirect ads
     */
    const handleOk = () => {
        setIsModalVisibleAds(false);
        window.open(dataAds.url, '_blank', 'noopener,noreferrer');
    }

    /**
     * Handle show modal ads
     *
     * @param record
     */
    const handleShowModalAds = (record) => {
        setBannerAds(record);
        setIsModalVisibleAds(true);
    };

    /**
     * Handle register trial
     */
    const handleTrial = () => {
        const hide = message.loading('Processing...');
        setIsModalVisibleTrial(false);
        account.check_trial({type: typeTrial}).then(res => {
            if (res.status == 'success') {
                location.href = window.base_url + 'google-redirect/' + res.trial_id;
            } else if(res.status == 'fail') {
                notification.open({
                    message: 'Trial registration failed.',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(complete => {
            hide();
        }).then(err => {
        });
    }

    /**
     * Handle register trial proxy
     */
    const handleTrialProxy = () => {
        const hide = message.loading('Processing...');
        setIsModalVisibleTrial(false);
        account.check_trial_proxy({'id': packageIdTrialProxy, 'type': proxyTypeTrial}).then(res => {
            if (res.status == 'success') {
                location.href = window.base_url + 'proxy-google-redirect/' + res.trial_id;
            } else if (res.status == 'fail') {
                notification.open({
                    message: 'Trial registration failed.',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(complete => {
            hide();
        }).then(err => {
            hide();
        });
    }

    /**
     * Handle register trial vps
     *
     * @param values
     */
    const handleTrialVps = (values) => {
        if (!checkIhaveRead) {
            notification.open({
                message: 'Fail!',
                description: 'I have read and agree is required',
                type: 'error'
            });
            return;
        }
        const hide = message.loading('Processing...');
        setIsModalVisibleTrial(false);
        account.check_trial_vps({...values, 'id': packageIdTrialVps}).then(res => {
            if (res.status == 'success') {
                location.href = window.base_url + 'vps-google-redirect/' + res.trial_id;
            } else if (res.status == 'fail') {
                notification.open({
                    message: 'Trial registration failed.',
                    description: res.message,
                    type: 'error'
                });
            }
        }).then(complete => {
            hide();
        }).then(err => {
            hide();
        });
    }

    /**
     * Handle config payment
     *
     * @param values
     */
    const onFinish = (values) => {
        if (dataPayment?.plan) {
            const hide = message.loading('Processing...');
            setLoadingForm(true);
            let planId = "";
            if (typeProduct == 'socks5') {
                planId = dataPayment.plan.plan_id;
            } else {
                planId = dataPayment.plan.id;
            }
            payment.config_payment({...values, plan_id: planId, type: dataPayment?.type, 'type_product': typeProduct}).then(res => {
                if (res.status == 'success') {
                    if (res.new_order == true) {
                        setIsModalVisible(false);
                        history.push({pathname: '/success.html'});
                    } else {
                        setIsModalVisible(false);
                        setLoadNotification(true);

                        if (typeProduct == 'socks5') {
                            localStorage.setItem('confirm_id', res.confirm_id);
                            localStorage.setItem('duration', 60 * 15);
                            history.push({pathname: '/pendding.html'});
                        } else {
                            history.push({pathname: '/send-cdkey.html'});
                        }
                    }
                } else if (res.status == 'fail') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }).then(complete => {
                hide();
                //setLoadingForm(false);
            }).then(err => {
                hide();
                //setLoadingForm(false);
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: 'Please choose plan!',
                type: 'error'
            });
        }
    }

    /**
     * Load list Operating system
     */
    const loadListOs = () => {
        vps.getListOsFrontend().then(res => {
            if (res.status == 'success') {
                setDataOs(res.list_os);
            }
        }).then(res => {
        });
    }

    /**
     * Handle get confirm
     *
     * @param data
     */
    const getConfirm = (data) => {
        if (data) {
            const hide = message.loading('Processing...');
            let planId = "";
            if (data.type_product == 'socks5') {
                planId = data.plan.plan_id;
            } else {
                planId = data.plan.id;
            }
            payment.get_confirm({plan_id: planId, 'type': data.type, 'type_product': data.type_product, 'network': data?.network}).then(res => {
                if (res.status == 'success') {
                    const response = res.response;
                    if (response.plan_price == data.plan.plan_price) {
                        var submit = document.createElement('form');
                        submit.method = "POST";
                        var is_submit = false;
                        if (data.type == constants.PerfectMoney.toLowerCase()) {
                            is_submit = false;
                            $('#div_element').html($.base64.atob(res.code, true));
                        } else if (data.type == constants.WebMoney.toLowerCase()) {
                            is_submit = false;
                            $('#div_element').html($.base64.atob(res.code, true));
                        } else if (data.type == constants.Payeer.toLowerCase()) {
                            is_submit = false;
                            location.href = res.data_payment.payeer_url;
                        } else if (data.type == constants.Cryptomus.toLowerCase()) {
                            is_submit = false;
                            location.href = '/crypto-payment/' + res.code;
                        }
                        if (is_submit) {
                            // document.body.appendChild(submit);
                            // submit.submit();
                        }
                    }
                } else if (res.status == 'fail') {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }).then(complete => {
                hide();
            }).then(err => {
            });
        } else {
            notification.open({
                message: 'Fail!',
                description: 'Please choose plan!',
                type: 'error'
            });
        }
    }

    /**
     * Handle save tracking
     *
     * @param planId
     * @param packageId
     * @param typePayment
     */
    const saveTracking = (planId, packageId, typePayment) => {
        if (typePayment == constants.PerfectMoney.toLowerCase()) {
            typePayment = 'pm';
        } else if (typePayment == constants.WebMoney.toLowerCase()) {
            typePayment = 'wmz';
        }
        payment.tracking_confirm({'plan_id': planId, 'package_id': packageId, 'type': typePayment}).then(res => {
        }).then(complete => {
        }).then(err => {
        });
    }

    /**
     * Handle nl2br textarea
     */
    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Buy Socks5 - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    account.profile().then(res => {
                        if (res.status == 'success') {
                            setProfile(res.response);
                        }
                    });
        
                    account.note().then(res => {
                        if (res.status == 'success' && res.response) {
                            setNote(nl2br(res.response.config_description));
                        }
                    });

                    account.check_data_trial(user.user_id).then(res => {
                        if (res.status == 'success') {
                            if (res.is_sock == 1) {
                                setIsShowTrial(true);
                            }
                            if (res.is_proxy == 1) {
                                setIsShowTrialProxy(true);
                            }
                            if (res.is_vps == 1) {
                                setIsShowTrialVps(true);
                            }
                        }
                    });
                }
            }).then(res => {
            });
        }

        plan.getListPlan().then(response => {
            if (response.status == 'success') {
                setData(response.response);
                setLoading(false);
            }
        });

        plan.getListPackageProxy().then(res => {
            if (res.status == 'success') {
                setListPackageProxy(res.response.proxy);
            }
        });

        plan.getListPackageVps({country: 'us'}).then(res => {
            if (res.status == 'success') {
                setListPackageVps(res.response.vps);
            }
        });

        banner.get_list_banner({position: 'buy_sock'}).then(res => {
            if (res.status == 'success') {
                setListBanner(res.response);
            }
        });

        config.getSettingWebsite().then(res => {
            if (res.status == 'success') {
                setSettingWebsite(res.response);
            }
        });

        loadListOs();
    }, []);

    /**
     * Handle onchange load package vps by country
     *
     * @param value
     */
    const changePackageCountryVps = (value) => {
        const hide = message.loading('Processing...');
        plan.getListPackageVps({country: value}).then(res => {
            if (res.status == 'success') {
                setListPackageVps(res.response.vps);
            }
        }).then(complete => {
            hide();
        }).then(err => {
            hide();
        });
    }

    /**
     * Show payment list
     *
     * @param record
     * @param type
     */
    function paymentList(record, type) {
        return (
            <ul className="list-unstyled remove-margin list-payment">
                <li className={window.confirm.is_payment_pm != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="Perfect Money">
                        <a className="payment" onClick={() => showModalPlan(record, constants.PerfectMoney.toLowerCase(), type)}><img src={perfectMoney} /> </a>
                    </Tooltip>
                </li>
                <li className={window.confirm.is_payment_payeer != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="Payeer">
                        <a className="payment" onClick={() => showModalPlan(record, constants.Payeer.toLowerCase(), type)}><img src={payeer} /> </a>
                    </Tooltip>
                </li>
                <li className={(window.confirm.is_payment_wmz != "1" || record.plan_price > 190) ? 'd-none' : ''}>
                    <Tooltip placement="top" title="WebMoney">
                        <a className="payment" onClick={() => showModalPlan(record, constants.WebMoney.toLowerCase(), type)}><img src={webMoney} /></a>
                    </Tooltip>
                </li>
                <li className={window.confirm.is_payment_btc != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="Bitcoin">
                        <a className="payment" onClick={() => showModalPlan(record, constants.Bitcoin.toLowerCase(), type)}><img src={bitCoin} /></a>
                    </Tooltip>
                </li>
                <li className={window.confirm.is_payment_bch != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="Bitcoin Cash">
                        <a className="payment" onClick={() => showModalPlan(record, constants.BitcoinCash.toLowerCase(), type)}><img src={bitcoinCash} /></a>
                    </Tooltip>
                </li>
                <li className={window.confirm.is_payment_erc20 != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="USDT (ERC20)">
                        <a className="payment erc20" onClick={() => showModalPlan(record, constants.ERC20.toLowerCase(), type)}><img src={tether} /></a>
                    </Tooltip>
                </li>
                <li className={window.confirm.is_payment_trc20 != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="USDT (TRC20)">
                        <a className="payment trc20" onClick={() => showModalPlan(record, constants.TRC20.toLowerCase(), type)}><img src={usdt} /></a>
                    </Tooltip>
                </li>
                <li className={window.confirm.is_payment_bep20 != "1" ? 'd-none' : ''}>
                    <Tooltip placement="top" title="USDT BSC (BNB Smart Chain(BEP20)) ">
                        <a className="payment trc20" onClick={() => showModalPlan(record, constants.BSC.toLowerCase(), type)}><img src={bep20} /></a>
                    </Tooltip>
                </li>
            </ul>
        );
    }

    /**
     * Show button trial
     *
     * @param type
     */
    function getButtonTrial(type) {
        return (
            <a className="btn-continue-google" onClick={() => {
                if (user != null) {
                    setTypeTrial(type);
                    setIsModalVisibleTrial(true);
                } else {
                    history.push({pathname: '/login.html'});
                    return;
                }
            }}>Continue with Google</a>
        )
    }

    /**
     * Show button trial proxy
     *
     * @param packageId
     */
    function getButtonTrialProxy(packageId) {
        return (
            isShowTrialProxy && (
                <a className="btn-continue-google" style={{minWidth: "150px"}} onClick={() => {
                    if (user != null) {
                        setPackageIdTrialProxy(packageId);
                        setIsModalVisibleTrialProxy(true);
                    } else {
                        history.push({pathname: '/login.html'});
                        return;
                    }
                }}>Free Trial</a>
            )
        )
    }

    /**
     * Show button trial vps
     *
     * @param packageId
     */
    function getButtonTrialVps(packageId) {
        return (
            isShowTrialVps && (
                <a className="btn-continue-google" style={{minWidth: "150px"}} onClick={() => {
                    if (user != null) {
                        setPackageIdTrialVps(packageId);
                        setIsModalVisibleTrialVps(true);
                    } else {
                        history.push({pathname: '/login.html'});
                        return;
                    }
                }}>Free Trial</a>
            )
        )
    }

    /**
     * Handle check block buy proxy
     *
     * @param packageId
     */
    function checkBlockBuyProxy(packageId) {
        if (profile?.is_buy_proxy == 0) {
            notification.open({
                message: 'Fail!',
                description: "Your account is temporarily locked for 3 days due to violating the rules.",
                type: 'error'
            });
            return ;
        } else if (profile?.is_buy_proxy == 2) {
            notification.open({
                message: 'Fail!',
                description: "Your account has been permanently locked due to repeated violations of the rules.",
                type: 'error'
            });
            return ;
        }
        location.href = `/buy-proxy/` + packageId;
        return;
    }

    /**
     * Handle check block buy vps
     *
     * @param packageId
     */
    function checkBlockBuyVps(packageId) {
        if (profile?.is_buy_vps == 0) {
            notification.open({
                message: 'Fail!',
                description: "Your account is temporarily locked for 3 days due to violating the rules.",
                type: 'error'
            });
            return ;
        } else if (profile?.is_buy_vps == 2) {
            notification.open({
                message: 'Fail!',
                description: "Your account has been permanently locked due to repeated violations of the rules.",
                type: 'error'
            });
            return ;
        }
        location.href = `/buy-vps/` + packageId;
        return;
    }

    /**
     * Show list banner
     */
    const dataListBannerResult = dataListBanner.map((item, key) => {
        return (
            item.name != 'shopsocks5.com' ? (
                <div className={"col-12 col-sm-" + (item.is_full == 1 ? '6' : '6')} key={item.id}>
                    <a onClick={()=> handleShowModalAds(item)} className='d-block text-center mb-2'>
                        <img src={item.path} style={{maxWidth: "100%", marginBottom: "5px"}} />
                    </a>
                </div>
            ) : ''
        )
    })

    return (
        <div className="container">
            <div className="servicer">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            {dataListBannerResult}
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="content">
                        <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
                            <div className={"tab-buy-socks nav-pills" + (loading ? " d-none" : "")}>
                                <Tabs defaultActiveKey={paramQuery?.tab ?? 'socks5_premium'}>
                                    <TabPane tab="ISP SOCKS5" key="socks5_premium">
                                        <div title="PLAN ISP SOCKS5" className="plan panel panel-default">
                                            <h3 className="panel-heading mb-2">PLAN ISP SOCKS5</h3>
                                            <div className="panel-body">
                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_premium }}></div>
                                            </div>
                                        </div>
                                        {
                                            note != '' ? (
                                                <div className="plan panel panel-default">
                                                    <div className="panel-body">
                                                        <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                            <thead>
                                                                <tr>
                                                                    <th>Plan</th>
                                                                    <th>Daily Expired</th>
                                                                    <th>Price($)</th>
                                                                    <th>Credit Expired</th>
                                                                    <th>Total Socks5 Receive</th>
                                                                    <th>Payment</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data?.Premium && data?.Premium.length > 0 && data?.Premium.map((item, key) => 
                                                                        <tr key={item.plan_id}>
                                                                            <td data-title="Plan">{item.plan_name}</td>
                                                                            <td data-title="Daily Expired">{item.plan_expired == 0 ? window.confirm.number_day + ' Days' : item.plan_expired + ' Days'}</td>
                                                                            <td data-title="Price($)">{item.plan_price} $</td>
                                                                            <td data-title="Credit Expired">{"Until Credit = 0"}</td>
                                                                            <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                            <td data-title="Payment" className="no-padding">{ paymentList(item, 'socks5') }</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="SOCKS5 DAILY" key="socks5_daily">
                                        <div title="PLAN SOCKS5 DAILY" className="plan panel panel-default mt-3">
                                            <h3 className="panel-heading mb-2">PLAN SOCKS5 DAILY</h3>
                                            <div className="panel-body">
                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_daily }}></div>
                                            </div>
                                        </div>
                                        {
                                            note != '' ? (
                                                <div className="plan panel panel-default">
                                                    <div className="panel-body">
                                                        <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Tabs className="tab-day" defaultActiveKey="30day">
                                                        <TabPane tab="15 Days" key="15day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            isShowTrial && user != null ? (
                                                                                <tr>
                                                                                    <td data-title="Plan">DAILY 5 SOCKS5 <br/>1 DAY</td>
                                                                                    <td data-title="Daily Limit">5</td>
                                                                                    <td data-title="Price($)">0$</td>
                                                                                    <td data-title="Expired">1 Day</td>
                                                                                    <td data-title="Total Socks5 Receive">5</td>
                                                                                    <td className="no-padding">{ getButtonTrial('Daily') }</td>
                                                                                </tr>
                                                                            ) : ""
                                                                        }
                                                                        {
                                                                            data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                                item.plan_expired == 15 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item, 'socks5') }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="30 Days" key="30day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            isShowTrial && user != null ? (
                                                                                <tr>
                                                                                    <td data-title="Plan">DAILY 5 SOCKS5 <br/>1 DAY</td>
                                                                                    <td data-title="Daily Limit">5</td>
                                                                                    <td data-title="Price($)">0$</td>
                                                                                    <td data-title="Expired">1 Day</td>
                                                                                    <td data-title="Total Socks5 Receive">5</td>
                                                                                    <td className="no-padding">{ getButtonTrial('Daily') }</td>
                                                                                </tr>
                                                                            ) : ""
                                                                        }
                                                                        {
                                                                            data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                                item.plan_expired == 30 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item, 'socks5') }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="365 Days" key="365day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            isShowTrial && user != null ? (
                                                                                <tr>
                                                                                    <td data-title="Plan">DAILY 5 SOCKS5 <br/>1 DAY</td>
                                                                                    <td data-title="Daily Limit">5</td>
                                                                                    <td data-title="Price($)">0$</td>
                                                                                    <td data-title="Expired">1 Day</td>
                                                                                    <td data-title="Total Socks5 Receive">5</td>
                                                                                    <td className="no-padding">{ getButtonTrial('Daily') }</td>
                                                                                </tr>
                                                                            ) : ""
                                                                        }
                                                                        {
                                                                            data?.Daily && data?.Daily.length > 0 && data?.Daily.map((item, key) => 
                                                                                item.plan_expired == 365 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item, 'socks5') }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="PLAN SOCKS5 LIST" key="socks5_list">
                                        <div title="PLAN SOCKS5 LIST" className="plan panel panel-default mt-3">
                                            <h3 className="panel-heading mb-2">PLAN SOCKS5 LIST</h3>
                                            <div className="panel-body">
                                                <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_list }}></div>
                                            </div>
                                        </div>
                                        {
                                            note != '' ? (
                                                <div className="plan panel panel-default">
                                                    <div className="panel-body">
                                                        <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                        <div className="plan-table remove-padding tab-children">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Tabs className="tab-day" defaultActiveKey="list-30day">
                                                        <TabPane tab="15 Days" key="list-15day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            isShowTrial && user != null ? (
                                                                                <tr>
                                                                                    <td data-title="Plan">LIST 5 SOCKS5 <br/>1 DAY</td>
                                                                                    <td data-title="Daily Limit">5</td>
                                                                                    <td data-title="Price($)">0$</td>
                                                                                    <td data-title="Expired">1 Day</td>
                                                                                    <td data-title="Total Socks5 Receive">5</td>
                                                                                    <td className="no-padding">{ getButtonTrial('List') }</td>
                                                                                </tr>
                                                                            ) : ""
                                                                        }
                                                                        {
                                                                            data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                                item.plan_expired == 15 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item, 'socks5') }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="30 Days" key="list-30day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            isShowTrial && user != null ? (
                                                                                <tr>
                                                                                    <td data-title="Plan">LIST 5 SOCKS5 <br/>1 DAY</td>
                                                                                    <td data-title="Daily Limit">5</td>
                                                                                    <td data-title="Price($)">0$</td>
                                                                                    <td data-title="Expired">1 Day</td>
                                                                                    <td data-title="Total Socks5 Receive">5</td>
                                                                                    <td className="no-padding">{ getButtonTrial('List') }</td>
                                                                                </tr>
                                                                            ) : ""
                                                                        }
                                                                        {
                                                                            data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                                item.plan_expired == 30 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item, 'socks5') }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tab="365 Days" key="list-365day">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan</th>
                                                                            <th>Daily Limit</th>
                                                                            <th>Price($)</th>
                                                                            <th>Expired</th>
                                                                            <th>Total Socks5 Receive</th>
                                                                            <th>Payment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            isShowTrial && user != null ? (
                                                                                <tr>
                                                                                    <td data-title="Plan">LIST 10 SOCKS5 <br/>1 DAY</td>
                                                                                    <td data-title="Daily Limit">10</td>
                                                                                    <td data-title="Price($)">0$</td>
                                                                                    <td data-title="Expired">1 Day</td>
                                                                                    <td data-title="Total Socks5 Receive">10</td>
                                                                                    <td className="no-padding">{ getButtonTrial('List') }</td>
                                                                                </tr>
                                                                            ) : ""
                                                                        }
                                                                        {
                                                                            data?.List && data?.List.length > 0 && data?.List.map((item, key) => 
                                                                                item.plan_expired == 365 && (<tr key={item.plan_id}>
                                                                                    <td data-title="Plan">{item.plan_name}</td>
                                                                                    <td data-title="Daily Limit">{item.plan_daily_limit != 0 ? item.plan_daily_limit : 'Unlimited'}</td>
                                                                                    <td data-title="Price($)">{item.plan_price} $</td>
                                                                                    <td data-title="Expired">{item.plan_expired == 0 ? 'Unlimited' : item.plan_expired + ' Days'}</td>
                                                                                    <td data-title="Total Socks5 Receive">{item?.total_receive}</td>
                                                                                    <td data-title="Payment" className="no-padding">{ paymentList(item, 'socks5') }</td>
                                                                                </tr>)
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPane>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Static Datacenter Proxies" key="static_proxies">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
                                                <div className={"" + (loading ? " d-none" : "")}>
                                                    <div className="plan panel panel-default">
                                                        <h3 className="panel-heading mb-2">Static Datacenter Proxies</h3>
                                                        <div className="panel-body">
                                                            <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_package_proxy }}></div>
                                                        </div>
                                                    </div>
                                                    {
                                                        note != '' ? (
                                                            <div className="plan panel panel-default">
                                                                <div className="panel-body">
                                                                    <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                </div>
                                                            </div>
                                                        ) : ''
                                                    }
                                                    <div className='list-package plan-table pl-0 pr-0'>
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Country</th>
                                                                        <th>Price</th>
                                                                        <th>Daily Expired</th>
                                                                        <th>Description</th>
                                                                        <th>Buy Now</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        dataListPackageProxy && dataListPackageProxy.length > 0 && dataListPackageProxy.map((item, key) => 
                                                                            <tr key={item.id}>
                                                                                <td data-title="Country">
                                                                                    {
                                                                                        item?.icon ? (<img src={window.base_url + item.icon} style={{width: "30px", height: "auto", marginRight: "10px"}} />) : ''
                                                                                    }
                                                                                    <p className='mb-0 mt-1'>{item?.plan_name}</p>
                                                                                </td>
                                                                                <td data-title="Price">{item.plan_price} Point</td>
                                                                                <td data-title="Daily Expired">30 days</td>
                                                                                <td data-title="Description"><p className='mb-0 text-left' dangerouslySetInnerHTML={{ __html: nl2br(item?.description) }}></p></td>
                                                                                <td data-title="Buy Now" className="no-padding">
                                                                                    <Link to='/buy-point.html' className='btn btn-primary mb-3'><img src={icBuyPoint} width="24px" /> Buy Point Now</Link><br/>
                                                                                    <a onClick={() => {checkBlockBuyProxy(item.id)}} className='btn btn-primary mb-3'><i className="fa fa-shopping-cart pr-2"></i> Buy Proxy Now</a><br/>
                                                                                    { item.is_trial == 1 ? getButtonTrialProxy(item.id) : '' }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Cloud VPS" key="cloud_vps">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
                                                <div className={"" + (loading ? " d-none" : "")}>
                                                    <div className="plan panel panel-default">
                                                        <h3 className="panel-heading mb-2">Cloud VPS</h3>
                                                        <div className="panel-body">
                                                            <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_package_vps }}></div>
                                                        </div>
                                                    </div>
                                                    {
                                                        note != '' ? (
                                                            <div className="plan panel panel-default">
                                                                <div className="panel-body">
                                                                    <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                </div>
                                                            </div>
                                                        ) : ''
                                                    }
                                                    <div className='list-package plan-table pl-0 pr-0'>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-md-4">
                                                                <Form.Item name="country">
                                                                    <Select style={{width: "100%"}} defaultValue={'US'} placeholder="Country" onChange={changePackageCountryVps}>
                                                                        <Option value="US">Cloud VPS US (US)</Option>
                                                                        <Option value="SG">Cloud VPS Singapore (SG)</Option>
                                                                        <Option value="AU">Cloud VPS Australia (AU)</Option>
                                                                        <Option value="CA">Cloud VPS Canada (CA)</Option>
                                                                        <Option value="UK">Cloud VPS UK (UK)</Option>
                                                                        <Option value="DE">Cloud VPS Germany (DE)</Option>
                                                                        <Option value="FR">Cloud VPS France (FR)</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Country</th>
                                                                        <th>Price</th>
                                                                        <th>Daily Expired</th>
                                                                        <th>Description</th>
                                                                        <th>Buy Now</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        dataListPackageVps && dataListPackageVps.length > 0 && dataListPackageVps.map((item, key) => 
                                                                            <tr key={item.id}>
                                                                                <td data-title="Country">
                                                                                    {
                                                                                        item?.icon ? (<img src={window.base_url + item.icon} style={{width: "30px", height: "auto", marginRight: "10px"}} />) : ''
                                                                                    }
                                                                                    <p className='mb-0 mt-1'>{item?.plan_name}</p>
                                                                                </td>
                                                                                <td data-title="Price">{item.plan_price} Point</td>
                                                                                <td data-title="Daily Expired">30 days</td>
                                                                                <td data-title="Description"><p className='mb-0 text-left' dangerouslySetInnerHTML={{ __html: nl2br(item?.description) }}></p></td>
                                                                                <td data-title="Buy Now" className="no-padding">
                                                                                    <Link to='/buy-point.html' className='btn btn-primary mb-3'><img src={icBuyPoint} width="24px" /> Buy Point Now</Link><br/>
                                                                                    <a onClick={() => { checkBlockBuyVps(item.id) }} className='btn btn-primary mb-3'><i className="fa fa-shopping-cart pr-2"></i> Buy VPS Now</a><br/>
                                                                                    { item.is_trial == 1 ? getButtonTrialVps(item.id) : '' }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    {/* <TabPane tab="Reseller S5Proxy" key="s5_proxy">
                                        <Tabs className='tab-children'>
                                            <TabPane tab="ISP Proxies" key="isp-proxies">
                                                <div className="plan-table remove-padding tab-children">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Tabs className="tab-reseller-s5proxy" defaultActiveKey="922">
                                                                <TabPane tab="922 S5Proxy" key="922">
                                                                    <div title="922 S5Proxy" className="plan panel panel-default">
                                                                        <h3 className="panel-heading mb-2">922 S5Proxy</h3>
                                                                        <div className="panel-body">
                                                                            <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_922 }}></div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        note != '' ? (
                                                                            <div className="plan panel panel-default">
                                                                                <div className="panel-body">
                                                                                    <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''
                                                                    }
                                                                    <div className="plan-table remove-padding tab-children">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="table-responsive">
                                                                                    <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th></th>
                                                                                                <th>Plan</th>
                                                                                                <th>Credit</th>
                                                                                                <th>Price($)</th>
                                                                                                <th>Description</th>
                                                                                                <th>Payment</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                data?.s5922 && data?.s5922.length > 0 && data?.s5922.map((item, key) => 
                                                                                                    <tr key={item.id}>
                                                                                                        <td className="no-padding"><img src={s5922} width="135"/></td>
                                                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                                                        <td data-title="Credit">{item.credit}</td>
                                                                                                        <td data-title="Price($)">{item.plan_price.toFixed(2)} $</td>
                                                                                                        <td data-title="Description">{item.description}</td>
                                                                                                        <td data-title="Payment" className="no-padding">{ paymentList(item, 's5') }</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tab="PIA S5Proxy" key="pia">
                                                                    <div title="PIA S5Proxy" className="plan panel panel-default">
                                                                        <h3 className="panel-heading mb-2">PIA S5Proxy</h3>
                                                                        <div className="panel-body">
                                                                            <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_pia }}></div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        note != '' ? (
                                                                            <div className="plan panel panel-default">
                                                                                <div className="panel-body">
                                                                                    <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''
                                                                    }
                                                                    <div className="plan-table remove-padding tab-children">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="table-responsive">
                                                                                    <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th></th>
                                                                                                <th>Plan</th>
                                                                                                <th>Credit</th>
                                                                                                <th>Price($)</th>
                                                                                                <th>Description</th>
                                                                                                <th>Payment</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                data?.s5pia && data?.s5pia.length > 0 && data?.s5pia.map((item, key) => 
                                                                                                    <tr key={item.id}>
                                                                                                        <td className="no-padding"><img src={s5PIA} width="145"/></td>
                                                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                                                        <td data-title="Credit">{item.credit}</td>
                                                                                                        <td data-title="Price($)">{item.plan_price.toFixed(2)} $</td>
                                                                                                        <td data-title="Description">{item.description}</td>
                                                                                                        <td data-title="Payment" className="no-padding">{ paymentList(item, 's5') }</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tab="ABC S5Proxy" key="abc">
                                                                    <div title="ABC S5Proxy" className="plan panel panel-default">
                                                                        <h3 className="panel-heading mb-2">ABC S5Proxy</h3>
                                                                        <div className="panel-body">
                                                                            <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_abc }}></div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        note != '' ? (
                                                                            <div className="plan panel panel-default">
                                                                                <div className="panel-body">
                                                                                    <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''
                                                                    }
                                                                    <div className="plan-table remove-padding tab-children">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="table-responsive">
                                                                                    <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th></th>
                                                                                                <th>Plan</th>
                                                                                                <th>Credit</th>
                                                                                                <th>Price($)</th>
                                                                                                <th>Description</th>
                                                                                                <th>Payment</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                data?.s5abc && data?.s5abc.length > 0 && data?.s5abc.map((item, key) => 
                                                                                                    <tr key={item.id}>
                                                                                                        <td className="no-padding"><img src={s5ABC} width="135"/></td>
                                                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                                                        <td data-title="Credit">{item.credit}</td>
                                                                                                        <td data-title="Price($)">{item.plan_price.toFixed(2)} $</td>
                                                                                                        <td data-title="Description">{item.description}</td>
                                                                                                        <td data-title="Payment" className="no-padding">{ paymentList(item, 's5') }</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </TabPane>
                                                            </Tabs>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="Traffic Plans" key="traffic-plans">
                                                <div className="plan-table remove-padding tab-children">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Tabs>
                                                                <TabPane tab="922 S5Proxy" key="922-traffic-s5proxy">
                                                                    <div title="922 S5Proxy" className="plan panel panel-default">
                                                                        <h3 className="panel-heading mb-2">922 S5Proxy</h3>
                                                                        <div className="panel-body">
                                                                            <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_traffic_922 }}></div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        note != '' ? (
                                                                            <div className="plan panel panel-default">
                                                                                <div className="panel-body">
                                                                                    <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''
                                                                    }
                                                                    <div className="plan-table remove-padding tab-children">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="table-responsive">
                                                                                    <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th></th>
                                                                                                <th>Plan</th>
                                                                                                <th>Credit</th>
                                                                                                <th>Price($)</th>
                                                                                                <th>Description</th>
                                                                                                <th>Payment</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                data?.s5922Traffic && data?.s5922Traffic.length > 0 && data?.s5922Traffic.map((item, key) => 
                                                                                                    <tr key={item.id}>
                                                                                                        <td className="no-padding"><img src={s5922} width="135"/></td>
                                                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                                                        <td data-title="Credit">{item.credit}</td>
                                                                                                        <td data-title="Price($)">{item.plan_price.toFixed(2)} $</td>
                                                                                                        <td data-title="Description">{item.description}</td>
                                                                                                        <td data-title="Payment" className="no-padding">{ paymentList(item, 's5') }</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane tab="Luna Proxy" key="pia">
                                                                    <div title="Luna Proxy" className="plan panel panel-default">
                                                                        <h3 className="panel-heading mb-2">Luna Proxy</h3>
                                                                        <div className="panel-body">
                                                                            <div className="remove-margin" dangerouslySetInnerHTML={{ __html: settingWebsite?.description_luna }}></div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        note != '' ? (
                                                                            <div className="plan panel panel-default">
                                                                                <div className="panel-body">
                                                                                    <p className="remove-margin" dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''
                                                                    }
                                                                    <div className="plan-table remove-padding tab-children">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="table-responsive">
                                                                                    <table className="table table-bordered remove-margin table-buy-sock table-list-mobile">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th></th>
                                                                                                <th>Plan</th>
                                                                                                <th>Credit</th>
                                                                                                <th>Price($)</th>
                                                                                                <th>Description</th>
                                                                                                <th>Payment</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                data?.s5luna && data?.s5luna.length > 0 && data?.s5luna.map((item, key) => 
                                                                                                    <tr key={item.id}>
                                                                                                        <td className="no-padding"><img src={luna} width="145"/></td>
                                                                                                        <td data-title="Plan">{item.plan_name}</td>
                                                                                                        <td data-title="Credit">{item.credit}</td>
                                                                                                        <td data-title="Price($)">{item.plan_price.toFixed(2)} $</td>
                                                                                                        <td data-title="Description">{item.description}</td>
                                                                                                        <td data-title="Payment" className="no-padding">{ paymentList(item, 's5') }</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </TabPane>
                                                            </Tabs>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </TabPane> */}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="div_element" className="d-none"></div>
            <Modal title="Make Payment" visible={isModalVisible} onCancel={handleCancel} footer={null} width={760}>
                <h6>You buy: <strong style={{color: "red"}}>{dataPayment?.plan?.plan_name} </strong> 
                {
                    typeProduct == 'socks5' ? (
                        dataPayment?.plan?.plan_expired == 0 ? ' | Expire: ' + window.confirm.number_day + ' Days' : ' | Expire: ' + dataPayment?.plan?.plan_expired + ' Days '
                    ) : ""
                }
                | Price: {dataPayment?.plan?.plan_price}$</h6>
                <hr />
                <div className="row">
                    <div className="col-md-6">
                        <p><strong>Step 1: Send payment to</strong></p>
                        <p>Login into your blockchain and Please send <strong style={{color: "red"}}>${dataPayment?.plan_price}</strong> without payment fee (your blockchain)</p>
                        <p>
                            {dataPayment?.payment_name} Address:<br/>
                            <b>{dataPayment?.payment_address}</b><br/><br/>
                            {dataPayment?.payment_name} Address QR CODE:<br/>
                            <img src={dataPayment?.image} width="180px" />
                        </p>
                    </div>
                    <div className="col-md-6">
                        <p><strong>Step 2: Confirm payment</strong></p>
                        <p>After payment is done, please paste your billing address in the box below and press confirm and wait some minutes to complete!</p>
                        <p className="mb-0"><b>Paste Your TX (Hash) payment:</b></p>
                        <Form
                            form={form}
                            onFinish={onFinish}
                            className="form-horizontal mt-3 pd-2"
                        >
                            <div className="row">
                                <div className="col-sm-12">
                                    <Form.Item
                                        label=""
                                        labelCol={{span: 0}}
                                        name="address"
                                        rules={[{ required: true, message: 'Please input your TX (Hash) payment' }]}
                                    >
                                        <Input prefix={<EnvironmentOutlined className="site-form-item-icon" />} placeholder="Paste Your TX (Hash) payment!" />
                                    </Form.Item>
                                    {
                                        typeProduct == 's5' ? (
                                            <Form.Item
                                                label=""
                                                labelCol={{span: 0}}
                                                name="email"
                                                rules={[{ required: true, type: "email", message: 'Please input your email!' }]}
                                            >
                                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email receive Cdkey" />
                                            </Form.Item>
                                        ) : ""
                                    }
                                </div>
                                <div className="col-sm-12">
                                    <Button type="primary" htmlType="submit" loading={loadingForm} className="d-block medium">Click confirm</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                { dataPayment?.type == constants.Bitcoin.toLowerCase() && <p className="mt-3">How to buy: <Link to="/faq-detail/how-to-buy-socks5-via-bitcoin-wallet">https://shopsocks5.com/faq-detail/how-to-buy-socks5-via-bitcoin-wallet</Link></p>}
                { dataPayment?.type == constants.BitcoinCash.toLowerCase() && <p className="mt-3">How to buy: <Link to="/faq-detail/how-to-buy-socks5-via-bitcoincash-wallet">https://shopsocks5.com/faq-detail/how-to-buy-socks5-via-bitcoincash-wallet</Link></p>}
                { dataPayment?.type == constants.ERC20.toLowerCase() && <p className="mt-3">How to buy: <Link to="/faq-detail/how-to-buy-socks5-via-usdterc20-wallet">https://shopsocks5.com/faq-detail/how-to-buy-socks5-via-usdterc20-wallet</Link></p>}
                { dataPayment?.type == constants.TRC20.toLowerCase() && <p className="mt-3">How to buy: <Link to="/faq-detail/how-to-buy-socks5-via-usdttrc20-wallet">https://shopsocks5.com/faq-detail/how-to-buy-socks5-via-usdttrc20-wallet</Link></p>}
                { dataPayment?.type == constants.BSC.toLowerCase() && <p className="mt-3">How to buy: <Link to="/faq-detail/how-to-buy-socks5-via-usdtbsc-wallet">https://shopsocks5.com/faq-detail/how-to-buy-socks5-via-usdtbsc-wallet</Link></p>}
                <hr />
                <p className="text-center">
                    <b>After payment your account not working<br/> 
                    Please Contact Skype: {window.confirm?.skype} <br/> 
                    Thank you buy socks at Shopsocks5.com</b>
                </p>
            </Modal>
            <Modal visible={isModalVisibleAds} onCancel={handleCancel} onOk={handleOk} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className="remove-margin white-space-pre-line" dangerouslySetInnerHTML={{ __html: dataAds.description }}></p>
                    </div>
                </div>
            </Modal>
            <Modal visible={isModalVisiblePlan} footer={null} closable={false} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <div className='alert alert-warning'>WARNING!</div>
                        <p>You're current using plan ({profile?.plan_current_name}), so you only renew plan ({planChoose?.plan_name}), can't upgrade new plan.</p>
                        <p>If you buy another plan , Old plan will be canceled please waiting for the current plan to expire.</p>
                        <p>If you are sure you want to buy another package, please click buy another package</p>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <Button type="primary" onClick={handleBuyAnother} className="d-block medium">Buy another package</Button>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <Button type="danger" onClick={handleCancel} className="d-block medium">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal visible={isModalVisibleTrial} footer={null} onCancel={() => {setIsModalVisibleTrial(false)}} width={480}>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p className='mb-1'>We offer a package free trial.</p>
                        <p className='mb-1'>You plz connect account google.</p>
                        <p className='mb-1'>My site will activate your account package trial</p>
                        <p className='mb-1 text-danger'>We only support personal emails. @gmail email address</p>
                        <p>Thank you very much</p>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <Button type="primary" onClick={handleTrial} className="d-block medium">Continue</Button>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <Button type="danger" onClick={() => {setIsModalVisibleTrial(false)}} className="d-block medium">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal visible={isModalVisibleTrialProxy} footer={null} onCancel={() => {setIsModalVisibleTrialProxy(false)}} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className='mb-1 text-center'>We offer a package free trial. (<span className='text-danger'>Time trial: 60 minutes</span>)</p>
                        <p className='mb-1 text-center'>You plz connect account google.</p>
                        <p className='mb-1 text-center'>My site will activate your account package trial</p>
                        <p className='mb-1 text-center text-danger'>We only support personal emails. @gmail email address</p>
                        <p className='text-center'>Thank you very much</p>
                        <Form.Item
                            label="Proxy Type:"
                            labelCol={{span: 24}}
                            name="type"
                        >
                            <Select style={{width: "100%"}} defaultValue="proxy_https" onChange={(val) => { setProxyTypeTrial(val) }}>
                                <Option value="proxy_sock_5">SOCKS5 Proxy</Option>
                                <Option value="proxy_https">HTTPS Proxy</Option>
                            </Select>
                        </Form.Item>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <Button type="primary" onClick={handleTrialProxy} className="d-block medium">Continue</Button>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <Button type="danger" onClick={() => {setIsModalVisibleTrialProxy(false)}} className="d-block medium">Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal visible={isModalVisibleTrialVps} footer={null} onCancel={() => {setIsModalVisibleTrialVps(false)}} width={768}>
                <div className="row">
                    <div className="col-md-12">
                        <p className='mb-1 text-center'>We offer a package free trial. (<span className='text-danger'>Time trial: 60 minutes</span>)</p>
                        <p className='mb-1 text-center'>You plz connect account google.</p>
                        <p className='mb-1 text-center'>My site will activate your account package trial</p>
                        <p className='mb-1 text-center text-danger'>We only support personal emails. @gmail email address</p>
                        <p className='text-center'>Thank you very much</p>
                        <Form
                            name="basic"
                            className="form"
                            onFinish={(values) => { handleTrialVps(values) }}
                        >
                            <div className="row-eq-height group-choo-os">
                                <Form.Item
                                    label="Operating system:"
                                    labelCol={{span: 24}}
                                    name="os"
                                    rules={[{ required: true, message: 'Please choose Operating system!'}]}
                                >
                                    <Radio.Group>
                                        {
                                            dataOs.length > 0 && dataOs.map((item, key) => 
                                                <Radio key={'os' + item.id} value={item.key}>
                                                    <div className='os-item'>
                                                        <img src={window.base_url + item?.image} width="100px" />
                                                        <p>{item?.title}</p>
                                                    </div>
                                                </Radio>
                                            )
                                        }
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <Form.Item
                                name="i_have_read"
                                valuePropName="checked"
                                rules={[
                                    { required: true, message: 'I have read and agree is required' }
                                ]}
                            >
                                <Checkbox checked={checkIhaveRead} onChange={(e) => {setCheckIhaveRead(e.target.checked);}}>I have read and agree to the <a href="https://shopsocks5.com/faq-detail/terms-and-conditions-and-privacy-policy" target='_blank'>Terms of Service</a></Checkbox>
                            </Form.Item>
                            <div className='row'>
                                <div className='col-12 col-sm-6'>
                                    <Button type="primary" htmlType="submit" className="d-block medium">Continue</Button>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <Button type="danger" htmlType="button" onClick={() => {setIsModalVisibleTrialVps(false)}} className="d-block medium">Cancel</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default Buysock;