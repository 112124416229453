import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, ExportOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import { Button, message, Form, Input, Checkbox, Drawer, notification, Popconfirm, Spin } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormSelect, ProFormDateTimePicker,ProFormDigit } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { socks, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

const fillSock = (list_sock) => {
    var temp = list_sock;
    if(typeof list_sock != 'undefined' && list_sock != ''){
        var fuck = temp.match(/\d{1,3}([.])\d{1,3}([.])\d{1,3}([.])\d{1,3}((:)|(\s)+)\d{1,8}/g);
        var fuck_temp = [];
        if(fuck != null){
            for (var i = 0; i < fuck.length; i++){
                if($.inArray(fuck[i], fuck_temp) === -1) fuck_temp.push(fuck[i]);
            }
            var list = "";
            fuck = fuck_temp;
            for(var i = 0; i < fuck.length; i++){
                if(fuck[i].match(/\d{1,3}([.])\d{1,3}([.])\d{1,3}([.])\d{1,3}(\s)+\d{1,8}/g)){
                    fuck[i] = fuck[i].replace(/(\s)+/,':');
                }
                list = list + fuck[i];
                if(i != (fuck.length-1)){
                    list = list + "\n";
                }
            }
            $('textarea#nest-messages_list_sock').val(list);
        }
    }
    return fuck_temp;
}

/**
 * Add node
 *
 * @param fields
 */
const handleAdd = (fields) => {
    const list_sock = fillSock(fields.list_sock);
    var checked = 0;
    var die = 0;
    var live = 0;
    $('#result-check').addClass('d-none');
    $("#sock-result .count-check").text(checked);
    $("#sock-result .count-live").text(live);
    $("#sock-result .count-die").text(die);
    $("#die").html('');
    $("#live").html('');
    $('.icon-check').show();
    $('#result-check').removeClass('d-none');
    $('#result-check .count-all').html(list_sock.length);
    try {
        $('#nest-messages .loading-form').show();
        var total = list_sock.length;
        for (var i = 0; i < list_sock.length; i++) {
            var sock = list_sock[i];
            socks.addItem({'sock': sock}).then((res) => {
                checked++;
                $("#sock-result .count-check").text(checked);
                if (checked == total) {
                    $('#nest-messages .loading-form').hide();
                }
                if (res.status == "success") {
                    live++;
                    $("#sock-result .count-live").text(live);
                    $("#live").append(res.message);
                } else if (res.status == 'fail') {
                    die++;
                    $("#sock-result .count-die").text(die);
                    $("#die").append(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    } catch (error) {
        $('#nest-messages .loading-form').hide();
    }
    return false;
};

/**
 * Add node Dichvu
 *
 * @param fields
 */
const handleAddDichvu = (fields) => {
    var listSock = fields.list_sock.split('\n');
    var checked = 0;
    var die = 0;
    var live = 0;
    $('#result-check').addClass('d-none');
    $("#sock-result .count-check").text(checked);
    $("#sock-result .count-live").text(live);
    $("#sock-result .count-die").text(die);
    $("#die").html('');
    $("#live").html('');
    $('.icon-check').show();
    $('#result-check').removeClass('d-none');
    $('#result-check .count-all').html(listSock.length);
    try {
        $('#nest-messages .loading-form').show();
        var total = listSock.length;
        for (var i = 0; i < listSock.length; i++) {
            var sockItem = listSock[i];
            socks.addItemDichvu({'item': sockItem}).then((res) => {
                checked++;
                $("#sock-result .count-check").text(checked);
                if (checked == total) {
                    $('#nest-messages .loading-form').hide();
                }
                if (res.status == "success") {
                    live++;
                    $("#sock-result .count-live").text(live);
                    $("#live").append(res.message);
                } else if (res.status == 'fail') {
                    die++;
                    $("#sock-result .count-die").text(die);
                    $("#die").append(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    } catch (error) {
        $('#nest-messages .loading-form').hide();
    }
    return false;
};

/**
 * Delete node
 *
 * @param selectedRows
 */
const handleRemove = async (selectedRows) => {
    const hide = message.loading('Processing...');
    if (!selectedRows) return true;
    try {
        var res = await socks.deleteItems({
            lists: selectedRows.map((row) => row.sock_id),
        });

        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Deleted successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        notification.open({
            message: 'Fail!',
            description: 'Delete failed, please try again!',
            type: 'error'
        });
    }
    return false;
};

/**
 * Export TXT
 *
 * @param param
 */
const handleExport = async (param) => {
    const hide = message.loading('Processing...');
    try {
        var res = await socks.exportTXT(param);
        hide();
        console.log(res);
        if (res.status == 'success') {
            const element = document.createElement("a");
            element.href = res.url;
            element.download = "sock.txt";
            document.body.appendChild(element);
            element.click();
            element.removeChild(link);
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
        console.log(error);
        // notification.open({
        //     message: 'Fail!',
        //     description: 'Export failed, please try again!',
        //     type: 'error'
        // });
    }
    return false;
};

/**
 * Clear Sock
 *
 * @parma param
 */
const handleClearSock18h = async (param) => {
    const hide = message.loading('Processing...');
    try {
        var res = await socks.clearSock18h(param);
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Clear successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
    }
    return false;
};

/**
 * Add Sock site check
 */
const handleAddSockSiteCheck = async () => {
    const hide = message.loading('Processing...');
    try {
        var res = await socks.addSockSiteCheck();
        hide();
        if (res.status == 'success') {
            notification.open({
                message: 'Success',
                description: 'Add successfully.',
                type: 'success'
            });
            return true;
        } else {
            if (typeof res.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
        }
    } catch (error) {
        hide();
    }
    return false;
};

const SocksComponent = () => {
    const [showDetail, setShowDetail] = useState(false);
    const [checked, setChecked] = useState(false);
    const [checkedIpReal, setCheckedIpReal] = useState(false);
    const [showDetailImport, setShowDetailImport] = useState(false);
    const [showDetailImportDichvu, setShowDetailImportDichvu] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const [dataCountry, setdataCountry] = useState({});
    const history = useHistory();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }

        getListCountry();
    }, []);


    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};
        setParamQuery(parameters);

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);
        //setParamQuery(getParamsByUrl());

        const data = await socks.getList(parameters);
       
        return data;
    }

    const getListCountry = async () => {
        try {
            var res = await socks.getSelect();
            setdataCountry(res);
        } catch (error) {
        }
        return false;
    };

    const onChange = (e) => {
        paramQuery.lock_sock = 0;
        if(e.target.checked){
            paramQuery.lock_sock = 1;
        }
        setChecked(e.target.checked);
    };

    const onChangeIpReal = (e) => {
        paramQuery.ip_real = 0;
        if(e.target.checked){
            paramQuery.ip_real = 1;
        }
        setCheckedIpReal(e.target.checked);
    };

    const columns = [
        {
            title: "Sock",
            dataIndex: 'sock_ip',
            sorter: false,
            hideInSearch: true,
            valueType: 'text',
            render: (dom, entity) => {
                return (
                    <a
                        onClick={() => {
                            setCurrentRow(entity);
                            setShowDetail(true);
                        }}
                    >
                        <span className="line-bottom">{dom}</span>
                    </a>
                );
            },
        },
        {
            title:'User Name',
            dataIndex: 'user_name',
            hideInSearch: true
        },
        {
            title:'Password',
            dataIndex: 'password',
            hideInSearch: true
        },
        {
            title:'Proxy Type',
            dataIndex: 'proxy_type',
            hideInSearch: true,
            render: (_, record) => [
                <span key={'proxy_type' + record.sock_id}>{record.proxy_type == 'proxy_https' ? 'HTTPS Proxy' : 'SOCKS5 Proxy'}</span>
            ]
        },
        {
            title:'IP Thật',
            dataIndex: 'ip_real',
            hideInSearch: true
        },
        {
            title:'Select Sock5',
            dataIndex: 'sock_type',
            valueEnum: {
                0: {
                    text:'Total Socks5'
                },
                1: {
                    text: "Seclect socks5"
                },
                2: {
                    text: "Premium Socks5"
                }
            },
        },
        {
            title:'Host Name',
            dataIndex: 'sock_hostname'
        },
        {
            title: "Zip Code",
            dataIndex: 'sock_zip',
            hideInSearch: true
        },
        {
            title: "City",
            dataIndex: 'sock_city',
            hideInSearch: true,
        },
        {
            title: "State",
            dataIndex: 'sock_states',
            hideInSearch: true
        },
        {
            title: "Country",
            dataIndex: 'sock_country',
            valueEnum: dataCountry,
            render: (_, record) => [
                <span key={record.sock_id}><img src={record.country_img} /> {record.sock_country}</span>
            ]
        },
        {
            title: "Type Sock",
            dataIndex: 'sock_symbol',
            valueEnum: {
                "rs": {
                    text:'R',
                },
                "dt": {
                    text: "D"
                }
            },
        },
        {
            title: "Time",
            dataIndex: 'sock_times',
            hideInSearch: true
        },
        {
            title: "Last View",
            dataIndex: 'sock_lastview',
            hideInSearch: true
        },
        {
            title: "BlackList",
            dataIndex: 'sock_back_list',
            valueEnum: {
                1: {
                    text:'BlackList'
                },
                0: {
                    text: "Clear"
                }
            },
            render: (_, record) => [
                <span key={record.sock_id}>{record.sock_back_list == 1 ? 'BlackList' : "Clear"}</span>
            ]
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="Socks"
                actionRef={actionRef}
                request={list}
                rowKey="sock_id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                    <Button
                        type="primary"
                        key="primary-service"
                        onClick={() => {
                            setShowDetailImportDichvu(true);
                        }}
                    >
                        <PlusOutlined /> Import Sock Dichvu
                    </Button>,
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            setShowDetailImport(true);
                        }}
                    >
                        <PlusOutlined /> Import Sock
                    </Button>,
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            handleAddSockSiteCheck();
                        }}
                    >
                        <PlusOutlined /> AddSock Site check
                    </Button>,
                    <Checkbox checked={checked} onChange={onChange}>
                        Lọc Sock
                    </Checkbox>,
                    <Checkbox checked={checkedIpReal} onChange={onChangeIpReal}>
                        Xuất IP thật
                    </Checkbox>,
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            handleExport(paramQuery);
                        }}
                    >
                        <ExportOutlined /> Export
                    </Button>,
                    <Button
                        type="primary"
                        key="primary"
                        onClick={() => {
                            if (handleClearSock18h(paramQuery)) {
                                actionRef.current?.reloadAndRest?.();
                            }
                        }}
                    >
                        <DeleteOutlined /> Clear Sock 18h
                    </Button>
                ]}
                columns={columns}
                rowSelection={{
                    onChange: (_, selectedRows) => {
                        setSelectedRows(selectedRows);
                    },
                }}
                pagination={{
                    pageSizeOptions: ['10', '20', '30', '50', '100', '200', '300', '500'],
                    defaultCurrent: (paramQuery?.current || 1),
                    defaultPageSize: (paramQuery?.pageSize || 30)
                }}
            />

            {/*Select row with checkbox*/}
            {selectedRowsState?.length > 0 && (
                <FooterToolbar
                    extra={
                        <div className="mb-2">
                            Choose <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>
                            &nbsp;items&nbsp;&nbsp;
                        </div>
                    }
                >
                    <Popconfirm
                        title="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={ async () => {
                            await handleRemove(selectedRowsState);
                            setSelectedRows([]);
                            actionRef.current?.reloadAndRest?.();
                        }} 
                    >
                        <Button type="danger"><DeleteOutlined /> Delete</Button>
                    </Popconfirm>
              </FooterToolbar>
            )}

            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.sock_id && (
                    <ProDescriptions 
                        title={currentRow?.user_name}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.sock_id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>

            {/*Import Sock*/}
            <Drawer
                width={520}
                visible={showDetailImport}
                onClose={() => {
                    setShowDetailImport(false);
                }}
                closable={true}
            >
                <Form name="nest-messages" layout="vertical" className="mt-5" onFinish={handleAdd}>
                    <div className="relative">
                        <Form.Item name="list_sock"  width="lg" label="Socks List"  rules={[{ required: true }]}>
                            <Input.TextArea rows="6" />
                        </Form.Item>
                        <Form.Item className="text-right">
                            <Button type="primary" htmlType="submit">Import</Button>
                        </Form.Item>
                        <div className="loading-form"><div><Spin /></div></div>
                    </div>
                    <div id="result-check" className="d-none">
                        <div id="sock-result" className="text-center">
                            All: <font color="violet" className="count-all">0</font>&nbsp;
                            | Checked: <font color="green" className="count-check">0</font>&nbsp;
                            | Success: <font color="blue" className="count-live">0</font>&nbsp;
                            | Error: <font color="red" className="count-die">0</font>
                        </div>
                        <hr />
                        <div id="live" className="mb-4"></div>
                        <div id="die"></div>
                    </div>
                </Form>
            </Drawer>

            {/*Import Sock Dichvu*/}
            <Drawer
                width={520}
                visible={showDetailImportDichvu}
                onClose={() => {
                    setShowDetailImportDichvu(false);
                }}
                closable={true}
            >
                <Form name="nest-messages" layout="vertical" className="mt-5" onFinish={handleAddDichvu}>
                    <div className="relative">
                        <Form.Item name="list_sock"  width="lg" label="Socks List Dichvu"  rules={[{ required: true }]}>
                            <Input.TextArea rows="6" />
                        </Form.Item>
                        <Form.Item className="text-right">
                            <Button type="primary" htmlType="submit">Import</Button>
                        </Form.Item>
                        <div className="loading-form"><div><Spin /></div></div>
                    </div>
                    <div id="result-check" className="d-none">
                        <div id="sock-result" className="text-center">
                            All: <font color="violet" className="count-all">0</font>&nbsp;
                            | Checked: <font color="green" className="count-check">0</font>&nbsp;
                            | Success: <font color="blue" className="count-live">0</font>&nbsp;
                            | Error: <font color="red" className="count-die">0</font>
                        </div>
                        <hr />
                        <div id="live" className="mb-4"></div>
                        <div id="die"></div>
                    </div>
                </Form>
            </Drawer>
        </PageContainer>
    );
}
export default SocksComponent;
