import { Request } from '../_until';

export const setting_menu = {
    getData,
    updateItem
};

const urls = {
    get_data: `/setting_menu`,
    edit_item: `/setting_menu/update`,
};

function getData() {
    return Request.get(urls.get_data, {}).then(res => res.data);
};

function updateItem(requestOptions) {
    return Request.post(urls.edit_item, requestOptions).then(res => res.data);
};