import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Badge, Spin, Modal } from 'antd';
import {
    Nav,
    Navbar,
    NavbarBrand,
    Collapse,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';
import { notification } from '../../../../endpoint';



/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logo from '../../../../assets/theme/images/shopsocks5.png';

const Header = () => {
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('user'));
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [current, setCurrent] = useState([]);

    useEffect(() => {
        getNotification();
    }, []);

    const getNotification = () => {
        notification.getList({current: page, pageSize: 5}).then(res => {
            if (res.status == 'success') {
                if (page*5 >= res.total) {
                    setHasMore(false);
                }
                setData(data.concat(res.response));
                setCount(res.not_ready);
                setTotal(res.total);
                setPage(page + 1);
            }
        }).then(comp => {
        }).then(error => {
        });
    }

    const ChangeAll = () => {
        notification.changeAllStatus({'status': 1}).then(res => {
            if (res.status == 'success') {
                $('.message-item').removeClass('not-ready');
                setCount(0);
            }
        }).then(comp => {
        }).then(error => {
        });
    }

    const showModal = (item) => {
        setCurrent(item);
        setIsModalVisible(true);

        notification.changeStatus(item?.id, {'status': 1}).then(res => {
            if (res.status == 'success') {
                $('#message-item'+ item?.id).removeClass('not-ready');
                if(count - 1 >= 0){
                    setCount(count - 1);
                }
            }
        }).then(comp => {
        }).then(error => {
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        history.push({pathname: '/auth/login'});
    }
    
    /*--------------------------------------------------------------------------------*/
    /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
    /*--------------------------------------------------------------------------------*/
    const showMobilemenu = () => {
        document.getElementById('main-wrapper').classList.toggle('show-sidebar');
    }

    return (
        <header className="topbar navbarbg" data-navbarbg="skin1">
            <Navbar className="top-navbar" dark expand="md">
                <div className="navbar-header" id="logobg" data-logobg="skin6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
                    {/*--------------------------------------------------------------------------------*/}
                    <NavbarBrand href="/">
                        <span className="logo-text">
                            <img src={logo} alt="homepage" className="dark-logo" />
                        </span>
                    </NavbarBrand>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Mobile View Toggler  [visible only after 768px screen]                         */}
                    {/*--------------------------------------------------------------------------------*/}
                    <button className="btn-link nav-toggler d-block d-md-none text-white" onClick={() => showMobilemenu()}>
                        <i className="ti-menu ti-close" style={{fontSize: "22px"}} />
                    </button>
                </div>
                <Collapse className="navbarbg" navbar data-navbarbg="skin1" >
                    <Nav className="ml-auto float-right" navbar>
                        {/*<NavItem>
                            <a href="https://www.wrappixel.com/templates/xtreme-react-redux-admin/" className="btn btn-danger mr-2" style={{ marginTop: '15px' }}>Upgrade to Pro</a>
                        </NavItem>
                        --------------------------------------------------------------------------------*/}
                        {/* Start Profile Dropdown                                                         */}
                        {/*--------------------------------------------------------------------------------*/}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav>
                                {
                                    count > 0 ? (<Badge count={count} className="text-white"><i className="mdi mdi-bell font-24"></i></Badge>) : <i className="mdi mdi-bell font-24"></i>
                                }
                            </DropdownToggle>
                            <DropdownMenu right className="mailbox">
                                <span className="with-arrow"><span className="bg-white" style={{backgroundColor: "#fff"}}></span></span>
                                <div className="message-center notifications">
                                    {   count > 0 && (<div className="view-all text-center">
                                            <a onClick={ChangeAll}>View All</a>
                                        </div>)
                                    }
                                    <InfiniteScroll
                                        dataLength={data.length}
                                        next={getNotification}
                                        hasMore={hasMore}
                                        height={300}
                                        loader={<div className="text-center"><Spin size="small" /></div>}
                                        scrollableTarget="scrollableDiv"
                                    >
                                        {
                                            data && data.length > 0 && data.map((item, key) => 
                                                <span onClick={() => showModal(item)} id={"message-item" + item.id} className={"message-item" + (item.is_ready == 0 ? " not-ready" : "")} key={item.id}>
                                                    <span className="btn btn-circle btn-primary">
                                                        <i className="fa fa-link"></i>
                                                    </span>
                                                    <div className="mail-contnet">
                                                        <h5 className="message-title">{item.title}</h5>
                                                        <span className="mail-desc">{item.type == 'new_order' ? "New Order" : "Confirm Order"}</span>
                                                        <span className="time">{item.date}</span>
                                                    </div>
                                                </span>
                                            )
                                        }
                                        

                                        {/* <span className="message-item">
                                            <span className="btn btn-circle btn-success"><i className="ti-calendar"></i></span>
                                            <div className="mail-contnet">
                                                <h5 className="message-title">Event Today</h5><span className="mail-desc">Just a reminder that you have event.</span><span className="time">9:10 PM</span>
                                            </div>
                                        </span>
                                        <span className="message-item">
                                            <span className="btn btn-circle btn-info"><i className="ti-settings"></i></span>
                                            <div className="mail-contnet">
                                                <h5 className="message-title">Settings</h5><span className="mail-desc">You can customize this template as you want.</span><span className="time">9:08 AM</span>
                                            </div>
                                        </span>
                                        <span className="message-item">
                                            <span className="btn btn-circle btn-primary"><i className="ti-user"></i></span>
                                            <div className="mail-contnet">
                                                <h5 className="message-title">Check Email</h5><span className="mail-desc">Just check my admin!</span><span className="time">9:02 AM</span>
                                            </div>
                                        </span> */}
                                    </InfiniteScroll>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className="pro-pic">
                                <img
                                    src={user?.avatar}
                                    alt="user"
                                    className="rounded-circle"
                                    width="31"
                                />
                            </DropdownToggle>
                            <DropdownMenu right className="user-dd">
                                <span className="with-arrow"><span className="bg-white" style={{backgroundColor: "#fff"}}></span></span>
                                <DropdownItem>
                                    <NavLink to="/ntsock/profile" className="mb-0" style={{color: "#000"}}>
                                        <i className="ti-settings mr-1 ml-1" /> My Account Setting
                                    </NavLink>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>
                                    <NavLink to="/ntsock/profile" className="mb-0" style={{color: "#000"}}>
                                        <i className="ti-user mr-1 ml-1" /> Change Password
                                    </NavLink>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={logout}>
                                    <i className="fa fa-power-off mr-1 ml-1" /> Logout
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>
            </Navbar>
            <Modal title="Notification" visible={isModalVisible} footer={false} onCancel={handleCancel}>
                <h4>{current?.title}</h4>
                <div className="mt-2">{current?.description}</div>
                <div className="mt-2"><b>{current?.date}</b></div>
            </Modal>
        </header>
    );
}
export default Header;
