import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Spin } from 'antd';
import { faqs } from '../../endpoint';

const FAQDetail = () => {
    const [loading, setLoading] = useState(true);
    const [dataFaq, setDataFaq] = useState([]);
    const { slug } = useParams();

    const loadLoc = () => {
        var headingList = $("<ol id='list-heading'></ol>");
        var currentLevels = [headingList];
        var oldLevel = 0;
        $("#faq-detail-info h2, #faq-detail-info h3, #faq-detail-info h4").each(function(index) {
            var heading = $(this);
            var text = $.trim(heading.text());
            if (text != '') {
                var level = parseInt(heading.prop("tagName").substring(1));
                heading.attr("id", "point_" + index);
                var listItem = $(`<li class="level-${level}"><a href="#point_${index}">${text}</a></li>`);

                while (level <= currentLevels.length) {
                    currentLevels.pop();
                }
                var parentList = currentLevels[currentLevels.length - 1];
                var nestedList;
                nestedList = listItem;
                parentList.append(nestedList);
                currentLevels.push(nestedList);
                oldLevel = level;
            }
        });
        $('#ftwp-contents .ftwp-body').html(headingList);

        if(currentLevels.length <= 1) {
            $('#ftwp-container').hide();
        }

        $(document).on('click', '#icon_toggle_mb', function() {
            $('#ftwp-contents').toggleClass('active');
            $('#ftwp-container').toggleClass('active');
        });

        $(document).on("click", '#ftwp-contents #list-heading li a', function(event) {
            let id = $(this).attr('href');
            if ($(id).length > 0) {
                let top = $(id).offset().top;
                $("html, body").animate({ scrollTop: top}, 'slow');

                $('#ftwp-contents').removeClass('active');
                $('#ftwp-container').removeClass('active');
            }
            return false;
        });
    }

    const loadFAQ = () => {
        faqs.getItemFrontend(slug).then(res => {
            if (res.status == 'success') {
                setDataFaq(res.response);
                document.title = res.response.name + " - " + window?.title;
                $("#faq-detail-info").html(res.response.content);
                loadLoc();
            }
            setLoading(false);
        }).then(res => {
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Instructions - " + window?.title;
        loadFAQ();
    }, []);
    
    return (
        <div className="container">
            {
                loading ? 
                (<div className="text-center mt-5"><Spin /></div>) : ''
            }
            <div className={"row " + (loading ? 'd-none' : '')}>
                <div className="col-12 col-md-9 order-md-2">
                    <div className="panel panel-default panel-custom">
                        <h3 className="panel-heading mb-2">{dataFaq?.name}</h3>
                        <div className="panel-body">
                            <div className="info-login">
                                <div id='faq-detail-info'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-3 faq-detail-toc order-md-1">
                    <div id="ftwp-container" className="ftwp-wrap ftwp-middle-left ftwp-fixed-to-post ftwp-minimize">
                        <button type="button" id="ftwp-trigger" className="" title="click To Maximize The Table Of Contents">
                            <span className="icon-header-container">
                                <a id="icon_toggle_mb">
                                    <i className="fa fa-list-ul"></i>
                                </a>
                            </span>
                        </button>
                        <span className="icon-header-container">
                            <a id="icon_toggle_mb">
                                <i className="fa fa-list-ul"></i>
                            </a>
                        </span>
                        <nav id="ftwp-contents" className="ftwp-shape-square ftwp-border-thin ftwp-transform-left-center">
                            <header id="ftwp-header">
                                <button type="button" id="ftwp-header-minimize" className="ftwp-icon-minimize" aria-labelledby="ftwp-header-title" aria-label="Expand or collapse"></button>
                                <h3 id="ftwp-header-title">Table of contents</h3>
                            </header>
                            <div className='ftwp-body'></div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQDetail;
