import React from 'react';


const Footer = () => {
    return (
        <footer className="footer text-center">
            Copyright © 2015 - <b>Shopsocks5.com</b> | All Rights Reserved
        </footer>
    );
}
export default Footer;
 