import React, { useState, useEffect } from 'react';
import indexRoutes from './routes/index';
import themeRoutes from './routes/theme';
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { get_user } from './_until';
import Login from './component/admin/views/page/login';
import 'antd/dist/antd.css';
import '@ant-design/pro-table/dist/table.css';
import './assets/theme/styles.scss';

const App = () => {
    useEffect(() => {
    }, []);

    return (
        <Router>
            <Switch >
                <Route path='/auth/login' component={ Login } />
                <Route path='/ntsock/:path?' >
                    <Switch>
                        {   
                            (get_user() != null) && indexRoutes.map((prop, key) => {
                                return <Route path={ prop.path } key={ key } component={ prop.component } />;
                            })
                        } 
                        {
                            (get_user() == null) && (<Redirect to={{ pathname: '/auth/login'}} />)
                        }
                    </Switch> 
                </Route>
                <Route path= "/:path?">
                    {
                        themeRoutes.map((prop, key) => {
                            return <Route path={ prop.path } key={ key } component={ prop.component } />;
                        })
                    }  
                </Route>
            </Switch>
        </Router>
    );
};
export default App;