import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Modal, notification, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { account } from '../../endpoint';

const Forgot = () => {
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('user'));
    const [isModal, setIsModal] = useState(false);

    const onFinish = async (values) => {
        const hide = message.loading('Processing...');
        try {
            var res = await account.forgot(values);
            hide();
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: res.message,
                    type: 'success'
                });
            } else {
                if (typeof res.message != 'undefined') {
                    if (typeof res.error_send_forgot != 'undefined') {
                        if (res.error_send_forgot == 1) {
                            setIsModal(true);
                        } else {
                            notification.open({
                                message: 'Fail!',
                                description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                                type: 'error'
                            }); 
                        }
                    } else {
                        notification.open({
                            message: 'Fail!',
                            description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                            type: 'error'
                        });
                    }
                }
            }
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Forgot failed, please try again!',
                type: 'error'
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        document.title = "Forgot - " + window?.title;
        if (user != null) {
            history.push({pathname: '/home'});
        }
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-3"></div>
                <div className="col-md-4 col-sm-6">
                    <div className="panel panel-default panel-custom">
                        <div className="panel-heading">USER FORGOT PASSWORD</div>
                        <div className="panel-body">
                            <div id="cards">
                                <div className="section_content">
                                    <Form
                                        name="basic"
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        className="form-horizontal mt-3"
                                        id="forgotform"
                                    >
                                        <Form.Item
                                            label="Email:"
                                            labelCol={{span: 24}}
                                            name="email"
                                            rules={[{ required: true, type: "email", message: 'Please input your email!' }]}
                                        >
                                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                                        </Form.Item>
                                        <div className="mb-3 text-center">
                                            <div className="col-xs-12 pb-3">
                                                <Button type="primary" htmlType="submit" className="d-block medium">Submit</Button>
                                                <div className="h-10"></div>
                                                <Link to="/">Cancel</Link>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-3"></div>
            </div>
            <Modal title={false} visible={isModal} onCancel={() => {setIsModal(false)}} footer={null} width={480}>
                <div className="row">
                    <div className="col-md-12">
                        <p className='text-center mb-0'>We have sent an mail to your email account.</p>
                        <p className='text-center mb-0'>You please check your email at inbox or spam.</p>
                        <hr />
                        <p className="text-center mt-3">
                            <span>If you do not receive the mail please contact us:</span><br/> 
                            Skype: {window.confirm?.skype}<br/>
                            Email: {window.confirm?.support_email}
                        </p>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default Forgot;
