import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import Header from '../include/Header';
import Footers from '../include/Footer';
import TopBarProgress from "react-topbar-progress-indicator";
import WebsiteRoutes from '../../../routes/website';

const LayoutTheme = (props) => {
    const [loadingProcessBar, setLoadingProcessBar] = useState(true);

    window.addEventListener('load', function() {
        setLoadingProcessBar(true);
        setTimeout(function() {
            setLoadingProcessBar(false);
        }, 1500);
    });

    props.history.listen((location, action) => {
        setLoadingProcessBar(true);
        setTimeout(function() {
            setLoadingProcessBar(false);
        }, 1000);

        $('#navbarColor03').removeClass('show')
        if (window.confirm?.ga) {
            ReactGA.initialize(window.confirm?.ga);
            ReactGA.pageview(window.location.pathname + window.location.search);
            //console.log(location.pathname + location.search);

            const tagManagerArgs = {
                gtmId: window.confirm?.ga,
                dataLayer: {
                    page: 'home'
                },
                dataLayerName: 'PageDataLayer'
            }
            TagManager.initialize(tagManagerArgs)
        }
    });

    const onScrollTop = () => {
        $("html, body").animate({ scrollTop: "0"}, 'slow');
    }

    useEffect(() => {
    }, []);

    return (
        <div className="wrapper">
            {loadingProcessBar && <TopBarProgress />}
            <Header />
            <div className="page-content">
                <Switch>
                    {WebsiteRoutes.map((prop, key) => {
                        if (prop.redirect) {
                            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                        } else {
                            return (
                                <Route path={prop.path} component={prop.component} key={key} />
                            );
                        }
                    })}
                </Switch>
            </div>
            <Footers/>  
            <div className="noopslikebox">
                <div id="support-box">
                    <div className="input-group">
                        <label className="form-control">Skype: {window.confirm?.skype}</label>
                        <label className="form-control">Email: {window.confirm?.support_email}</label>
                        <label className="form-control opacity-0">Telegram:</label>
                        <label className="form-control opacity-0">PM: {window.confirm?.PM_PURSE}</label>
                        <label className="form-control opacity-0">&nbsp;&nbsp;</label>
                    </div>
                </div>
            </div>
            <div className='scroll-top'>
                <a onClick={onScrollTop}><i className='fa fa-angle-up'></i></a>
            </div>
        </div>
    );
}
export default LayoutTheme;
