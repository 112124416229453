import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Switch, notification, Modal, message, Spin } from 'antd';
import { LockOutlined, MailOutlined, UserOutlined, SafetyOutlined, UndoOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { account, authenticator } from '../../endpoint';
import AppContext from './../../_until/AppContext';

import icBuyPoint from '../../assets/images/ic-buy-point.png';

const MyAccount = () => {
    const history = useHistory();
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTelegram, setLoadingTelegram] = useState(false);
    const [loadingConvert, setLoadingConvert] = useState(false);
    const [isModalConvertPlan, setIsModalConvertPlan] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalFreeVisible, setIsModalFreeVisible] = useState(false);
    const [isModalChangePasswordVisible, setIsModalChangePasswordVisible] = useState(false);
    const [isShowModalOnOff, setIsShowModalOnOff] = useState(false);
    const [isShowModalVerifyEmail, setIsShowModalVerifyEmail] = useState(false);
    const [profile, setProfile] = useState([]);
    const [telegram, setTelegram] = useState([]);
    const [connect, setConnect] = useState(null);
    const user = JSON.parse(localStorage.getItem('user'));
    const oneTimePassword = localStorage.getItem('one_time_password');
    const [isLoadNotification, setLoadNotification] = useContext(AppContext);

    /**
     * Handle change password
     *
     * @param values
     */
    const onFinish = (values) => {
        const hide = message.loading('Processing...');
        try {
            setLoading(true);
            account.change_password(values).then(res => {
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: 'Save successfully',
                        type: 'success'
                    });
                    setIsModalChangePasswordVisible(false);
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                            type: 'error'
                        });
                    }
                }
            }).then(err => {
                hide();
                setLoading(false);
            });
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
        }
    };

    /**
     * Handle check status telegram
     */
    const checkStatusTelegram = () => {
        account.telegram_reset_status().then(res => {
            if (res.status == 'success' && res.success) {
                if (connect != null) {
                    clearInterval(connect);
                }
                getTelegramInfo();
                setLoadingTelegram(false);
            }
        });
    }

    /**
     * Handle connect telegram
     */
    const telegramConnection = () => {
        window.open("https://t.me/shopsock5_bot?start=" + profile?.user_id);
        setLoadingTelegram(true);
        account.telegram_connection().then(res => {
            if (res.status == 'success') {
                checkStatusTelegram();
                setConnect(setInterval(checkStatusTelegram, 10*1000));
            }
        });
    }

    /**
     * Handle disconnect telegram
     */
    const disconnectTelegramConnection = () => {
        setLoadingTelegram(true);
        account.telegram_disconnect().then(res => {
            if (res.status == 'success') {
                account.telegram_info().then(res => {
                    if (res.status == 'success') {
                        getTelegramInfo();
                        setLoadingTelegram(false);
                    }
                });
            }
        });
    }

    /**
     * Handle get info telegram
     */
    const getTelegramInfo = () => {
        account.telegram_info().then(res => {
            if (res.status == 'success') {
                setTelegram(res.response);
            }
        });
    }

    /**
     * Handle get info profile
     */
    const getProfile = () => {
        setLoadingProfile(true);
        account.profile().then(res => {
            if (res.status == 'success') {
                setProfile(res.response);
                setLoadingProfile(false);
            }
        });
    }

    /**
     * Handle convert plan
     */
    const covertPlan = () => {
        const hide = message.loading('Processing...');
        try {
            setLoadingConvert(true);
            account.convert_plan({}).then(res => {
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: 'Convert successfully',
                        type: 'success'
                    });
                    setIsModalConvertPlan(false);
                    getProfile();
                    setLoadNotification(true);
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                            type: 'error'
                        });
                    }
                }
            }).then(err => {
                hide();
                setLoadingConvert(false);
            });
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
        }
    }

    /**
     * Handle change on/off reset plan
     *
     * @param status
     */
    const changeShowReset = (status) => {
        const hide = message.loading('Processing...');
        try {
            setLoadingConvert(true);
            setLoadingButton(true);
            account.change_show_reset({'is_show': status ? 1 : 0}).then(res => {
                if (res.status == 'success') {
                    setIsShowModalOnOff(false);
                    notification.open({
                        message: 'Success',
                        description: 'Save successfully',
                        type: 'success'
                    });
                    profile.is_show_reset = status ? 1 : 0;
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                            type: 'error'
                        });
                    }
                }
                setLoadingButton(false);
            }).then(err => {
                hide();
                setLoadingConvert(false);
                setLoadingButton(false);
            });
        } catch (error) {
            hide();
            setLoadingButton(false);
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
        }
    }

    /**
     * Handle change status api
     *
     * @param status
     */
    const changeStatusApi = (status) => {
        const hide = message.loading('Processing...');
        try {
            account.change_status_api({'is_show': status ? 1 : 0}).then(res => {
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: 'Save successfully',
                        type: 'success'
                    });
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                            type: 'error'
                        });
                    }
                }
            }).then(err => {
                hide();
            });
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
        }
    }

    /**
     * Handle resend verify
     */
    const resendVerify = () => {
        const hide = message.loading('Processing...');
        try {
            setLoadingButton(true);
            account.resend_verify().then(res => {
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: 'Send mail successfully',
                        type: 'success'
                    });
                    setIsShowModalVerifyEmail(false);
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                            type: 'error'
                        });
                    }
                }
                hide();
                setLoadingButton(false);
            }).then(err => {
                setLoadingButton(false);
                hide();
            });
        } catch (error) {
            hide();
            setLoadingButton(false);
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
        }
    }

    /**
     * Handle show modal reset
     */
    const showModalReset = () => {
        setIsModalVisible(true);
    }

    /**
     * Handle show modal reset free
     */
    const showModalFree = () => {
        setIsModalFreeVisible(true);
    }

    /**
     * Handle close modal reset
     */
    const handleCancelReset = () => {
        setIsModalVisible(false);
    };

    /**
     * Handle close modal reset free
     */
    const handleCancelFree = () => {
        setIsModalFreeVisible(false);
    };

    /**
     * Handle close modal change password
     */
    const handleCancelResetPassword = () => {
        setIsModalChangePasswordVisible(false);
    };

    /**
     * Handle reset plan
     */
    const resetPlan = () => {
        setLoadingButton(true);
        account.reset_plan({is_free: isModalFreeVisible ? 1 : 0}).then(res => {
            if (res.status == 'success') {
                notification.open({
                    message: 'Success',
                    description: 'Reset successfully',
                    type: 'success'
                });
                handleCancelReset();
                handleCancelFree();
                location.reload();
            } else {
                notification.open({
                    message: 'Fail!',
                    description: res.message,
                    type: 'error'
                });
            }
            setLoadingButton(false);
        }).catch(err => {
            setLoadingButton(false);
            notification.open({
                message: 'Fail!',
                description: "Error",
                type: 'error'
            });
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Profile - " + window?.title;
        if (user != null) {
            authenticator.checkVerify2faFrontend({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/verify2fa.html'});
                } else {
                    account.profile().then(res => {
                        if (res.status == 'success') {
                            setProfile(res.response);
                            setLoadingProfile(false);
                        }
                    });

                    getTelegramInfo();
                }
            }).then(res => {
            });
        }
    }, []);

    if (user == null) {
        history.push({pathname: '/login.html'});
    }

    return (
        <div className="container">
            {
                loadingProfile ? 
                (<div className="text-center mt-5"><Spin /></div>) :
                (
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="panel panel-default panel-custom h-100">
                                        <div className="panel-heading">Account Information</div>
                                        <div className="panel-body">
                                            <div className='my-account-info'>
                                                <div className='row justify-content-between align-items-center'>
                                                    <div className='col-12 col-md-auto'>
                                                        <div className='d-flex align-items-center mb-3'>
                                                            <div className='icon'>
                                                                <UserOutlined />
                                                            </div>
                                                            <div className='info'>
                                                                <h4 className='mb-1'>Account</h4>
                                                                <p className='mb-0'>System account that can be used to login.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-auto'>
                                                        <p className='mb-0'>
                                                            <span>{profile?.user_name}</span>
                                                            <span className='right-end-width'>&nbsp;</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className='row justify-content-between align-items-center'>
                                                    <div className='col-12 col-md-auto'>
                                                        <div className='d-flex align-items-center mb-3'>
                                                            <div className='icon'>
                                                                <MailOutlined />
                                                            </div>
                                                            <div className='info'>
                                                                <h4 className='mb-1'>Email Address</h4>
                                                                <p className='mb-0'>Protect your account and transactions.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-auto'>
                                                        <p className='mb-0'>
                                                            <span>{profile?.user_mail}</span>
                                                            <span className='right-end-width'>
                                                                {profile?.verified_at == 0 && (<Button type="default" onClick={() => { setIsShowModalVerifyEmail(true); }} className="small mb-3 ml-2">Verification Now</Button>)}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className='row justify-content-between align-items-center'>
                                                    <div className='col-12 col-md-auto'>
                                                        <div className='d-flex align-items-center mb-3'>
                                                            <div className='icon'>
                                                                <LockOutlined />
                                                            </div>
                                                            <div className='info'>
                                                                <h4 className='mb-1'>Password</h4>
                                                                <p className='mb-0'>To login your account</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-auto'>
                                                        <p className='mb-0'>
                                                            <span>******</span>
                                                            <span className='right-end-width'>
                                                                <Button type="default" onClick={() => { setIsModalChangePasswordVisible(true); }} className="small mb-3 ml-2">Change Password</Button>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className='row justify-content-between align-items-center'>
                                                    <div className='col-12 col-md-auto'>
                                                        <div className='d-flex align-items-center mb-3'>
                                                            <div className='icon'>
                                                                <SafetyOutlined />
                                                            </div>
                                                            <div className='info'>
                                                                <h4 className='mb-1'>Google authenticator</h4>
                                                                <p className='mb-0'>To better protect your account and transactions, please download and install Google Authenticator</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-auto'>
                                                        <p className='mb-0'>
                                                            {profile?.is_security == 0 ? (<span className='text-danger'>Not set</span>) : (<span className='text-primary'>Enabled</span>)}
                                                            <span className='right-end-width'>
                                                                <Link to="/2fa.html" className="ant-btn ant-btn-default small mb-3 ml-2">
                                                                    <span>{profile?.is_security == 0 ? 'Start Now' : 'Disabled'}</span>
                                                                </Link>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div className='row justify-content-between align-items-center'>
                                                    <div className='col-12 col-md-auto'>
                                                        <div className='d-flex align-items-center mb-3'>
                                                            <div className='icon'>
                                                                <UsergroupAddOutlined />
                                                            </div>
                                                            <div className='info'>
                                                                <h4 className='mb-1'>Referral</h4>
                                                                <p className='mb-0'>Refer others to Shopsocks5.com and earn 15% commission to fund your next purchase.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-auto'>
                                                        <p className='mb-0'>
                                                            <span>&nbsp;</span>
                                                            <span className='right-end-width'>
                                                                <Link to="/user-invite.html" className="ant-btn ant-btn-default small mb-3 ml-2">
                                                                    <span>Referral</span>
                                                                </Link>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-6 col-md-6">
                                    <div className="panel panel-default panel-custom h-100">
                                        <div className="panel-heading">Public Socks5</div>
                                        <div className="panel-body">
                                            <div className="info-login">
                                                <ul className="list-unstyled">
                                                    <li>Plan: 
                                                        <span>{profile?.plan_name}</span>
                                                        {
                                                            profile?.plan_name == 'List' || profile?.plan_name == 'Daily' ? (
                                                                <Button type="primary" onClick={() => {setIsModalConvertPlan(true)}} className="small text-white mb-3 ml-2">Convert</Button>
                                                            ) : ''
                                                        }
                                                        <Link to="/convert.html" className="medium btn btn-primary ml-2">
                                                            Convert History
                                                        </Link>
                                                    </li>
                                                    <li>Expired: <span>{profile?.user_exdate}</span> ({profile?.user_exdate_left})</li>
                                                    <li>Used today: <span>{profile?.count}</span></li>
                                                    <li>Total Reset Limit Socks5 This Month: <span>{profile?.count_reset}</span></li>
                                                    <li className="d-none">Last Login: <span>{profile?.user_lastlogin}</span></li>
                                                    {
                                                        profile?.plan_name == 'Socks5 All' ? (
                                                            <li>Limit: <span>Unlimited socks5 per day</span></li>
                                                        ) : (
                                                            <li>Limit: <span>{profile?.count} of {profile?.limit} socks</span></li>
                                                        )
                                                    }
                                                    <li className="d-none">Bonus: <span>{profile?.bonus}$</span></li>
                                                    <li>
                                                        <Link to="/histories.html" className="medium btn btn-primary mr-3 mb-3 d-none">
                                                            Histories
                                                        </Link>
                                                        <Link to="/orders.html" className="medium btn btn-primary mr-3 mb-3">
                                                            Orders History
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <p className='mb-1'>Reset Limit Socks5</p>
                                                        <div>
                                                            <Link to="/reset.html" className="ant-btn ant-btn-primary medium text-white mr-3 mb-3">
                                                                Reset History
                                                            </Link>
                                                            {
                                                                profile?.is_reset == 1 && (
                                                                    <Button type="primary" onClick={() => showModalReset()} htmlType="submit" className="medium text-white mr-3 mb-3">
                                                                        Reset <UndoOutlined className="site-form-item-icon" />
                                                                    </Button>
                                                                )
                                                            }
                                                            {
                                                                profile?.is_reset_free == 1 ? (
                                                                    <Button type="primary" onClick={() => showModalFree()} htmlType="submit" className="medium text-white mb-3">
                                                                        Reset Free <UndoOutlined className="site-form-item-icon" />
                                                                    </Button>
                                                                ) : ''
                                                            }
                                                        </div>
                                                    </li>
                                                    <li>
                                                        Show button Reset Limit Socks5:<br/>
                                                        <Switch
                                                            checkedChildren="On"
                                                            unCheckedChildren="Off"
                                                            onChange={
                                                                (value) => {
                                                                    if (value) {
                                                                        setIsShowModalOnOff(true)
                                                                    } else {
                                                                        changeShowReset(value);
                                                                    }
                                                                }
                                                            }
                                                            key={profile.user_id}
                                                            checked={profile?.is_show_reset == 1 ? true : false} 
                                                        />
                                                    </li>
                                                    <li className='pt-3'>
                                                        API Socks5:<br/>
                                                        <Switch 
                                                            checkedChildren="On"
                                                            unCheckedChildren="Off"
                                                            onChange={(value) => {changeStatusApi(value);}}
                                                            key={"status_api" + profile.user_id}
                                                            defaultChecked={profile?.status_api == 1 ? true : false} 
                                                        />
                                                    </li>
                                                    <li className='pt-3'>API Token: <span>{profile?.api_token}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="panel panel-default panel-custom h-100">
                                        <div className="panel-heading">Private: User Pass Auth</div>
                                        <div className="panel-body">
                                            <div className="info-login">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <p className='mb-1 mt-3'>Static Datacenter Proxies</p>
                                                        <Link to="/list-proxy.html" className="medium btn btn-primary mr-3 mb-3">
                                                            Proxies Manager
                                                        </Link>
                                                        <Link to="/list-expired-proxy.html" className="medium btn btn-primary mr-3 mb-3">
                                                            Proxy expires in less than 3 days
                                                        </Link>
                                                        <Link to="/history-proxy-change-ip.html" className="medium btn btn-primary mr-3 mb-3">
                                                            History Change New IP
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <p className='mb-1 mt-3'>Cloud VPS</p>
                                                        <Link to="/list-vps.html" className="medium btn btn-primary mr-3 mb-3">
                                                            Cloud VPS Manager
                                                        </Link>
                                                        <Link to="/list-expired-vps.html" className="medium btn btn-primary mr-3 mb-3">
                                                            Cloud VPS expires in less than 3 days
                                                        </Link>
                                                        <Link to="/history-vps-change-ip.html" className="medium btn btn-primary mr-3 mb-3">
                                                            History Change New IP
                                                        </Link>
                                                    </li>
                                                    <li className='d-none'>
                                                        <p className='mb-1 mt-3'>Point balance in account: <span>{profile?.credit} Point</span></p>
                                                        <p className='mb-0'>
                                                            <Link to='/buy-point.html' className='btn btn-primary mb-3 mr-3'><img src={icBuyPoint} width="24px" /> Buy Point Now</Link>
                                                            <Link to='/ss5-wallet.html' className='btn btn-primary mb-3 mr-3'><img src={icBuyPoint} width="24px" /> SS5 Wallet</Link>
                                                            <Link to='/user-invite.html' className='btn btn-primary mb-3'>Referral</Link>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-12 col-md-12">
                                    <div className="panel panel-default panel-custom h-100">
                                        <div className="panel-heading">Connect Telegram</div>
                                        <div className="panel-body">
                                            <div className="info-login">
                                                <div className="box-card-icon mb-3 d-flex align-items-center">
                                                    <div className="icon pr-2">
                                                        <img width="48px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/1200px-Telegram_2019_Logo.svg.png" alt="wallet-img" />
                                                    </div>
                                                    <div className="description">
                                                        <h4 className="text-base mb-2">Connect Telegram</h4>
                                                        <p className="description">Connect Telegram receive notification</p>
                                                    </div>
                                                </div>
                                                { telegram?.telegram_id ? 
                                                    (<p>
                                                        You are connected to Telegram: 
                                                        <b>{ telegram.telegram_info?.first_name } - { telegram.telegram_info?.last_name }</b>
                                                    </p>) : ''
                                                }
                                                <div className="box-card-btn mt-2">
                                                    { 
                                                        telegram?.telegram_id ? <Button type="danger" onClick={disconnectTelegramConnection} loading={loadingTelegram} className="medium">Disconnect</Button> :
                                                            <Button type="primary" onClick={telegramConnection} loading={loadingTelegram} className="medium">Connect</Button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal title="Convert" visible={isModalConvertPlan} onCancel={() => {setIsModalConvertPlan(false)}} footer={null} width={480}>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center mb-4'>Click convert you can convert plan <b className='text-danger'>{profile?.plan_name}</b> to plan <b className='text-danger'>{profile?.plan_name == "List" ? 'Daily' : 'List'}</b></p>
                                    <div className='row'>
                                        <div className='col-12 col-sm-6'>
                                            <Button type="primary" onClick={covertPlan} loading={loadingConvert} className="d-block medium">Convert</Button>
                                        </div>
                                        <div className='col-12 col-sm-6'>
                                            <Button type="danger" onClick={() => {setIsModalConvertPlan(false)}} className="d-block medium">Cancel</Button>
                                        </div>
                                    </div>
                                    <hr />
                                    <p className="text-center mt-3">
                                        <span className="text-danger"><b>Support</b></span><br/> 
                                        Email: {window.confirm?.support_email}<br/>
                                        Skype: {window.confirm?.skype}
                                    </p>
                                </div>
                            </div>
                        </Modal>
                        <Modal title="Reset Account" visible={isModalVisible} onCancel={handleCancelReset} footer={null} width={580}>
                            <p className="mb-3">
                                If you use reset limit function<br/>
                                You agree to deduct 1 day of usage to remove "Your account limited today!"<br/><br/>
                                You are using the Reset Limit Socks5 function, your account is currently on:<br/>
                                Plan ({profile?.plan_name}) | Expires on: {profile?.user_exdate}.<br/>
                                When you click "Agree", you agree to deduct 1 day of usage, your account will be changed to:<br/>
                                Plan ({profile?.plan_name}) | Expires on: {profile?.user_exdate_1day}.<br/><br/>
                                What is "Your account limited today!"?<br/>
                                - This message usually appears when you have reached the usage limit for your account.<br/>
                                - The limit can be on the number of socks5 you can use per day or on the total amount of data you can use.<br/>
                                - You can check your account details in <a href="/my-account.html">My Account</a> to see the specific limits for your account.<br/>
                                - If you have reached your limit, you will need to wait until the limit resets before you can continue using the service.<br/>
                                - We will arrive at the new date at 0:00 AM on the GMT+7 time zone. You can see the GMT+7 time zone at the link: https://time.is/en/UTC+7<br/><br/>
                                U can see reset limit history at link:<br/>
                                <a href='/reset.html'>https://shopsocks5.com/reset.html</a>
                            </p>
                            <p className="text-center">
                                <Button type="primary" onClick={() => resetPlan()} htmlType="submit" loading={loadingButton} className="medium text-white mr-3">
                                    Agree
                                </Button>
                                <Button type="danger" onClick={() => handleCancelReset()} htmlType="submit" className="medium">
                                    Cancel
                                </Button>
                            </p>
                            <p className="text-center mt-3">
                                <span className="text-danger"><b>Support</b></span><br/> 
                                Email: {window.confirm?.support_email}<br/> 
                                Skype: {window.confirm?.skype}
                            </p>
                        </Modal>
                        <Modal title="Reset Free Account" visible={isModalFreeVisible} onCancel={handleCancelFree} footer={null} width={580}>
                            <p className="text-center mb-3"><b>Your account has 1 free reset every month<br/>You want reset your account today?</b></p>
                            <p className="text-center">
                                <Button type="primary" onClick={() => resetPlan()} htmlType="submit" loading={loadingButton} className="medium text-white mr-3">
                                    Agree
                                </Button>
                                <Button type="danger" onClick={() => handleCancelFree()} htmlType="submit" className="medium">
                                    Cancel
                                </Button>
                            </p>
                            <p className="text-center mt-3">
                                <span className="text-danger"><b>Support</b></span><br/> 
                                Email: {window.confirm?.support_email}<br/> 
                                Skype: {window.confirm?.skype}
                            </p>
                        </Modal>
                        <Modal title="Show button Reset Limit Socks5" visible={isShowModalOnOff} onCancel={() => { setIsShowModalOnOff(false) }} footer={null} width={580}>
                            <p className="mb-3">
                                If you use reset limit function<br/>
                                You agree to deduct 1 day of usage to remove "Your account limited today!"<br/><br/>
                                What is "Your account limited today!"?<br/>
                                - This message usually appears when you have reached the usage limit for your account.<br/>
                                - The limit can be on the number of socks5 you can use per day or on the total amount of data you can use.<br/>
                                - You can check your account details in <a href="/my-account.html">My Account</a> to see the specific limits for your account.<br/>
                                - If you have reached your limit, you will need to wait until the limit resets before you can continue using the service.<br/>
                                - We will arrive at the new date at 0:00 AM on the GMT+7 time zone. You can see the GMT+7 time zone at the link: https://time.is/en/UTC+7<br/><br/>
                                U can see reset limit history at link:<br/>
                                <a href="/reset.html">https://shopsocks5.com/reset.html</a>
                            </p>
                            <p className="text-center">
                                <Button type="primary" onClick={() => changeShowReset(true)} htmlType="submit" loading={loadingButton} className="medium text-white mr-3">
                                    Agree
                                </Button>
                                <Button type="danger" onClick={() => { setIsShowModalOnOff(false) }} htmlType="submit" className="medium">
                                    Cancel
                                </Button>
                            </p>
                            <p className="text-center mt-3">
                                <span className="text-danger"><b>Support</b></span><br/> 
                                Email: {window.confirm?.support_email}<br/> 
                                Skype: {window.confirm?.skype}
                            </p>
                        </Modal>
                        <Modal title="Change Password" visible={isModalChangePasswordVisible} onCancel={handleCancelResetPassword} footer={null} width={580}>
                            <Form
                                name="basic"
                                className="form-horizontal form"
                                onFinish={onFinish}
                            >
                                <div className="form-group">
                                    <Form.Item
                                        label="Current password:"
                                        labelCol={{span: 24}}
                                        name="user_pass"
                                        rules={[{ required: true, message: 'Please input current password!' }]}
                                    >
                                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Current Password" />
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <Form.Item
                                        label="New password:"
                                        labelCol={{span: 24}}
                                        name="user_pass_new"
                                        rules={[{ required: true, message: 'Please input new password!' }, { min: 6, message: 'New Password must be minimum 6 characters.' }]}
                                    >
                                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="New password" />
                                    </Form.Item>
                                </div>
                                <div className="form-group">
                                    <Form.Item
                                        label="Repeat password:"
                                        labelCol={{span: 24}}
                                        name="user_pass_new_re"
                                        rules={[{ required: true, message: 'Please input new password!' }, { min: 6, message: 'Repeat password must be minimum 6 characters.' }]}
                                    >
                                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Repeat password" />
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-right">
                                        <Button type="primary" htmlType="submit" loading={loading} className="medium">Save</Button>
                                    </div>
                                </div>
                            </Form>
                        </Modal>
                        <Modal title="Verify Account" visible={isShowModalVerifyEmail} onCancel={() => { setIsShowModalVerifyEmail(false) }} footer={null} width={580}>
                            <div>
                                <p>
                                    To complete the verification process, please confirm your email address by clicking the link below:<br/>
                                    <Button type="primary" loading={loadingButton} onClick={resendVerify} className="small mt-2">Verification Now</Button>
                                </p>
                            </div>
                        </Modal>
                    </div>
                )
            } 
        </div>
    );
}

export default MyAccount;
