import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, notification, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import TelegramLoginButton from 'react-telegram-login';
import { getParamsByUrlPaging } from '../../_until';
import { googleKey } from '../../_until';
import { account } from '../../endpoint';

const Register = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [token, setToken] = useState(null);
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkPolicy, setCheckPolicy] = useState(false);
    const [checkFaq, setCheckFaq] = useState(false);
    const [paramQuery, setParamQuery] = useState(getParamsByUrlPaging());
    const user = JSON.parse(localStorage.getItem('user'));

    /**
     * Handle submit form
     *
     * @param values
     */
    const onFinish = async (values) => {
        if (!checkPolicy) {
            notification.open({
                message: 'Fail!',
                description: "Please choose Terms and Conditions and Privacy Policy",
                type: 'error'
            });
            return false;
        }
        if (!checkFaq) {
            notification.open({
                message: 'Fail!',
                description: "Please choose FAQ",
                type: 'error'
            });
            return false;
        }
        if (token) {
            const hide = message.loading('Processing...');
            try {
                setLoading(true);
                var requestOptions = {...values, 'gg_code': token};
                var res = await account.register(requestOptions);
                hide();
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: 'Register successfully. Please login.',
                        type: 'success'
                    });
                    history.push({pathname: '/login.html'});
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                            type: 'error'
                        });
                    }
                }
            } catch (error) {
                hide();
                notification.open({
                    message: 'Fail!',
                    description: 'Register failed, please try again!',
                    type: 'error'
                });
            }
        } else {
            notification.open({
                message: 'Fail!',
                description: "Recaptcha has not been loaded",
                type: 'error'
            });
        }
        setLoading(false);
    };

    /**
     * Handle get token captcha
     *
     * @param response
     */
    const setTokenFunc = (getToken) => {
        setToken(getToken);
    };

    /**
     * Handle login telegram
     *
     * @param response
     */
    const handleTelegramResponse = (response) => {
        var queryString = Object.keys(response).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(response[key])
        }).join('&');
        window.location.href = '/social/telegram?' + queryString + '&invite_code=' + paramQuery?.invite_code;
    };

    useEffect(() => {
        document.title = "Register - " + window?.title;
    }, []);

    if (user != null) {
        history.push({pathname: '/home'});
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-3"></div>
                <div className="col-md-4 col-sm-6">
                    <div className="panel panel-default panel-custom">
                        <div className="panel-heading">USER REGISTER</div>
                        <div className="panel-body">
                            <div id="cards">
                                <GoogleReCaptchaProvider reCaptchaKey={googleKey()}>
                                    {
                                        token === null && (<GoogleReCaptcha onVerify={setTokenFunc} refreshReCaptcha={refreshReCaptcha}></GoogleReCaptcha>)
                                    }
                                    <div className="section_content">
                                        <Form
                                            name="basic"
                                            form={form}
                                            onFinish={onFinish}
                                            className="form-horizontal mt-3"
                                            id="registerform"
                                        >
                                            <Form.Item
                                                label="Username:"
                                                labelCol={{span: 24}}
                                                name="user"
                                                rules={[{ required: true, message: 'Please input your username!' }]}
                                            >
                                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="User Name" />
                                            </Form.Item>
                                            <Form.Item
                                                label="Email:"
                                                labelCol={{span: 24}}
                                                name="email"
                                                rules={[{ required: true, type: "email", message: 'Please input your email!' }]}
                                            >
                                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                                            </Form.Item>
                                            <Form.Item
                                                label="Password:"
                                                labelCol={{span: 24}}
                                                name="pwd"
                                                rules={[{ required: true, message: 'Please input your password!' }]}
                                            >
                                                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                                            </Form.Item>
                                            <hr/>
                                            <Form.Item
                                                label="Referral Code:"
                                                labelCol={{span: 24}}
                                                name="invite_code"
                                                initialValue={paramQuery?.invite_code}
                                            >
                                                <Input placeholder="Referral Code" />
                                            </Form.Item>
                                            <Form.Item
                                                name="privacy_policy"
                                                labelCol={{span: 24}}
                                            >
                                                <Checkbox checked={checkPolicy} onChange={() => setCheckPolicy(!checkPolicy)}>
                                                    I have read and agree to the <a href="https://shopsocks5.com/faq-detail/terms-and-conditions-and-privacy-policy" target='_blank'>Terms and Conditions and Privacy Policy</a>
                                                </Checkbox>
                                            </Form.Item>
                                            <Form.Item 
                                                name="faq"
                                                labelCol={{span: 24}}
                                            >
                                                <Checkbox checked={checkFaq} onChange={() => setCheckFaq(!checkFaq)}>
                                                    I have read and agree to the <a href="https://shopsocks5.com/faq-detail/faq" target='_blank'>FAQ</a>
                                                </Checkbox>
                                            </Form.Item>
                                            <div className="mb-2 text-center">
                                                <div className="col-xs-12 pb-3">
                                                    <Button type="primary" htmlType="submit" loading={loading} className="d-block medium">Register</Button>
                                                </div>
                                            </div>
                                            <div className="mb-3 text-center">
                                                <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="shopsock5_bot" />
                                            </div>
                                            <div className=" mb-3 text-center">
                                                <a className="btn-continue-google btn-block m-0" href={"/signup-google?invite_code=" + paramQuery?.invite_code}>Signup with Google</a>
                                            </div>
                                            <div className="mb-3 text-center">
                                                <a className="btn-continue-microsoft btn-block m-0" href={"/signup-microsoft?invite_code=" + paramQuery?.invite_code}>Signin with Microsoft</a>
                                            </div>
                                            <div className="mb-3 text-center">
                                                <a className="btn-continue-x btn-block m-0" href={"/signup-twitter?invite_code=" + paramQuery?.invite_code}>Signin with X</a>
                                            </div>
                                            <hr />
                                            <p className="text-center">
                                                <span>
                                                    <Link to="/login.html">Login</Link>
                                                </span>
                                                <span> | </span>
                                                <span>
                                                    <Link to="/forgot.html">Forgot password</Link>
                                                </span>
                                            </p>
                                        </Form>
                                    </div>
                                </GoogleReCaptchaProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-3"></div>
            </div>
        </div>
    );
}

export default Register;