import { Request } from '../_until';
import { notification } from 'antd';

export const banner = {
    getList,
    updateItem,
    addItem,
    changeSort,
    deleteItems,
    get_list_banner
};

const urls = {
    get_list: `/banners`,
    add_item: `/banners/add`,
    edit_item: `/banners/update`,
    change_sort: `/banners/change_sort/`,
    delete_item: `/banners/delete`,
    get_banner_website: `configg/get_banner`,
};

async function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    const res = await Request.get(urls.get_list + '?' + queryString, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            return {
                success: true,
                data: response.response,
                total: response.total
            };
        } else if (response.status === 'fail') {
            if (typeof response.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: response.message,
                    type: 'error'
                });
            }
        }
    } catch (e) {

    }
    return null;
};

async function updateItem(requestOptions) {
    return await Request.post(urls.edit_item, requestOptions).then(res => res.data);
};

async function addItem(requestOptions) {
    return await Request.upload(urls.add_item, requestOptions).then(res => res.data);
};

async function deleteItems(requestOptions) {
    return await Request.post(urls.delete_item, requestOptions).then(res => res.data);
};

async function changeSort(plan_id, requestOptions) {
    return await Request.post(urls.change_sort + plan_id, requestOptions).then(res => res.data);
};

function get_list_banner(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');
    return Request.get_website(urls.get_banner_website + '?' + queryString, {}).then(res => res.data);
};