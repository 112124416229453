import { Request } from '../_until';
import { notification } from 'antd';

export const partner = {
    getList,
    getItem,
    updateItem,
    addItem,
    changeStatus,
    changeShow,
    deleteItems,
    getCategorySelect,
    getListFrontend,
    getFrontendItem,
    getCategoryFrontend
};

const urls = {
    get_list: `/partner`,
    get_item: `/partner/get/`,
    add_item: `/partner/add`,
    edit_item: `/partner/update`,
    change_status: `/partner/change_status/`,
    delete_item: `/partner/delete`,
    get_list_category: `/partner/get_categories`,
};

const websiteUrls = {
    get_list: `partner`,
    get_item: `partner/get`,
    get_list_category: `partner/categories`,
};

async function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    const res = await Request.get(urls.get_list + '?' + queryString, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            return {
                success: true,
                data: response.response,
                total: response.total
            };
        } else if (response.status === 'fail') {
            if (typeof response.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: response.message,
                    type: 'error'
                });
            }
        }
    } catch (e) {

    }
    return null;
};

async function getItem(id) {
    const res = await Request.get(urls.get_item + id, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {}
    return null;
};

async function updateItem(requestOptions) {
    return await Request.upload(urls.edit_item, requestOptions).then(res => res.data);
};

async function addItem(requestOptions) {
    return await Request.upload(urls.add_item, requestOptions).then(res => res.data);
};

async function changeStatus(id, requestOptions) {
    return await Request.post(urls.change_status + id, requestOptions).then(res => res.data);
};

async function changeShow(id, requestOptions) {
    return await Request.post(urls.change_show + id, requestOptions).then(res => res.data);
};

async function deleteItems(requestOptions) {
    return await Request.post(urls.delete_item, requestOptions).then(res => res.data);
};

async function getCategorySelect() {
    const requestOptions = {};
    const res = await Request.post(urls.get_list_category, requestOptions);
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

async function getListFrontend(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');
    return await Request.get_website(websiteUrls.get_list + '?' + queryString, {}).then(res => res.data);
};

async function getFrontendItem(id) {
    return await Request.post_website(websiteUrls.get_item, {'id': id}).then(res => res.data);
};

async function getCategoryFrontend() {
    return await Request.post_website(websiteUrls.get_list_category, {}).then(res => res.data);
};