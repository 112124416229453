import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Spin } from 'antd';
import { faqs } from '../../endpoint';
import defaultImage from '../../assets/theme/images/default-featured-image.jpg';

const FAQsList = () => {
    const [loading, setLoading] = useState(true);
    const [dataFaqs, setDataFaqs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [active, setActive] = useState('all');

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Instructions - " + window?.title;
        onChangeCategory(active);
        faqs.getListCategoryFrontend().then(res => {
            if (res.status == 'success') {
                setCategories(res.response);
            }
        }).then(res => {
        });
    }, []);

    /**
     * Handle filter category
     *
     * @param categoryId
     */
    const onChangeCategory = (categoryId) =>{
        setActive(categoryId);
        setLoading(true);
        faqs.getListFrontend(categoryId).then(res => {
            if (res.status == 'success') {
                setDataFaqs(res.response);
            }
            setLoading(false);
        }).then(res => {
        });
    }
    
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <h3 className="text-bold mt-0 mb-3"><b>User Guide</b></h3>
                    <div className='list-category'>
                        <ul>
                            <li><Link className='active' to='/faqs.html'>All</Link></li>
                            {
                                categories && categories.length > 0 && categories.map((item, key) => 
                                    <li key={item.id}>
                                        <Link to={'/faqs/category/' + item.slug}>{item.name}</Link>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                        {
                            loading ? 
                            (<div className="text-center mt-5"><Spin /></div>) :
                            (
                                <div className="row">
                                    <div className="col-12 col-sm-12">
                                        <div className="row faqs-list">
                                            {
                                                dataFaqs && dataFaqs.length > 0 && dataFaqs.map((item, key) => 
                                                    <div key={item.id} className='col-12 col-sm-6 col-md-3'>
                                                        <div className="faq-item">
                                                            <div className='img'>
                                                                <Link to={'/faq-detail/' + item.slug}><img src={item.image ? window.base_url + item.image : defaultImage} /></Link>
                                                            </div>
                                                            <div className='faq-content'>
                                                                <Link to={'/faq-detail/' + item.slug}>{item.name}</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        } 
                </div>
            </div>
        </div>
    );
}

export default FAQsList;
