import React, { useState, useEffect } from 'react';
import { useHistory} from "react-router-dom";
import { Button, message, notification, Form, Checkbox, Input, Spin } from 'antd';
import { account, setting, authenticator } from '../../../../endpoint';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const SettingComponent = () => {
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [settingData, setSettingData] = useState({});
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }

        setting.getData().then(res => {
            if (res.status == 'success') {
                if (res.response != null) {
                    setSettingData(res.response);
                }
                setLoadingData(false);
                setEditorLoaded(true);
            }
        });
    }, []);

    /**
     * Save data
     */
    const onFinish = () => {
        const dataSetting = form.getFieldsValue();
        const hide = message.loading('Processing...');
        try {
            setLoading(true);
            setting.updateItem({...dataSetting, ...settingData}).then(res => {
                if (res.status == 'success') {
                    notification.open({
                        message: 'Success',
                        description: 'Save successfully',
                        type: 'success'
                    });
                    setTimeout(function() {
                        location.reload();
                    }, 300)
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: res.message,
                            type: 'error'
                        });
                    }
                }
            }).then(err => {
                hide();
                setLoading(false);
            });
        } catch (error) {
            hide();
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
        }
    };

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h3>Setting</h3>
                            <hr/>
                            {
                                loadingData && (<div className="text-center mt-5"><Spin /></div>)
                            }
                            {
                                !loadingData && (
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-2"></div>
                                        <div className="col-12 col-md-12 col-lg-8">
                                            <Form
                                                name="basic"
                                                className="form-horizontal mt-3 form"
                                                onFinish={onFinish}
                                                form={form}
                                            >
                                                {
                                                    editorLoaded ? (
                                                        <div className="mb-4">
                                                            <label>Description Premium</label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                id="description_premium"
                                                                config={{
                                                                    ckfinder: false
                                                                }}
                                                                data={settingData?.description_premium}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    settingData.description_premium = data;
                                                                    setSettingData(settingData);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : ""
                                                }
                                                {
                                                    editorLoaded ? (
                                                        <div className="mb-4">
                                                            <label>Description Daily</label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                id="description_daily"
                                                                config={{
                                                                    ckfinder: false
                                                                }}
                                                                data={settingData?.description_daily}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    settingData.description_daily = data;
                                                                    setSettingData(settingData);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : ""
                                                }
                                                {
                                                    editorLoaded ? (
                                                        <div className="mb-4">
                                                            <label>Description List</label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                id="description_list"
                                                                config={{
                                                                    ckfinder: false
                                                                }}
                                                                data={settingData?.description_list}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    settingData.description_list = data;
                                                                    setSettingData(settingData);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : ""
                                                }
                                                {
                                                    editorLoaded ? (
                                                        <div className="mb-4">
                                                            <label>Description S5 922</label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                id="description_922"
                                                                config={{
                                                                    ckfinder: false
                                                                }}
                                                                data={settingData?.description_922}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    settingData.description_922 = data;
                                                                    setSettingData(settingData);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : ""
                                                }
                                                {
                                                    editorLoaded ? (
                                                        <div className="mb-4">
                                                            <label>Description S5 ABC</label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                id="description_abc"
                                                                config={{
                                                                    ckfinder: false
                                                                }}
                                                                data={settingData?.description_abc}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    settingData.description_abc = data;
                                                                    setSettingData(settingData);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : ""
                                                }
                                                {
                                                    editorLoaded ? (
                                                        <div className="mb-4">
                                                            <label>Description S5 PIA</label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                id="description_pia"
                                                                config={{
                                                                    ckfinder: false
                                                                }}
                                                                data={settingData?.description_pia}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    settingData.description_pia = data;
                                                                    setSettingData(settingData);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : ""
                                                }
                                                {
                                                    editorLoaded ? (
                                                        <div className="mb-4">
                                                            <label>Description Credit</label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                id="description_credit"
                                                                config={{
                                                                    ckfinder: false
                                                                }}
                                                                data={settingData?.description_credit}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    settingData.description_credit = data;
                                                                    setSettingData(settingData);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : ""
                                                }
                                                {
                                                    editorLoaded ? (
                                                        <div className="mb-4">
                                                            <label>Description Package Traffic 922</label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                id="description_traffic_922"
                                                                config={{
                                                                    ckfinder: false
                                                                }}
                                                                data={settingData?.description_traffic_922}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    settingData.description_traffic_922 = data;
                                                                    setSettingData(settingData);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : ""
                                                }
                                                {
                                                    editorLoaded ? (
                                                        <div className="mb-4">
                                                            <label>Description Package Luna</label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                id="description_luna"
                                                                config={{
                                                                    ckfinder: false
                                                                }}
                                                                data={settingData?.description_luna}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    settingData.description_luna = data;
                                                                    setSettingData(settingData);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : ""
                                                }
                                                {
                                                    editorLoaded ? (
                                                        <div className="mb-4">
                                                            <label>Description Package Proxy</label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                id="description_package_proxy"
                                                                config={{
                                                                    ckfinder: false
                                                                }}
                                                                data={settingData?.description_package_proxy}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    settingData.description_package_proxy = data;
                                                                    setSettingData(settingData);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : ""
                                                }
                                                {
                                                    editorLoaded ? (
                                                        <div className="mb-4">
                                                            <label>Description Package VPS</label>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                id="description_package_vps"
                                                                config={{
                                                                    ckfinder: false
                                                                }}
                                                                data={settingData?.description_package_vps}
                                                                onReady={(editor) => {
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    settingData.description_package_vps = data;
                                                                    setSettingData(settingData);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : ""
                                                }
                                                <div className="row">
                                                    <div className="col-md-12 text-right">
                                                        <Button type="primary" htmlType="submit" loading={loading} className="medium">Save</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SettingComponent;
