import { Request } from '../_until';
import { notification } from 'antd';

export const packages = {
    getList,
    getItem,
    updateItem,
    addItem,
    changeStatus,
    deleteItems
};

const urls = {
    get_list: `/package`,
    get_item: `/package/get/`,
    add_item: `/package/add`,
    edit_item: `/package/update`,
    change_status: `/package/change_status/`,
    delete_item: `/package/delete`,
};

async function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    const res = await Request.get(urls.get_list + '?' + queryString, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            return {
                success: true,
                data: response.response,
                total: response.total
            };
        } else if (response.status === 'fail') {
            if (typeof response.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: response.message,
                    type: 'error'
                });
            }
        }
    } catch (e) {

    }
    return null;
};

async function getItem(id) {
    const res = await Request.get(urls.get_item + id, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            const results = response.response;
            return results;
        }
    } catch (e) {}
    return null;
};

async function updateItem(requestOptions) {
    return await Request.post(urls.edit_item, requestOptions).then(res => res.data);
};

async function addItem(requestOptions) {
    return await Request.post(urls.add_item, requestOptions).then(res => res.data);
};

async function changeStatus(id, requestOptions) {
    return await Request.post(urls.change_status + id, requestOptions).then(res => res.data);
};

async function deleteItems(requestOptions) {
    return await Request.post(urls.delete_item, requestOptions).then(res => res.data);
};
