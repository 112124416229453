import { Request } from '../_until';
import { notification } from 'antd';

export const user_tracking_confirm = {
    getList,
    confirmOrder
};

const urls = {
    get_list: `/user_tracking_confirm`,
    confirm_item: `/user_tracking_confirm/confirm_order`,
};

async function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key])
    }).join('&');

    const res = await Request.get(urls.get_list + '?' + queryString, {});
    try {
        const response = res.data;
        if (response.status === 'success') {
            return {
                success: true,
                data: response.response,
                total: response.total
            };
        } else if (response.status === 'fail') {
            if (typeof response.message != 'undefined') {
                notification.open({
                    message: 'Fail!',
                    description: response.message,
                    type: 'error'
                });
            }
        }
    } catch (e) {
    }
    return null;
};

async function confirmOrder(requestOptions) {
    return await Request.post(urls.confirm_item, requestOptions).then(res => res.data);
};
