import React from 'react';
import { Modal, Form, Button, Upload } from 'antd';
import { ProFormSelect, ProFormText, ProFormDigit, ProFormTextArea } from '@ant-design/pro-form';

const BannerUpdate = (props) => {
    return (
        <Modal
            width={420}
            bodyStyle={{
                padding: '32px 40px 48px',
            }}
            destroyOnClose
            footer={false}
            title="Edit Plan"
            visible={props.updateModalVisible}
            onCancel={() => {
                props.onCancel();
            }}
        >
            <Form
                onFinish={props.onSubmit}
                initialValues={{
                    name: props.values?.name,
                    url: props.values?.url,
                    position: {label: props.values?.position == 'banner_top' ? 'Home' : (props.values?.position == 'sock' ? 'Sock Page' : 'Sidebar'), value: props.values?.position},
                    sort: props.values?.sort,
                    is_full: {value: props.values?.is_full},
                    status: {label: props.values?.status == 1 ? 'Active' : 'Deactive', value: props.values?.status},
                    description: props.values?.description
                }}
            >
                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Name is required"
                        },
                    ]}
                    width="lg"
                    name="name"
                    label="Name:"
                />

                <ProFormText
                    rules={[
                        {
                            required: true,
                            message: "Url is required"
                        },
                    ]}
                    width="lg"
                    name="url"
                    label="Url:"
                />

                <div className="mb-4 mt-4">
                    <Upload >
                        <img src={props.values?.path} alt="avatar" style={{ width: '100%' }} />
                    </Upload>
                </div>

                <ProFormSelect
                    width="lg"
                    name="position"
                    label="Position"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    request={ () => [
                        { label: 'Home', value: 'banner_top'},
                        { label: 'Sidebar', value: 'banner_sidebar' },
                        { label: 'Sock Page', value: 'sock'},
                        { label: 'Buy Sock', value: 'buy_sock'}
                    ]}
                    placeholder="Please select a Position"
                    rules={[
                        { 
                            required: true, 
                            message: 'Please select your Position!' 
                        }
                    ]}
                />
                <ProFormDigit 
                    rules={[
                        {
                            required: true,
                            message: "Sort is required"
                        },
                    ]}
                    label="Sort" 
                    name="sort" 
                    width="lg" 
                    min={0}
                />

                <ProFormSelect
                    width="lg"
                    name="is_full"
                    label="Columns"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    initialValue={props.values?.is_full}
                    request={async () => [
                        { label: 'Full', value: 1},
                        { label: '2 Columns', value: 2 },
                        { label: '3 Columns', value: 3 },
                        { label: '4 Columns', value: 4 }
                    ]}
                    placeholder="Columns"
                />

                <ProFormSelect
                    width="lg"
                    name="status"
                    label="Status"
                    fieldProps={{
                        labelInValue: true,
                    }}
                    rules={[
                        {
                            required: true,
                            message: "Status is required"
                        },
                    ]}
                    request={async () => [
                        { label: 'Active', value: '1'},
                        { label: 'Deactive', value: '0' }
                    ]}
                    placeholder="Status"
                />
                <ProFormTextArea
                    rules={[
                        {
                            required: true,
                            message: "Description is required"
                        },
                    ]}
                    row="10"
                    width="lg"
                    name="description"
                    label="Description:"
                    placeholder="Description:"
                />
                <div className="text-right">
                    <Button type="primary" htmlType="submit">Update</Button>
                </div>
            </Form>
        </Modal>
    );
};
export default BannerUpdate;
