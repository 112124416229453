import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { Button, message, Switch, Drawer, notification, Popconfirm, Form } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import ProDescriptions from '@ant-design/pro-descriptions';
import { user_trial_proxy, authenticator } from '../../../../endpoint';
import { getParamsByUrl } from '../../../../_until';

const UserTrialProxyComponent = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const actionRef = useRef(null);
    const [currentRow, setCurrentRow] = useState([]);
    const [selectedRowsState, setSelectedRows] = useState([]);
    const [paramQuery, setParamQuery] = useState(getParamsByUrl());
    const history  = useHistory();
    const [form] = Form.useForm();
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        if (oneTimePassword) {
            authenticator.checkVerify2fa({"one_time_password": oneTimePassword}).then(res => {
                if (res.status != 'success') {
                    history.push({pathname: '/ntsock/verify2fa'});
                }
            }).then(res => {
            });
        } else {
            history.push({pathname: '/ntsock/verify2fa'});
        }
    }, []);

    /**
     * Load list results
     *
     * @param param1
     * @param param2
     */
    const list = async (param1, param2) => {
        const parameters = {...param1, ...param2};

        var queryString = Object.keys(parameters).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key])
        }).join('&');

        history.push(`?` + queryString);
        //setParamQuery(getParamsByUrl());
        const data = await user_trial_proxy.getList(parameters);
        return data;
    }

    /**
     * Columns show table
     */
    const columns = [
        {
            title: "User Name",
            dataIndex: 'user_name',
            sorter: false,
            initialValue: paramQuery?.user_name,
        },
        {
            title: "IP",
            dataIndex: 'ip',
            sorter: false,
            hideInSearch: true,
            render: (_, record) => [
                <span key={'ip' + record.id}>{ record?.ip + ':' + record?.port }</span>
            ]
        },
        {
            title: "Packag Name",
            dataIndex: 'plan_name',
            sorter: false,
            hideInSearch: true,
        },
        {
            title: "Google email",
            dataIndex: 'email',
            sorter: false,
            hideInSearch: true
        },
        {
            title: "Date Expire",
            dataIndex: 'date_expired',
            sorter: false,
            hideInSearch: true
        },
        {
            title: "Type Proxy",
            dataIndex: 'type',
            hideInSearch: true,
            render: (_, record) => [
                <span key={'type' + record.id}>{ record?.type == 'proxy_sock_5' ? "SOCKS5 Proxy" : "HTTPS Proxy" }</span>
            ]
        },
        {
            title: "Status",
            dataIndex: 'status',
            initialValue: paramQuery?.status,
            valueEnum: {
                0: {
                    text:'Pending',
                    status: 'Default',
                },
                1: {
                    text: "Complete",
                    status: 'Success',
                }
            }
        },
        {
            title:'Date',
            dataIndex: 'date',
            hideInSearch: true
        }
    ];

    return (
        <PageContainer>
            <ProTable 
                headerTitle="User Trial Proxy"
                actionRef={actionRef}
                request={list}
                rowKey="id"
                search={{
                    labelWidth: 120
                }}
                toolBarRender={() => [
                ]}
                columns={columns}
                pagination={{
                    defaultCurrent: (paramQuery?.current || 1),
                    defaultPageSize: (paramQuery?.pageSize || 20)
                }}
            />

            {/*View Info*/}
            <Drawer
                width={320}
                visible={showDetail}
                onClose={() => {
                    setCurrentRow(undefined);
                    setShowDetail(false);
                }}
                closable={true}
            >
                {currentRow?.id && (
                    <ProDescriptions 
                        title={currentRow?.sock}
                        request={async () => ({
                            data: currentRow || {},
                        })}
                        params={{
                            id: currentRow?.id,
                        }}
                        columns={columns}
                    />
                )}
            </Drawer>
        </PageContainer>
    );
}
export default UserTrialProxyComponent;
