import { Request } from '../_until';

export const sock_list = {
    getList,
    getListAPI,
    getList24h,
    getSockList,
    getSockPremium,
    getCountryAll,
    getCountry,
    getCity,
    getState,
    viewSock,
    downloadSock24h,
    downloadSockAll,
    getRickLevel
};

const urls = {
    get_list: `sock_list`,
    get_sock_api: `sock_list/sockapi`,
    get_sock_24h: `sock_list/sock24h`,
    get_sock_list: `sock_list/socklist`,
    get_sock_premium: `sock_list/sockpremium`,
    get_country_all: `sock_list/get_country_all`,
    get_country: `sock_list/get_country`,
    get_city: `sock_list/get_city`,
    get_state: `sock_list/get_state`,
    view_sock: `sock_list/view_sock`,
    download_sock_24h: `sock_list/export24h`,
    download_sockapi: `sock_list/download_sockapi`,
    get_rick_level: `sock_list/get_rick_level`,
};


function getList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_list + '?' + queryString, {}).then(res => res.data);
};

function getListAPI(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_sock_api + '?' + queryString, {}).then(res => res.data);
};

function downloadSockAll(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.download_sockapi + '?' + queryString, {}).then(res => res.data);
};

function getSockList(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_sock_list + '?' + queryString, {}).then(res => res.data);
};

function getSockPremium(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_sock_premium + '?' + queryString, {}).then(res => res.data);
};

function getList24h(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_sock_24h + '?' + queryString, {}).then(res => res.data);
};

function getCountryAll(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_country_all + '?' + queryString, {}).then(res => res.data);
};


function getCountry(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_country + '?' + queryString, {}).then(res => res.data);
};


function getCity(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_city + '?' + queryString, {}).then(res => res.data);
};

function getState(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_state + '?' + queryString, {}).then(res => res.data);
};

function viewSock(requestOptions) {
    return Request.post_website(urls.view_sock, requestOptions).then(res => res.data);
};

function downloadSock24h() {
    return Request.post_website(urls.download_sock_24h, {export: 1}).then(res => res.data);
};

function getRickLevel(requestOptions) {
    var queryString = Object.keys(requestOptions).map((key) => {
        if (typeof requestOptions[key] != 'undefined') {
            return encodeURIComponent(key) + '=' + encodeURIComponent(requestOptions[key]);
        }
    }).join('&');

    return Request.get_website(urls.get_rick_level + '?' + queryString, {}).then(res => res.data);
};