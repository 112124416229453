import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, notification, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import TelegramLoginButton from 'react-telegram-login';
import { googleKey } from '../../_until';
import { account } from '../../endpoint';

const Login = () => {
    const history  = useHistory();
    const [token, setToken] = useState(null);
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));

    /**
     * Handle login
     *
     * @param values
     */
    const onFinish = async (values) => {
        //if (token) {
            const hide = message.loading('Processing...');
            try {
                setLoading(true);
                var requestOptions = {...values, 'gg_code': token};
                var res = await account.login(requestOptions);
                hide();
                if (res.status == 'success') {
                    const user = res.response;
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('token', user.token);
                    notification.open({
                        message: 'Success',
                        description: 'Login successfully',
                        type: 'success'
                    });
                    //history.go({pathname: '/home'});
                } else {
                    if (typeof res.message != 'undefined') {
                        notification.open({
                            message: 'Fail!',
                            description: (<><div dangerouslySetInnerHTML={{__html: res.message}} /></>),
                            type: 'error'
                        });
                    }
                }
            } catch (error) {
                hide();
                notification.open({
                    message: 'Fail!',
                    description: 'Login failed, please try again!',
                    type: 'error'
                });
            }
            setLoading(false);
        // } else {
        //     notification.open({
        //         message: 'Fail!',
        //         description: "Recaptcha has not been loaded",
        //         type: 'error'
        //     });
        // }
    };

    /**
     * Handle on login fail
     *
     * @param errorInfo
     */
    const onFinishFailed = (errorInfo) => {
        //console.log('Failed:', errorInfo);
    };

    /**
     * Handle login telegram
     *
     * @param response
     */
    const handleTelegramResponse = (response) => {
        var queryString = Object.keys(response).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(response[key])
        }).join('&');
        window.location.href = '/social/telegram?' + queryString;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Login - " + window?.title;
    }, []);

    if (user != null) {
        history.push({pathname: '/home'});
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-3"></div>
                <div className="col-md-4 col-sm-6">
                    <div className="panel panel-default panel-custom">
                        <div className="panel-heading">USER LOGIN</div>
                        <div className="panel-body">
                            <GoogleReCaptchaProvider reCaptchaKey={googleKey()}>
                                {
                                    token === null && (<GoogleReCaptcha onVerify={token => {setToken(token); }}></GoogleReCaptcha>)
                                }
                                <div id="cards">
                                    <div className="section_content">
                                        <Form
                                            name="basic"
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            className="form-horizontal mt-3 form-login"
                                            id="loginform"
                                        >
                                            <Form.Item
                                                label="Username or Email:"
                                                labelCol={{span: 24}}
                                                name="username"
                                                rules={[{ required: true, message: 'Please input your Username or Email!' }]}
                                            >
                                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="User Name or Email!" />
                                            </Form.Item>
                                            <Form.Item
                                                label="Password:"
                                                labelCol={{span: 24}}
                                                name="password"
                                                rules={[{ required: true, message: 'Please input your password!' }]}
                                            >
                                                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                                            </Form.Item>
                                            <div className="mb-3 row">
                                                <div className="col-md-12">
                                                    <div className="d-flex align-items-center">
                                                        <Form.Item name="remember" valuePropName="checked" className="mb-0">
                                                            <Checkbox> Remember me</Checkbox>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-2 text-center">
                                                <div className="col-xs-12 pb-3">
                                                    <Button type="primary" htmlType="submit" loading={loading} className="d-block medium">Log In</Button>
                                                </div>
                                            </div>
                                            <div className="mb-3 text-center">
                                                <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="shopsock5_bot" />
                                            </div>
                                            <div className="mb-3 text-center">
                                                <a className="btn-continue-google btn-block m-0" href="/signup-google">Signin with Google</a>
                                            </div>
                                            <div className="mb-3 text-center">
                                                <a className="btn-continue-microsoft btn-block m-0" href="/signup-microsoft">Signin with Microsoft</a>
                                            </div>
                                            <div className="mb-3 text-center">
                                                <a className="btn-continue-x btn-block m-0" href="/signup-twitter">Signin with X</a>
                                            </div>
                                            <hr />
                                            <p className="text-center">
                                                <span>
                                                    <Link to="/register.html">Register</Link>
                                                </span>
                                                <span> | </span>
                                                <span>
                                                    <Link to="/forgot.html">Forgot password</Link>
                                                </span>
                                            </p>
                                        </Form>
                                    </div>
                                </div>
                            </GoogleReCaptchaProvider>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-3"></div>
                </div>
            </div>
        </div>
    );
}
export default Login;
