import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Spin } from 'antd';
import { account } from '../../endpoint';
import { getParamsHash } from '../../_until';

const LoginMicrosoft = () => {
    const history = useHistory();
    const [paramQuery, setParamQuery] = useState(getParamsHash());
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        document.title = "Login Microsoft - " + window?.title;
        if (user == null) {
            if (typeof paramQuery['access_token'] != 'undefined') {
                account.get_info_microsoft(paramQuery['access_token']).then(res => {
                    if (res.status == 'success') {
                        window.location.href = '/';
                    }
                });
            }
        }
    }, []);

    if (user != null) {
        history.push({pathname: '/home'});
    }

    return (
        <div className="container">
            <div className="text-center mt-5"><Spin /></div>
        </div>
    );
}
export default LoginMicrosoft;