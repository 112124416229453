import React, { useState, useRef, useEffect } from 'react';
import { useHistory, generatePath, useRouteMatch  } from "react-router-dom";
import { Button, message, Switch, Drawer, notification, Popconfirm, Form, Spin } from 'antd';
import { authenticator } from '../../endpoint';

const Auth2FAComponentFrontend = () => {
    const actionRef = useRef(null);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [security, setSecurity] = useState([]);
    const [qrcode, setQrcode] = useState('');
    const [verifyCode, setVerifyCode] = useState('');
    const oneTimePassword = localStorage.getItem('one_time_password');

    useEffect(() => {
        authenticator.get2fa().then(res => {
            if (res.status == 'success') {
                if (res.qr_code_url != '') {
                    setQrcode(res.qr_code_url);
                    setSecurity(res.security);
                }

                if (res.security?.enable == 1 && !oneTimePassword) {
                    history.push({pathname: '/verify2fa.html'});
                }
            }
        }).then(res => {
            setLoading(false);
        });
    }, []);

    /**
     * generate 2FA Secret
     */
    const generate2faSecret = () => {
        const hide = message.loading('Processing...');
        setLoading(true);
        try {
            authenticator.generate2faSecret({"generate": 1}).then(res => {
                if (res.status == 'success') {
                    setQrcode(res.qr_code_url);
                    setSecurity(res.security);
                } else {
                    notification.open({
                        message: 'Fail!',
                        description: res.message,
                        type: 'error'
                    });
                }
            }).then(res => {
                hide();
                setLoading(false);
            });
        } catch (error) {
            hide();
            setLoading(false);
            notification.open({
                message: 'Fail!',
                description: 'Error, please try again!',
                type: 'error'
            });
        }
        return false;
    };

    /**
     * enable 2FA
     */
    const enable2fa = () => {
        if (verifyCode) {
            const hide = message.loading('Processing...');
            setLoading(true);
            try {
                authenticator.enable2fa({"verify_code": verifyCode}).then(res => {
                    if (res.status == 'success') {
                        setSecurity(res.security);
                    } else {
                        notification.open({
                            message: 'Fail!',
                            description: res.message,
                            type: 'error'
                        });
                    }
                }).then(res => {
                    hide();
                    setLoading(false);
                });
            } catch (error) {
                hide();
                setLoading(false);
                notification.open({
                    message: 'Fail!',
                    description: 'Error, please try again!',
                    type: 'error'
                });
            }
        } else {
            notification.open({
                message: 'Fail!',
                description: 'Please enter Authenticator Code.',
                type: 'error'
            });
        }
        return false;
    };

    /**
     * Onchange verify code
     */
    const handleOnChangeVerifyCode = event => {
        const { name, value } = event.target;
        setVerifyCode(value);
    };

    /**
     * disabled 2FA
     */
    const disabled2fa = () => {
        if (verifyCode) {
            const hide = message.loading('Processing...');
            setLoading(true);
            try {
                authenticator.disable2fa({"one_time_password": verifyCode}).then(res => {
                    if (res.status == 'success') {
                        setSecurity(res.security);
                    } else {
                        notification.open({
                            message: 'Fail!',
                            description: res.message,
                            type: 'error'
                        });
                    }
                }).then(res => {
                    hide();
                    setLoading(false);
                });
            } catch (error) {
                hide();
                setLoading(false);
                notification.open({
                    message: 'Fail!',
                    description: 'Error, please try again!',
                    type: 'error'
                });
            }
        } else {
            notification.open({
                message: 'Fail!',
                description: 'Please enter Authenticator Code.',
                type: 'error'
            });
        }
        return false;
    };

    return (
        <div className="container">
            <div className={"text-center mt-5 mb-5" + (loading ? "" : " d-none")}><Spin /></div>
            <div className={ "panel panel-default panel-custom" + (loading ? " d-none" : "")}>
                <div className="panel-heading">Two Factor Authentication</div>
                <div className="panel-body">
                    {
                        security?.id ? (
                            <div>
                                {
                                    security?.enable == 0 ? (
                                        <div>
                                            <p className="mb-2"><strong>1. Scan this barcode with your Google Authenticator App:</strong></p>
                                            <p className="mb-3"><img src={qrcode} width="200px" height="200px" /></p>
                                            <p className="mb-3"><strong>2.Enter the pin the code to Enable 2FA</strong></p>
                                            <div className="form-group">
                                                <label className="control-label">Authenticator Code</label>
                                                <div className="row">
                                                    <div className="col-md-4 col-md-offset-4">
                                                        <input type="text" onChange={handleOnChangeVerifyCode} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4 col-md-offset-4">
                                                        <button type="submit" className="btn btn-primary" onClick={enable2fa}>Enable 2FA</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <p>If you are looking to disable Two Factor Authentication. Please confirm your password and Click Disable 2FA Button.</p>
                                            <div className="form-group">
                                                <label className="control-label">One Time Password</label>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <input id="one_time_password" onChange={handleOnChangeVerifyCode} type="text" className="form-control" name="one_time_password" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 col-md-offset-4">
                                                    <button type="submit" className="btn btn-primary" onClick={disabled2fa}>Disable 2FA</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        ) : (
                            <div>
                                <h3><strong>Two Factor Authentication</strong></h3>
                                <p>Two factor authentication (2FA) strengthens access security by requiring two methods (also referred to as factors) to verify your identity. Two factor authentication protects against phishing, social engineering and password brute force attacks and secures your logins from attackers exploiting weak or stolen credentials.</p>
                                <p>To Enable Two Factor Authentication on your Account, you need to do following steps</p>
                                <ol>
                                    <li>Click on Generate Secret Button, To Generate a Unique secret QR code for your profile</li>
                                    <li>Verify the OTP from Google Authenticator Mobile App</li>
                                </ol>
                                
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary" onClick={generate2faSecret}>Generate Secret Key to Enable 2FA</button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
export default Auth2FAComponentFrontend;
