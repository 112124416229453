import axios from 'axios';

const api_url = window.base_url + 'api';
const base_url = window.base_url;
const config = {
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
};

export const Request = {
    get,
    post,
    upload,
    get_website,
    post_website,
    upload_website
};

function get(url, requestOptions) {
    const authorization = localStorage.getItem('token');
    if (url.indexOf('?') != -1) {
        url = url + '&_token=' + window._token;
    } else {
        url = url + '?_token=' + window._token;
    }
    url = url + '&authorization=' + authorization;
    return axios.get(api_url + url, requestOptions);
};

function post(url, requestOptions) {
    const authorization = localStorage.getItem('token');
    requestOptions._token = window._token;
    requestOptions.authorization = authorization;
    if (localStorage.getItem('client_ip')) {
        requestOptions.client_ip = localStorage.getItem('client_ip');
    }
    return axios.post(api_url + url, requestOptions, config);
};

function upload(url, formData) {
    const authorization = localStorage.getItem('token');
    formData.append('_token', window._token);
    formData.append('authorization', authorization);
    if (localStorage.getItem('client_ip')) {
        formData.append('client_ip', localStorage.getItem('client_ip'));
    }
    return axios.post(api_url + url, formData, config);
};

function get_website(url, requestOptions) {
    const authorization = localStorage.getItem('token');
    if (url.indexOf('?') != -1) {
        url = url + '&_token=' + window._token;
    } else {
        url = url + '?_token=' + window._token;
    }
    url = url + '&authorization=' + authorization;
    return axios.get(base_url + url, requestOptions);
};

function post_website(url, requestOptions) {
    const authorization = localStorage.getItem('token');
    requestOptions._token = window._token;
    requestOptions.authorization = authorization;
    if (localStorage.getItem('client_ip')) {
        requestOptions.client_ip = localStorage.getItem('client_ip');
    }
    return axios.post(base_url + url, requestOptions, config);
};

function upload_website(url, requestOptions) {
    const authorization = localStorage.getItem('token');
    requestOptions.append('_token', window._token);
    requestOptions.append('authorization', authorization);
    if (localStorage.getItem('client_ip')) {
        requestOptions.append('client_ip', localStorage.getItem('client_ip'));
    }
    return axios.post(base_url + url, requestOptions, { headers: { 'Content-Type': 'multipart/form-data', 'ClientIp': localStorage.getItem('client_ip') } });
};